import React,{useEffect,useMemo,useState,useCallback} from 'react';
import styled from 'styled-components';


import Header from './Header';
import Services from './Services';
import Quote from './Quote';
import GetInTouch from './GetInTouch';
import News from './News';

const Root = styled.div`
`;

export default function Home({expand,delaying,expanding,onExpanded,privateInsurance,path,scrollElement,animate}){

  const privateInsuranceDelayed = expanding ? !privateInsurance : privateInsurance;

  const hide = useMemo(()=>{
    return path !== '/';
  },[path]);

  return hide ? null : <Root>
    <div id="home--header"/>
    <Header delaying={delaying} animate={animate} privateinsurance={false} expanded={!privateInsuranceDelayed} expanding={expanding} expand={expand} onExpanded={onExpanded} />
    { delaying ? null : <>
      <Header animate={animate} privateinsurance={true} expanded={privateInsuranceDelayed} expanding={expanding} expand={expand} onExpanded={onExpanded} />
      <Services animate={animate} privateInsurance={privateInsurance}/>
      <Quote privateInsurance={privateInsurance}/>
      <GetInTouch animate={animate} id="home--get-in-touch" privateInsurance={privateInsurance}/>
      <News privateInsurance={privateInsurance}/>
    </> }
  </Root>
}
