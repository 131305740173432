export default {
  privateInsurance: true,
  title: 'Habitação',
  description: 'Proteção para a sua casa',
  buttons: [
    'Condomínio',
    'Casa',
    'Empregada Doméstica',
  ],
  pages: [
    {
      subtitle: 'Condomínio',
      body: [
        'Para que a segurança do condomínio não seja uma preocupação.​​​​​​​​​​​​​​​​​​​​​​​​​​',
        'O Seguro Condomínio garante a proteção das partes comuns do prédio e das frações autónomas. Além de dar resposta à obrigatoriedade de subscrever um Seguro de Incêndio para edifícios em regime de propriedade horizontal, garante ainda as coberturas de Assistência ao Condomínio, a Assistência ao Lar em caso de sinistro ou a Proteção Jurídica.',
      ],
      boxes:[
        {
          title: 'Condomínio',
          body: ['Para que a segurança do seu condomínio não seja uma preocupação, proteja as partes comuns bem como as frações autónomas.'],
          picture: 1,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Resolução de sinistros',
          body: ['A existência de uma única apólice de seguro para todos os condóminos permite agilizar e simplificar a resolução de sinistros entre as frações evitando conflitos e burocracias.​'],
        },
        {
          title: 'Assistência permanente',
          body: ['Pode solicitar o serviço de técnicos especializados no caso de ocorrer um sinistro coberto pelo seguro, numa parte comum do prédio ou numa fração.​'],
        },
        {
          title: 'Compromisso com o cliente',
          body: ['Garantimos rapidez, eficiência e transparência na resolução de sinistros. '],
        },
      ],
    },
    {
      subtitle: 'Casa',
      body: [
        'Queremos estar junto de si e da sua família quando realmente precisa. Por isso, para além de uma resolução rápida e eficaz em situação de sinistro, conte com o seguro Casa no seu dia a dia, quando necessitar de chamar um médico a casa ou de pedir assistência remota para o seu computador, se precisar de fazer obras em casa ou até quando fechar a porta e esquecer-se das chaves dentro de casa. São tantos os imprevistos... Connosco está seguro!'
      ],
      boxes:[
        {
          title: 'Casa',
          body: [
            'Um seguro para si e para a sua casa.',
            'Um seguro habitação que se adapta às preocupações de cada cliente e às suas necessidades de proteção.',
          ],
          picture: 1,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Planos com oferta crescente',
          body: [
            'Escolha entre os 3 planos disponíveis, o que melhor se adapta às suas necessidades de proteção e estilo de vida.',
            'Personalize a proteção para a sua casa através de coberturas adicionais que vão cuidar melhor de si, da sua família e do que é seu.',
          ],
        },
        {
          title: 'Na hora do sinistro',
          body: [
            'Para que o mais importante não lhe falte, o seguro Casa tem níveis de serviço que permitem dar uma resposta rápida e eficaz na resolução de sinistros.',
            'Faça a participação da forma mais cómoda para si por telefone, email ou diretamente na nossa agência.',
          ],
        },
        {
          title: 'Serviços e assistência',
          body: [
            'O seguro Casa tem sempre uma solução para si.',
            'Tem ainda serviços diferenciadores e únicos no mercado segurador, que junto a outros serviços de assistência disponíveis podem tornar o seu dia a dia melhor – conheça alguns deles a seguir.',
          ],
        },
      ],
    },
    {
      subtitle: 'Empregada Doméstica',
      body: [
        'Com o seguro Empregada Doméstica poderá cumprir a obrigação legal que recai sobre quem tem empregados ao seu serviço. Mas não basta garantir todos os cuidados necessários para a recuperação da sua Empregada Doméstica. Existem mudanças no seu dia-a-dia que poderiam surgir se não tivéssemos pensado nelas antes. Os serviços de Assistência Doméstica e Proteção Jurídica procuram responder a estas situações.',
      ],
      boxes:[
        {
          title: 'Empregada Doméstica',
          body: [
            'Proteja quem cuida da sua casa.',
            'Um seguro habitação que se adapta às preocupações de cada cliente e às suas necessidades de proteção.',
          ],
          picture: 1,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Serviços de assistência',
          body: [
            '​A inclusão das coberturas de assistência e proteção jurídica permitem dispor de um seguro mais abrangente e que vai para além da obrigatoriedade legal de efetuar o seguro de Acidentes de Trabalho.​',
          ],
        },
        {
          title: 'Resolução de sinistros',
          body: [
            '​Serviço de qualidade na resolução de sinistros, integrado no Compromisso WeCare​, com uma elevada taxa de sucesso na recuperação e reintegração dos trabalhadores acidentados.',
          ],
        },
        {
          title: 'Serviços ',
          body: [
            'Os trabalhadores acidentados têm acesso a um serviço de assistência privada, através do Serviço Médico Convencionado, possibilitando boas condições de recuperação.​​​',
          ],
        },
      ],
    },
  ]
};
