import React,{useRef,useState,useEffect} from 'react';
import styled from 'styled-components';
import elementWithinViewport from 'element-within-viewport';

import px from 'px.js';

import UnstyledVideo from 'Video';

import ContactForm from 'ContactForm';

const Root = styled.div`
  min-height: 100vh;
  border: 1px solid transparent;
  position: relative;
  scroll-snap-align: start;

  padding-left: ${px.vw(40)};
  padding-top: calc(${px.vw(128)} + (${px.vh(810-128)} - ${px.vw(810-128)})/2);
  @media (min-aspect-ratio: 18/10) {
    padding-left: ${px.vh(40)};
    padding-top: calc(${px.vh(80+36)});
  }

  ${px.portrait}{
    min-height: none;
    padding-left: ${px.mvw(16)};
    padding-right: ${px.mvw(16)};
    padding-top: ${px.mvw(100)};
  }

  & > *{
    position: relative;
    z-index: 2;
  }
`;

const Video = styled(UnstyledVideo)`
  position: absolute;
  width: ${px.vw(813)};
  top: calc(${px.vw(36)} + (${px.vh(810-128)} - ${px.vw(810-128)})/2);
  right: ${px.vw(-43)};
  @media (min-aspect-ratio: 18/10) {
    width: ${px.vh(813)};
    top: ${px.vh(36)};
    right: ${px.vh(-23)};
  }
  z-index: 0;
  ${px.portrait}{
    display: none;
  }
`;

export default function GetInTouch({animate,privateInsurance,...p}){

  const root = useRef();
  const [animationVisible,setAnimationVisible] = useState(true);

  useEffect(()=>{
    if(!root.current)
      return;
    elementWithinViewport(root.current, {
      onEnter: e => { setAnimationVisible(true) },
      onExit: e => { setAnimationVisible(false) }
    });
  },[root,setAnimationVisible]);

  return (
    <Root ref={root} data-alt={privateInsurance} {...p}>
      <Video
        enable={animationVisible && animate}
        src={require(`./assets/get in touch on ${privateInsurance?'light':'dark'}.webm`)}
        poster={require(`./assets/get in touch on ${privateInsurance?'light':'dark'}.png`)}
      />
      <ContactForm privateInsurance={privateInsurance}/>
    </Root>
  );
}
