import { hot } from 'react-hot-loader/root';
import React,{useEffect,useRef,useState,useCallback} from 'react';
import styled from 'styled-components';

import Footer from 'Footer';
import TopBar from 'TopBar';
import Burger from 'Burger';

import Home from 'Home';
import Services from 'Services';
import AboutUs from 'AboutUs';
import News from 'News';
import Assistance from 'Assistance';
import Contacts from 'Contacts';
import E404 from 'E404';
import Policies from 'Policies';

import Popup from 'Popup.js';

import px from 'px.js';

const Root = styled.div`
  background: ${p => p['data-privateinsurance'] ? '#EDF2FA' : '#171C26'};
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  scroll-snap-type: y mandatory;
  ${px.portrait}{
    scroll-snap-type: none;
  }
  color: white;
`;

const trim = (path)=> path.length === 1 ? path : path.replace(/\/$/,'');

function App() {

  const root = useRef();

  const [burgerOpen,setBurgerOpen] = useState(false); // ucomment setBurgerOpen(false) lines down below
  const handleOnBurgerClick = useCallback(()=>{
    setBurgerOpen(burger => !burger);
    setExpanding(false);
  },[setBurgerOpen]);

  const [privateInsurance,setPrivateInsurance] = useState(false);
  const [expanding,setExpanding] = useState(false);
  const expand = useCallback(()=>{
    setExpanding(true);
    setPrivateInsurance(value => !value);
  },[setExpanding]);
  const handleExpanded = useCallback(()=>{
    setExpanding(false);
  },[setPrivateInsurance,setExpanding]);

  const [path,setPath] = useState(trim(window.location.pathname));
  const navigate = useCallback((newPath)=>{
    const trimmedPath = trim(newPath);
    setPath(trimmedPath);
    setBurgerOpen(false);
    window.history.pushState(null,null,trimmedPath);
  },[setPath,setExpanding]);
  useEffect(()=>{
    setBurgerOpen(false);
    setExpanding(false);
  },[path]);
  useEffect(()=>{
    window.history.go = navigate;
    window.onpopstate = ()=>{
      setPath(trim(window.location.pathname));
    };
  },[navigate,setPath]);

  const [delaying,setDelaying] = useState(false);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setDelaying(false);
  //   },1000);
  // },[setDelaying]);

  const checkScroll = useCallback(()=>{
    setExpanding(false);
  },[setExpanding]);

  useEffect(()=>{
    const node = root.current;
    if(!node)
      return;
    node.addEventListener('scroll',checkScroll);
    return ()=>{
      node.removeEventListener('scroll',checkScroll);
    };
  },[root,setExpanding]);

  return (
    <Root id="approot" ref={root} data-privateinsurance={privateInsurance}>

      <TopBar delaying={delaying} path={path} hide={expanding} scrollElement={root} alt={privateInsurance} burgerOpen={burgerOpen} onBurgerClick={handleOnBurgerClick}/>
      <Home delaying={delaying} animate={!burgerOpen && !delaying} scrollElement={root} path={path} expand={expand} expanding={expanding} onExpanded={handleExpanded} privateInsurance={privateInsurance}/>
      { delaying ? null : <>
        <Popup hide={path==='/cookies'}/>
        <Policies path={path} privateInsurance={privateInsurance} />
        <Contacts animate={!burgerOpen} path={path} privateInsurance={privateInsurance}/>
        <Assistance path={path} privateInsurance={privateInsurance}/>
        <Services animate={!burgerOpen} path={path} setPrivateInsurance={setPrivateInsurance}/>
        <AboutUs path={path} privateInsurance={privateInsurance}/>
        <News path={path} privateInsurance={privateInsurance}/>
        <E404 path={path} privateInsurance={privateInsurance}/>
        <Burger privateInsurance={privateInsurance} open={burgerOpen}/>
        <Footer path={path}/>
      </> }
    </Root>
  );
}

export default hot(App);
