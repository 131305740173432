import React,{useMemo,useEffect,useRef,useState,useCallback} from 'react';
import styled from 'styled-components';
import elementWithinViewport from 'element-within-viewport';

import px from 'px.js';

import RawLink from 'Link';

const Root = styled.div`

  padding-left: ${px.vw(40)};
  padding-right: ${px.vw(40)};
  padding-top: ${px.vw(208)};
  padding-bottom: ${px.vw(72)};
  @media (min-aspect-ratio: 18/10) {
    padding-left: ${px.vh(40)};
    padding-right: ${px.vh(40)};
    padding-top: ${px.vh(208)};
    padding-bottom: ${px.vh(72)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    padding-left: ${16/20}em;
    padding-right: ${16/20}em;
    padding-top: ${120/20}em;
    padding-bottom: ${80/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }

  & p{
    margin: 0;
    min-height: 1em;
  }
`;

const Title = styled.div`
  font-size: ${px.vw(68)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(68)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(40)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(40)};
  }
  font-weight: bold;
  line-height: .96;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const Subtitle = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(30)};
  }
  margin-top: ${100/40}em;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;

const Description = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  margin-top: ${24/20}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
`;
const Body = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }

  margin-top:  ${20/16}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }

  max-width: ${646/16}em;

`;

const Buttons = styled.div`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  max-width: 80vw;
  ${px.portrait}{
    max-width: none;
    font-size: ${px.mvw(14)};
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: ${8/14}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  margin-top: ${40/14}em;

`;
const Button = styled.div`
  display: inline-block;
  vertical-align: top;

  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }

  font-weight: normal;
  line-height: ${45/14};

  cursor: pointer;

  color: #98A1B2;
  opacity: 0.7;
  border: ${1/14}em solid rgba(152, 161, 178, 0.5);
  &:hover{
    border: ${1/14}em solid rgba(152, 161, 178);
    opacity: 1;
  }
  &[data-alt=true]{
    border: ${1.5/14}em solid #171C26;
    color: #171C26;
  }

  height: ${45/14}em;
  margin-right: ${8/14}em;
  margin-bottom: ${8/14}em;
  padding: 0 ${24/14}em;

  &[data-selected=true]{
    /* font-weight: 600; */
    border: none;
    color: #EDF2FA;
    background: #00AEEA;
    opacity: 1;
  }
`;
const Boxes = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top: ${60/16}em;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: ${24/16}em;
  grid-row-gap: ${24/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    grid-template-columns: 100%;
    grid-auto-flow: unset;
    grid-row-gap: ${16/16}em;
  }
`;
const Boxes4 = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top: ${60/16}em;
  display: block;
  ${px.portrait}{
    display: grid;
    font-size: ${px.mvw(16)};
    grid-template-columns: 100%;
    grid-auto-flow: unset;
    grid-row-gap: ${16/16}em;
  }
`;
const Box4 = styled.div`
  position: relative;
  background: #1F2633;

  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  display: inline-block;
  padding: ${32/16}em;
  margin-right: ${24/16}em;
  &:last-child{
    margin-right: 0;
  }
  box-sizing: border-box;
  width: ${430/16}em;
  vertical-align: top;

  ${px.portrait}{
    display: block;
    width: 100%;
    font-size: ${px.mvw(16)};
    padding: ${24/16}em;
  }

  overflow: hidden;
`;


const Boxes2 = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top: ${60/16}em;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: ${24/16}em;
  grid-row-gap: ${84/16}em;
  margin-bottom: ${84/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    grid-template-columns: 1fr;
    grid-row-gap: ${64/16}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
`;
const Boxes3 = styled(Boxes2)`
  grid-template-columns: repeat(4,1fr);
  ${px.portrait}{
    grid-row-gap: ${8/16}em;
  }
`;

const Box2 = styled.div`
  display: block;
  position: relative;
`;

const BoxTitle = styled.div`
  position: relative;
  z-index: 1;
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  padding-right: 20%;
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.01em;
  color: #CEDAF2;
  /* margin-top: ${-56/20}em; */
  margin-bottom: ${26/20}em;
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    margin-bottom: ${8/20}em;
  }
`;
const Box4Title = BoxTitle;

const BoxText = styled.div`
  position: relative;
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.landscape}{
    &[data-onlybox=true]{
      max-width: ${449/16}em;
    }
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B3;

  & a{
    color: inherit;
    text-decoration: none;
  }
  & [data-subtitle=true]{
    font-weight: 600;
    color: #CEDAF2;
    line-height: 2;
    margin-top: 0.5em;
  }
`;
const Box4Text = BoxText;

const Box = styled.div`
  display: block;
  position: relative;
  background: #1F2633;

  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  padding: ${32/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    padding: ${24/16}em;
  }

  &:last-child{
    background: #475266;
  }

  overflow: hidden;
`;

const Box2Header = styled.div`
  display: block;

  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: 0.04em;

  color: #98A1B3;
  padding-bottom: 1em;
  border-bottom: ${1/12}em solid #475266;
  &[data-alt=true]{
    color: #475266;
    border-bottom: ${1/12}em solid #98A1B3;
  }

  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(12)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
  }
`;


const Box3Header = styled(p => (
  <div {...p}>
    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="1" cx="8" cy="8" r="8"/>
    </svg>
  </div>
))`
  display: block;
  position: relative;

  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }

  margin-left: ${(20+8)/16}em;
  & svg{
    width: ${20/16}em;
    height: ${20/16}em;
    position: absolute;
    top: -${8/16}em;
    left: -${(20+8)/16}em;
  }

  border-bottom: ${1/12}em solid #475266;
  & svg circle{
    stroke: #475266;
  }
  &[data-alt=true]{
    border-bottom: ${1/12}em solid #98A1B3;
    & circle{
      stroke: #98A1B2;
    }
  }

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    margin: 0;
    position: absolute;
    left: ${16/2/16}em;
    top: ${(16+8+4)/16}em;
    height: calc(100% - ${(16+8)/16}em);
    border-bottom: none;
    border-left: ${1/16}em solid #475266;
    &[data-alt=true]{
      border-bottom: none;
      border-left: ${1/16}em solid #98A1B3;
    }
    & svg{
      position: relative;
      top: ${-(16+8)/16}em;
      left: ${-16/2/16}em;
      width: ${16/16}em;
      height: ${16/16}em;
    }
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
`;
const Box3 = styled.div`
  position: relative;
  padding-left: ${(24+8)/16}em;
  padding-bottom: ${40/16}em;
  ${px.portrait}{
    &:last-child ${Box3Header}{
      border-left: none;
    }
  }
`;

const Box2Title = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.01em;
  margin-top: ${40/20}em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }


  ${px.portrait}{
    font-size: ${px.mvw(20)};
    margin-top: ${20/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
`;
const Box2Text = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  margin-top: 1em;
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
`;
const Box3Text = styled(Box2Text)`
  ${px.portrait}{
    margin-top: 0;
  }
`;

const FootNote = styled.div`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
  max-width: ${472/14}em;
  margin-top: 8em;
  line-height: 1.5;
  font-weight: normal;
  & a{
    text-decoration: none;
    font-weight: bold;
    color: #CEDAF2;
    &[data-alt=true]{
      color: #171C26;
    }
  }
`;

const Bullet = styled(
  p =>
  <svg {...p} width="100" height="78" viewBox="0 0 100 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.2918 76.3577C37.3372 77.3179 36.0349 77.8536 34.6821 77.8536C33.3292 77.8536 32.0269 77.3179 31.0723 76.3577L2.24382 47.5245C-0.747942 44.5328 -0.747942 39.6814 2.24382 36.6953L5.85358 33.0846C8.84628 30.0928 13.692 30.0928 16.6838 33.0846L34.6821 51.0838L83.316 2.4489C86.3087 -0.542864 91.1591 -0.542864 94.1462 2.4489L97.756 6.05959C100.748 9.05136 100.748 13.9018 97.756 16.8889L38.2918 76.3577Z" fill="#CEDAF2"/>
  </svg>
)`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
  display: inline-block;
  height: ${9*1.2/16}em;
  width: ${11.6*1.2/16}em;
  margin-right: ${6/16}em;
`;

const parseText = (text)=>{
  var result = [text];

  const idx = text.indexOf('•');
  if(idx > -1){
    result = [
      <span key={1}>{text.substr(0,idx)}</span>,
      <Bullet key={2}/>,
      <span key={3}>{text.substr(idx+1)}</span>
    ];
  }
  for(const i in result){
    if(typeof(result[i]) !== 'string')
      continue;
    const idx1 = result[i].indexOf('*');
    if(idx1 === -1)
      continue;
    const idx2 = result[i].indexOf('*',idx1+1);
    if(idx2 === -1)
      continue;
    result[i] = [
      <span key={1}>{result[i].substr(0,idx1)}</span>,
      <span key={3} style={{fontWeight: 'bolder'}}>
        {result[i].substr(idx1+1,idx2-1)}
      </span>,
      <span key={1}>{result[i].substr(idx2+1)}</span>
    ];
  }
  for(const i in result){
    if(typeof(result[i]) !== 'string')
      continue;
    const protocol = result[i].substr(0,result[i].indexOf(':')+1);
    if(protocol === 'tel:'){
      result[i] = (
        <a
          href={protocol+result[i].substr(protocol.length).replace(/[^0-9\+]/g,'')}
        >{result[i].substr(protocol.length)}</a>
      );
    }else if(protocol === 'mailto:'){
      result[i] = (<a href={result[i]}>{result[i].substr(protocol.length)}</a>);
    }
  }
  return result;
};

const data = {
  title: 'Sinistros e Assistência',
  description: 'Sempre consigo, em qualquer circunstância',
  buttons: [
    'Auto',
    'Casa',
    'Pets',
  ],
  pages: [
    {
      boxes:[
        {
          title: 'Se necessitar de reboque ou outro tipo de ajuda:',
          body:[
            {
              subtitle: 'Telefone: ',
              text: [
                'tel:21 440 50 08',
                'tel:(no estrangeiro: + 351 21 441 73 73)',
              ],
            },
            {
              subtitle: 'Horário: ',
              text: ['24 horas por dia, todos os dias da semana.​​​​'],
            },
          ],
        },
        {
          title: 'Para participar um sinistro ou outras situações:',
          body:[
            {
              subtitle: 'Telefone: ',
              text: ['tel:+351 915 207 888'],
            },
            {
              subtitle: 'E-mail: ',
              text: ['mailto:geral@portugalseguro.pt'],
            },
          ],
        }
      ],
      title2: 'Como agir',
      boxes2:[
        {
          title: 'Sinalizar o local do acidente',
          body: ['Vestir o colete refletor e sinalizar o local, colocando o triângulo a pelo menos 30 metros; e, se possível, ligar os quatro piscas.'],
        },
        {
          title: 'Há feridos?',
          body: ['Se do acidente resultarem feridos, ligar imediatamente para o 112 e não tentar mover ou deslocar os feridos.'],
        },
        {
          title: 'Preencher a declaração amigável',
          body: ['Este documento é a forma mais prática de participar um sinistro, pois reúne um conjunto de informações indispensáveis para que a Companhia o possa regularizar com a máxima brevidade. '],
        },
        {
          title: 'Não assumir qualquer responsabilidade pelo acidente',
          body: ['​Em situações em que as circunstâncias do acidente não forem bem claras ou existir conflitos, deve-se chamar as autoridades para que o mesmo fique registado.'],
        },
        {
          title: 'Que documentos são necessários ter no momento do sinistro?',
          body: ['O certificado de seguro (certificado provisório ou carta verde), assim como o dístico do seguro, que deve ser colocado num local bem visível.'],
        },
        {
          title: 'O que fazer em caso de furto ou roubo do veículo?',
          body: [
            'Comunicar o furto ou roubo do veículo no posto da Polícia mais próximo do local e solicitar uma cópia do Auto de ocorrência.',
            'Caso tenha contratado a cobertura de Furto ou Roubo, participe a ocorrência também à Portugal Seguro.',
          ],
        },
      ],
      title3: 'Participe o sinistro no prazo de 8 dias',
      boxes3:[
        {
          body: ['Mesmo que não seja culpado, participe o sinistro através do envio da cópia da Declaração Amigável (DAAA).'],
        },
        {
          body: ['Não se esqueça de indicar a oficina pretendida para a realização da peritagem.'],
        },
        {
          body: ['Consulte a nossa Rede de Oficinas Convencionadas onde poderá efetuar a reparação do seu automóvel.​'],
        },
        {
          body: ['​Pode ainda beneficiar do serviço Help‑a‑​Car​.'],
        },
      ],
      boxes4:[
        {
          title: 'Para participar',
          body:[
            {
              subtitle: '',
              text: ['Faça a participação do sinistro através da Portugal Seguro. Pode registar a sua participação por e-mail ou telefone.'],
            },
            {
              subtitle: 'Email:',
              text: ['mailto:geral@portugalseguro.pt'],
            },
            {
              subtitle: 'Telefone:',
              text: ['+351 915 207 888'],
            },
          ],
        },
        {
          title: 'Vidro partido?',
          body:[
            {
              subtitle: '',
              text: ['Caso tenha a cobertura Quebra Isolada de Vidros contacte diretamente uma das empresas especializadas em reparação e substituição de vidros automóveis. '],
            },
          ],
        },
        {
          title: 'Passos da resolução',
          body:[
            {
              subtitle: 'Marcação de peritagem: ',
              text: ['A peritagem do seu veículo será agendada no prazo máximo de 2 dias.'],
            },
            {
              subtitle: 'Responsabilidade: ',
              text: ['Define-se num máximo de 30 dias úteis. Caso a Companhia se responsabilize, o pagamento faz-se em 8 dias úteis.'],
            },
            {
              subtitle: 'Houve feridos? ',
              text: ['Há procedimentos específicos para garantir o acompanhamento médico.'],
            },
          ],
        },
      ],
    },
    {
      boxes:[
        {
          title: 'Se tiver um sinistro e precisar de entrar em contacto com a Companhia contacte:',
          body:[
            {
              subtitle: 'Telefone: ',
              text: ['tel:808 205 004'],
            },
            {
              subtitle: 'Horário: ',
              text: ['Das 8h30 às 22h00, nos dias úteis'],
            },
          ],
        },
        {
          title: 'Para participar um sinistro ou outras situações: ',
          body:[
            {
              subtitle: 'Telefone: ',
              text: ['tel:+351 915 207 888'],
            },
            {
              subtitle: 'E-mail: ',
              text: ['mailto:geral@portugalseguro.pt'],
            },
          ],
        },
      ],
      title2: 'Como agir',
      boxes2:[
        {
          title: 'Em caso de emergência',
          body: ['Ligue para o 112. O centro de emergência acionará os sistemas médicos, policial ou de incêndio necessários.'],
        },
        {
          title: 'Não assumir qualquer responsabilidade pelo sinistro',
          body: ['​É importante que não se assuma qualquer responsabilidade pelo sinistro perante terceiros, antes de falar com a Companhia.'],
        },
        {
          title: 'Assistência ao lar',
          body: ['Disponível 24 horas por dia, o serviço de Assistência ao Lar presta o apoio necessário na solução de imprevistos ou na manutenção da sua casa.'],
        },
        {
          title: '​​Se ocorrer uma inundação por rotura de canalização do edifício, o que devo fazer?',
          body: ['Deverá ligar imediatamente para a Linha Azul (808 205 004) ou para a linha de Assistência ao Lar (214 405 055).'],
        },
      ],
      title3: 'Participe o sinistro no prazo de 8 dias',
      boxes3:[
        {
          body: ['A participação telefónica permite uma maior rapidez, quer na abertura, quer na regularização do processo do sinistro.'],
        },
        {
          body: ['Pode também efetuar a participação de sinistros com a Portugal Seguro.'],
        },
        {
          body: ['Em caso de roubo ou furto, participe, de imediato, a ocorrência às autoridades policiais competentes. Posteriormente, envie a certidão com a relação dos bens furtados ou roubados à Companhia.'],
        },
        {
          body: ['Caso tenha um sinistro de Danos por água faça participação através da Declaração Amigável de Danos por Água.'],
        },
      ],
      boxes4:[],
    },
    {
      boxes:[
        {
          title: '​Se ​quiser acionar algum dos serviços da cobertura Pet Assistência, contacte:',
          body:[
            {
              subtitle: 'Telefone: ',
              text: ['tel:808 29 39 49'],
            },
            {
              subtitle: 'Horário: ',
              text: ['24 horas por dia'],
            },
          ],
        },
        {
          title: 'Para participar um sinistro ou outras situações: ',
          body:[
            {
              subtitle: 'Telefone: ',
              text: ['tel:+351 915 207 888'],
            },
            {
              subtitle: 'E-mail: ',
              text: ['mailto:geral@portugalseguro.pt'],
            },
          ],
        },
      ],
      title2: 'Como proceder',
      boxes2:[
        {
          title: 'Em caso de pedido de reembolso de despesas de veterinário',
          body: ['Preenchaa Participação de Sinistro​ e anexe o Relatório do Veterinário e os documentos de despesas.'],
        },
        {
          title: 'Em caso de necessidades de serviços de assistência',
          body: ['Disponível 24 horas por dia, o serviço Pet Assistência pode ser solicitado nos casos em necessite de um veterinário em casa, entrega de medicamentos ao domicílio, transporte de urgência do animal até ao hospital veterinário mais próximo e mesmo ajuda na organização do funeral do seu animal de companhia.  '],
        },
        {
          title: 'Em caso de necessidade de defesa jurídica',
          body: ['Em situação que necessite de apoio jurídico, pode solicitar Proteção Jurídica, contactando o nº indicado acima, nos dias e horários indicados, para orientação de como proceder em caso de litígio que envolva o seu animal de companhia.​'],
        },
      ],
      title3: 'Como participar',
      boxes3:[
        {
          body: ['Para solicitar a organização de funeral do seu animal de companhia, contacte-nos  através do número 808 29 39 49 ou +351 21 440 50 21, para receber todo o apoio que necessita nesse momento e tratar de todo o serviço fúnebre.​'],
        },
        {
          body: ['Para participar um sinistro de responsabilidade civil preencha a Participação de Sinistro​​ e anexe os documentos solicitados. Em caso de dúvida, ligue 808 29 39 49 ou +351 21 440 50 21. Participe o sinistro num prazo de 8 dias.'],
        },
        {
          body: ['É importante que não assuma qualquer responsabilidade pelo sinistro perante terceiros, antes de falar com a Companhia.'],
        },
        {
          body: ['Deve-se manter os bens danificados que resultem do sinistro à disposição dos serviços da Companhia, que poderá solicitá-los para visualização.​'],
        },
      ],
      boxes4:[],
    },
  ]
};


export default function Assistance({path,privateInsurance}){

  const hide = useMemo(()=>{
    return path !== '/sinistros-e-assistencia';
  },[path]);

  const [page,setPage] = useState(0);
  const handleButtonClick = useCallback(ev => {
    setPage(parseInt(ev.target.dataset.targetpage));
  },[setPage]);
  useEffect(()=>{
    setPage(0);
  },[path]);

  return hide ? null : (
    <Root>
      <Title data-alt={privateInsurance}>{data.title}</Title>
      <Description data-alt={privateInsurance}>{data.description}</Description>
      <Buttons>{data.buttons.map((label,idx)=>(
        <Button
          data-alt={privateInsurance}
          data-selected={idx === page}
          data-targetpage={idx}
          key={idx}
          onClick={handleButtonClick}
        >
          {label}
        </Button>
      ))}
      </Buttons>
      <Boxes
        data-gridtemplate={data.pages[page].gridtemplate}
      >{data.pages[page].boxes.map((box,idx) => (
        <Box key={idx}>
          <BoxTitle>{box.title}</BoxTitle>
          <BoxText>{
            box.body.map((section,idx) => (<>
              {section.subtitle ? <p data-subtitle={true}>{section.subtitle}</p> : null}
              {section.text.map((line,idx)=>(
                <p key={idx}>{parseText(line)}</p>
              ))}
            </>))
          }</BoxText>
        </Box>
      ))}</Boxes>
      <Subtitle data-alt={privateInsurance}>{data.pages[page].title2}</Subtitle>
      <Boxes2>{data.pages[page].boxes2.map((box,idx) => (
        <Box2 key={idx}>
          <Box2Header data-alt={privateInsurance}>#{idx+1}</Box2Header>
          <Box2Title data-alt={privateInsurance}>{box.title}</Box2Title>
          <Box2Text data-alt={privateInsurance}>{
            box.body.map((text,idx) => <p key={idx}>{text}</p>)
          }</Box2Text>
        </Box2>
      ))}</Boxes2>
      <Subtitle data-alt={privateInsurance}>{data.pages[page].title3}</Subtitle>
      <Boxes3>{data.pages[page].boxes3.map((box,idx) => (
        <Box3 key={idx}>
          <Box3Header data-alt={privateInsurance}/>
          <Box3Text data-alt={privateInsurance}>{
            box.body.map((text,idx) => <p key={idx}>{text}</p>)
          }</Box3Text>
        </Box3>
      ))}</Boxes3>
      <Boxes4
        data-gridtemplate={data.pages[page].gridtemplate}
      >{data.pages[page].boxes4.map((box,idx) => (
        <Box4 key={idx}>
          <Box4Title>{box.title}</Box4Title>
          <Box4Text>{
            box.body.map((section,idx) => (<>
              {section.subtitle ? <p data-subtitle={true}>{section.subtitle}</p> : null}
              <p key={idx}>{parseText(section.text)}</p>
            </>))
          }</Box4Text>
        </Box4>
      ))}</Boxes4>
      <FootNote data-alt={privateInsurance}>
        A informação constante deste site sobre este Seguro não dispensa a consulta da informação pré-contratual e contratual legalmente exigida em
        <a data-alt={privateInsurance} href="https://fidelidade.pt" rel="noopener noreferrer"> fidelidade.pt</a>
      </FootNote>
    </Root>
  );
}
