
const serviceList = [
  '/empresas/multirriscos',
  '/empresas/saude',
  '/empresas/automovel',
  '/empresas/cyber-safety',
  '/empresas/acidentes-de-trabalho',
  '/empresas/pequeno-negocio',
  '/empresas/produtos-empresas',
  '/particular/saude',
  '/particular/auto',
  '/particular/habitacao',
  '/particular/pets',
  '/particular/poupanca',
  '/particular/viagens-e-lazer',
  '/particular/familia',
];

const mapping = {};

for(const service of serviceList)
  mapping[service] = require(`.${service}.js`).default;

export {serviceList};
export default mapping;
