import React,{useMemo,useEffect,useRef,useState,useCallback} from 'react';
import styled from 'styled-components';
import elementWithinViewport from 'element-within-viewport';

import px from 'px.js';

import arrow from './assets/arrow.svg';

import peopleArrow from './Quote/assets/arrow.svg';
import peopleArrowAlt from './Quote/assets/arrow-alt.svg';

import RawLink from 'Link';
import Quote from './Quote';

import picture1 from './assets/1.jpg';
import picture2 from './assets/2.jpg';
import picture3 from './assets/3.jpg';
import linkedin from './assets/linkedin.svg';
import fidelidade from 'Home/Header/assets/fidelidade.svg';
import fidelidadeAlt from 'Home/Header/assets/fidelidade-alt.svg';
import multicare from 'Home/Header/assets/multicare.svg';
import multicareAlt from 'Home/Header/assets/multicare-alt.svg';

import wabi from './assets/wabi.svg';
import wabiAlt from './assets/wabi-alt.svg';
// import centaurValley from './assets/centaur-valley.svg';
// import centaurValleyAlt from './assets/centaur-valley-alt.svg';
import fixpacos from './assets/fixpacos.svg';
import fixpacosAlt from './assets/fixpacos-alt.svg';
import gadgetHome from './assets/gadget-home.svg';
import gadgetHomeAlt from './assets/gadget-home-alt.svg';
import gadgetFun from './assets/gadget-fun.svg';
import gadgetFunAlt from './assets/gadget-fun-alt.svg';
// import fixpacos from './assets/fixpacos.svg';
// import partners from 'Home/Header/assets/partners.svg';
// import partnersAlt from 'Home/Header/assets/partners-alt.svg';

const Root = styled.div`
  padding-left: ${px.vw(40)};
  padding-right: ${px.vw(40)};
  padding-top: ${px.vw(208)};
  @media (min-aspect-ratio: 18/10) {
    padding-left: ${px.vh(40)};
    padding-right: ${px.vh(40)};
    padding-top: calc(${px.vw(80)} + ${px.vh(208 - 80)});
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    padding-left: ${16/20}em;
    padding-right: ${16/20}em;
    padding-top: ${120/20}em;
    padding-bottom: ${80/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
`;

const Title = styled.div`
  font-size: ${px.vw(68)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(68)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(40)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(40)};
  }
  font-weight: bold;
  line-height: .96;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const Subtitle = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  max-width: ${531/40}em;
  ${px.portrait}{
    max-width: none;
    font-size: ${px.mvw(30)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(30)};
  }
  margin-top: ${100/40}em;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;

const Description = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  margin-top: ${24/20}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
`;
const Body = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }

  margin-top:  ${20/16}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }

  max-width: ${646/16}em;

  & p + p{
    margin-top: 1.25em;
  }
`;

const Buttons = styled.div`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: ${8/14}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  margin-top: ${40/14}em;

`;
const Button = styled.div`
  display: inline-block;
  vertical-align: top;

  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }

  font-weight: normal;
  line-height: ${45/14};

  cursor: pointer;

  color: #98A1B2;
  opacity: 0.7;
  border: ${1/14}em solid rgba(152, 161, 178, 0.5);
  &:hover{
    border: ${1/14}em solid rgba(152, 161, 178);
    opacity: 1;
  }
  &[data-alt=true]{
    border: ${1/14}em solid #171C2660;
    color: #171C26;
  }

  height: ${45/14}em;
  margin-right: ${8/14}em;
  margin-bottom: ${8/14}em;
  padding: 0 ${24/14}em;

  &[data-selected=true]{
    /* font-weight: 600; */
    border: none;
    color: #EDF2FA;
    background: #00AEEA;
    opacity: 1;
  }
`;
const Boxes2 = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  margin-top: ${60/16}em;
  margin-bottom: ${84/16}em;

  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${24/12}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    grid-template-columns: 1fr;
    grid-auto-flow: unset;
    grid-column-gap: ${0/12}em;
    grid-row-gap: ${64/16}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
`;

const Box2 = styled.div`
  display: block;
`;

const Arrow = styled(
  p => <img alt="" src={arrow} {...p}/>
)`



`;


const Box2Header = styled.div`
  display: block;

  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: 0.04em;

  color: #98A1B3;
  padding-bottom: 1em;
  border-bottom: ${1/12}em solid #475266;
  &[data-alt=true]{
    color: #475266;
    border-bottom: ${1/12}em solid #98A1B3;
  }



  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(12)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
  }
`;
const Box2Title = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.01em;
  margin-top: ${40/20}em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }


  ${px.portrait}{
    font-size: ${px.mvw(20)};
    margin-top: ${20/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
`;
const Box2Text = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  margin-top: 1em;
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
`;


const PeopleArrowContainer = styled.div`
  font-size: ${px.vw(18)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(18)};
  }

  text-align: right;
  ${px.portrait}{
    display: none;
  }

  margin-top: ${60/16}em;
  margin-bottom: ${32/16}em;
`;
const PeopleArrow = styled(({alt,flip,...p}) => <div {...p}/>)`
  display: inline-block;
  width:  ${80/18}em;
  height:  ${18/18}em;

  &:first-child{
    margin-right: ${24/18}em;
  }

  background-image: url(${p => p.alt ? peopleArrowAlt : peopleArrow});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;

  opacity: 0.4;
  &:hover{
    opacity: 1;
  }
  transition-duration: 300ms;
  transition-property: opacity;

  transform: scaleX(${p => p.flip ? -1 : 1});

  cursor: pointer;
`;
const People = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(16)}; */
  }
  display: grid;
  grid-template-columns: repeat(5,${430/16}em);
  grid-column-gap: ${24/16}em;
  grid-row-gap: ${84/16}em;
  margin-bottom: ${100/16}em;
  ${px.landscape}{
    & > *:last-child{
      display: none;
    }
  }
  transform: translateX(${p => -p['data-position']*(437+24)/16}em);
  transition-duration: 500ms;
  transition-property: transform;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    margin-top: ${110/16}em;
    margin-bottom: ${84/16}em;
    grid-template-columns: 90vw 90vw 90vw 90vw 90vw ${8/16}em;
    transform: none;
    grid-column-gap: ${8/16}em;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    width: calc(100% + ${32/16}em);
    margin-left: ${-16/16}em;
    padding: ${16/16}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
`;
const PersonPictureBackground = styled(
  ({...p}) => (
    <a
      rel="noopener noreferrer"
      target=" _blank"
      {...p}
    />
  )
)`
  display: block;
  background: #1F2633;
  &:hover{
    background: #242A38;
  }
`;
const PersonPicture = styled.img`
  width: 100%;
  mix-blend-mode: soft-light;

`;
const Person = styled.div`
  position: relative;
  ${px.portrait}{
    scroll-snap-align: center;
  }
  &:nth-child(1) ${PersonPicture}{
    opacity: 1;
    filter: brightness(105%) contrast(140%);
  }
  &:nth-child(2) ${PersonPicture}{
    opacity: 1;
    filter: brightness(110%) contrast(100%);
  }
  &:nth-child(3) ${PersonPicture}{
    opacity: 1;
    filter: brightness(110%) contrast(120%);
  }
  &:nth-child(4) ${PersonPicture}{
    opacity: 1;
    filter: brightness(110%) contrast(110%);
  }
  &:nth-child(5) ${PersonPicture}{
    opacity: 1;
    filter: brightness(120%) contrast(110%);
  }
`;

const PersonName = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(20)}; */
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  letter-spacing: -0.01em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
  margin-top: ${32/20}em;
`;
const PersonJob = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.00em;
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
  margin-top: ${8/16}em;
`;
const LinkedIn = styled(
  p => <img src={linkedin} {...p}/>
)`
  position: absolute;
  width: ${32/16}em;
  height: ${32/16}em;
  right: ${16/16}em;
  top: ${(470 - 32 - 16)/16}em;
  ${px.portrait}{
    top: calc(90vw - ${16/16}em);
  }
`

const Boxes = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top: ${110/16}em;
  margin-bottom: ${84/16}em;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${24/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    grid-template-columns: 100%;
    grid-auto-flow: unset;
    grid-row-gap: ${16/16}em;
  }
`;
const Box = styled.div`
  display: block;
  position: relative;
  background: #1F2633;

  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  padding: ${32/16}em;
  /* padding-bottom: ${68/16}em; */

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    padding: ${24/16}em;
    /* padding-bottom: ${68/16}em; */
  }

  overflow: hidden;
`;
const BoxPicture = styled.img`
  font-size: ${px.vw(16)};
  position: relative;
  width: 50%;
  left: ${-24/16}em;
  top: ${-16/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 70%;
  }
`;
const BoxTitle = styled.div`
  position: relative;
  z-index: 1;
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.01em;
  color: #CEDAF2;
  /* margin-top: ${-56/20}em; */
  margin-bottom: ${16/20}em;
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    margin-bottom: ${8/20}em;
  }
`;
const BoxText = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B3;
`;



const Center = styled.div`
  text-align: center;
  & > *{
    text-align: inherit;
  }
  & > ${Body}{
    display: inline-block;
  }
  ${px.portrait}{
    text-align: left;
  }
`;
const PartnersLogo = styled.div`
  white-space: nowrap;
  font-size: ${px.vw(20)};
  overflow-y: hidden;
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  margin-bottom: ${120/20}em;
  ${px.portrait}{
    margin-bottom: ${0/20}em;
    font-size: ${px.mvw(24)};
    overflow-x: scroll;
    width: 100%;
    scroll-snap-type: x mandatory;
    padding-right: 100vw;
    & > *{
      scroll-snap-align: start;
    }
  }
  ${px.tablet}{
    font-size: ${px.tvw(24)};
  }
  display: block;
  margin-top: ${40/20}em;
  & img{
    vertical-align: top;
    width: ${180*1.2/20}em;
  }
  height: ${80/20}em;
  &[data-type="comercial"]{
    & img{
      position: relative;
      top: ${-14/20}em;
      width: ${220*1.2/20}em;
      margin-right: ${20/20}em;
      ${px.portrait}{
        width: ${200*1.2/20}em;
      }
      &:last-child{
        position: relative;
        top: ${-5/20}em;
        width: ${100*1.2/20}em;
        margin-left: ${10/20}em;
        margin-right: ${20/20}em;
        ${px.portrait}{
          top: ${-10/20}em;
          width: ${100*1.2/20}em;
        }
      }
    }
  }
  &[data-type="institutional"]{
    & img{
      position: relative;
      top: ${20/20}em;
      &:first-child{
        margin-right: ${60/20}em;
        ${px.portrait}{
          margin-right: ${45/20}em;
        }
      }
      &:last-child{
        width: ${160*1.45/20}em;
        position: relative;
        top: ${(20-2)/20}em;
      }
    }
  }
`;
const PartnersText = styled(Body)`
 height: 5em;
 ${px.portrait}{
   height: 11em;
 }
`;

export default function AboutUs({path,privateInsurance}){

  const hide = useMemo(()=>{
    return path !== '/sobre-nos';
  },[path]);

  const positions = 3;
  const [position,setPosition] = useState(0);

  const next = useCallback(()=>{
    setPosition(position => (position-1+positions)%positions);
  },[setPosition]);
  const previous = useCallback(()=>{
    setPosition(position => (position+1+positions)%positions);
  },[setPosition]);

  const [toggle,setToggle] = useState(false);

  const handleToggle = useCallback(()=>{
    setToggle(t=>!t);
  },[setToggle]);

  return hide ? null : (
    <Root>
      <Title data-alt={privateInsurance}>Sobre nós</Title>
      <Body data-alt={privateInsurance}>
        Somos uma empresa que ambiciona de forma continua ser a mais eficiente e eficaz na sua área de atuação, o que nos inspira a pensar de forma inovadora, a evoluir e a adaptar-nos.
        <br/> A nossa paixão e o nosso desejo de fazer a diferença é evidente em tudo o que fazemos. Sempre orientados para o cliente e com reconhecida experiência, tornamo-nos uma das principais e mais confiáveis empresas a operar no ramo de seguros para empresas ou particulares. Estamos orgulhosos de servir uma vasta carteira de clientes, merecendo a sua confiança em temas tão sensíveis e importantes.
      </Body>
      <Body data-alt={privateInsurance}>
        Investimentos em inovação, atendimento ao cliente e operações sustentam o futuro da Portugal Seguro.  A nossa empresa está preparada para crescer, investir, expandir-se e agregar valor aos seus parceiros e clientes. Prepare-se para o futuro, connosco.
      </Body>

      <Boxes2>
        <Box2>
          <Box2Header data-alt={privateInsurance}>#1</Box2Header>
          <Box2Title data-alt={privateInsurance}>Missão</Box2Title>
          <Box2Text data-alt={privateInsurance}>
            Comprometemos-nos a informar, servir, acompanhar e cuidar das pessoas ao longo da vida com produtos e serviços inovadores que, efetivamente, protegem as pessoas.
          </Box2Text>
        </Box2>
        <Box2>
          <Box2Header data-alt={privateInsurance}>#2</Box2Header>
          <Box2Title data-alt={privateInsurance}>Visão</Box2Title>
          <Box2Text data-alt={privateInsurance}>
            Como uma marca que acredita na vida, pretendemos inspirar o Ser Humano pelo bom exemplo, contribuído de forma geral para a felicidade das pessoas e das organizações.
          </Box2Text>
        </Box2>
        <Box2>
          <Box2Header data-alt={privateInsurance}>#3</Box2Header>
          <Box2Title data-alt={privateInsurance}>Valores</Box2Title>
          <Box2Text data-alt={privateInsurance}>
            Fazer negócios com integridade, focando as reais necessidades de cada pessoa ou organização, está no centro dos nossos compromissos de responsabilidade corporativa.
          </Box2Text>
        </Box2>
      </Boxes2>

      <Subtitle data-alt={privateInsurance}>Diversidade e inclusão são parte integrante da cultura da nossa empresa</Subtitle>
      <Body data-alt={privateInsurance}>
        Os nossos valores estão enraizados no respeito: respeito por nós mesmos, pelo outro, respeito pela diversidade e respeito pelo futuro. O  nosso objetivo é incorporar diversidade e inclusão em tudo o que fazemos, focando três áreas principais: cultura, inovação e sociedade. Isso envolve a construção de uma cultura inclusiva que valorize os diferentes talentos e experiências dos nossos colaboradores, inovando para atender às necessidades dos nossos diversos clientes e interagindo com a sociedade nos diversos mercados em que operamos.
      </Body>

      <PeopleArrowContainer>
        <PeopleArrow onClick={next} alt={privateInsurance}/>
        <PeopleArrow onClick={previous} alt={privateInsurance} flip={true}/>
      </PeopleArrowContainer>

      <People data-position={position}>
        <Person>
          <PersonPictureBackground href="https://www.linkedin.com/in/romulo-costa-066156138/">
            <PersonPicture src={require('./assets/Rómulo Costa.png')}/>
          </PersonPictureBackground>
          <PersonName data-alt={privateInsurance}>Rómulo Costa</PersonName>
          <PersonJob data-alt={privateInsurance}>CEO</PersonJob>
          <LinkedIn/>
        </Person>
        <Person>
          <PersonPictureBackground href="https://www.linkedin.com/in/marques-pereira-56619aaa/">
            <PersonPicture src={require('./assets/Marques Pereira.png')}/>
          </PersonPictureBackground>
          <PersonName data-alt={privateInsurance}>Marques Pereira</PersonName>
          <PersonJob data-alt={privateInsurance}>CEO</PersonJob>
          <LinkedIn/>
        </Person>
        <Person>
          <PersonPictureBackground href="https://www.linkedin.com/in/ricardo-magalhaes-635b46aa/">
            <PersonPicture src={require('./assets/Ricardo Magalhães.png')}/>
          </PersonPictureBackground>
          <PersonName data-alt={privateInsurance}>Ricardo Magalhães</PersonName>
          <PersonJob data-alt={privateInsurance}>COO</PersonJob>
          <LinkedIn/>
        </Person>
        <Person>
          <PersonPictureBackground href="https://www.linkedin.com/in/eliane-borges-a24409169/">
            <PersonPicture src={require('./assets/Eliane Borges.png')}/>
          </PersonPictureBackground>
          <PersonName data-alt={privateInsurance}>Eliane Borges</PersonName>
          <PersonJob data-alt={privateInsurance}>Front Office Assistant</PersonJob>
          <LinkedIn/>
        </Person>
        <Person>
          <PersonPictureBackground>
            <PersonPicture src={require('./assets/Paula Moreira.png')}/>
          </PersonPictureBackground>
          <PersonName data-alt={privateInsurance}>Paula Moreira</PersonName>
          <PersonJob data-alt={privateInsurance}>Front Office Assistant</PersonJob>
          <LinkedIn/>
        </Person>
        <div/>
      </People>


      <Quote privateInsurance={privateInsurance}/>

      <Center>
        <Subtitle style={{maxWidth:'none'}} data-alt={privateInsurance}>Parceiros</Subtitle>
        <Buttons>
          <Button data-alt={privateInsurance} data-selected={!toggle} onClick={toggle ? handleToggle : undefined}>
            Institucionais
          </Button>
          <Button data-alt={privateInsurance} data-selected={toggle} onClick={!toggle ? handleToggle : undefined}>
            Comerciais
          </Button>
        </Buttons>
        { !toggle ? <>
          <PartnersText data-alt={privateInsurance}>
            O Grupo Fidelidade foi o parceiro estratégico que elegemos para atuarmos no mercado segurador. Conseguimos assim garantir as melhores soluções em todos os ramos de seguros e permitir o acesso à maior e mais diversificada rede de distribuição de produtos de seguros do mercado nacional.
          </PartnersText>
          <PartnersLogo key='a' data-type="institutional">
            <img data-alt={privateInsurance} src={privateInsurance ? fidelidadeAlt : fidelidade}/>
            <img data-alt={privateInsurance} src={privateInsurance ? multicareAlt : multicare}/>
          </PartnersLogo>
        </>:<>
          <PartnersText data-alt={privateInsurance}>
            A Portugal Seguro procura, de forma equilibrada e sustentada, criar parcerias que lhe permitam solidificar e desenvolver as suas valências através da promoção de sinergias com parceiros nacionais e internacionais em vários domínios.
          </PartnersText>
          <PartnersLogo key='b' data-type="comercial">
            <img data-alt={privateInsurance} src={privateInsurance ? fixpacosAlt : fixpacos}/>
            <img data-alt={privateInsurance} src={privateInsurance ? gadgetHomeAlt : gadgetHome}/>
            <img data-alt={privateInsurance} src={privateInsurance ? gadgetFunAlt : gadgetFun}/>
            <img data-alt={privateInsurance} src={privateInsurance ? wabiAlt : wabi}/>
            {/* <img data-alt={privateInsurance} src={privateInsurance ? centaurValleyAlt : centaurValley}/> */}
          </PartnersLogo>
        </>}
      </Center>
    </Root>
  );
}
