import React,{useState,useCallback,useEffect,useRef} from 'react';
import styled,{keyframes} from 'styled-components';
import elementWithinViewport from 'element-within-viewport';

import serialize from 'form-serialize';

import px from 'px.js';

import Link from 'Link';

const Root = styled.div`

`;


const Headline = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;

const Bottomline = styled.div`
  font-size: ${px.vw(16)};
  margin-top:  ${24/16}em;
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    br{
      display: none;
    }
  }
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
`;

const Form = styled.form`
  margin-top:  ${px.vw(24)};
  @media (min-aspect-ratio: 18/10) {
    margin-top:  ${px.vh(24)};
  }
  ${px.portrait}{
    margin-top:  ${px.mvw(42)};
  }
  & [data-hide=true]{
    display: none;
  }
`;

const TextField = styled(
  p => <input type="text" {...p}/>
)`
  display: block;
  border: none;
  outline: none;
  background: transparent;

  &:placeholder{
    color: #98A1B2B3;
    &[data-alt=true]{
      color: #475266B3;
    }
  }

  color: #CEDAF2;
  border-bottom: 1px solid #CEDAF2;
  &:focus{
    border-bottom: 1px solid #00AEEA;
  }
  &[data-alt=true]{
    color: #171C26;
    border-bottom: 1px solid #171C26;
    &:focus{
      border-bottom: 1px solid #00AEEA;
    }
  }

  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }

  width:  ${436/20}em;
  height:  ${53/20}em;
  margin-top:  ${16/20}em;
  letter-spacing: -0.01em;
  padding-left: 0;

  ${px.portrait}{
    font-size: ${px.mvw(20)};
    width: 100%;
    height:  ${41/20}em;
    margin-top:  ${28/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
`;

const SelectInsuranceType = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
  margin-top: ${24/16}em;
`;

const Radio = styled(
  ({children,preselect,value,...p}) => <>
    <input defaultChecked={value===preselect} id={`getintouch-radio-${value}`} type="checkbox" value={value} {...p} />
    <label htmlFor={`getintouch-radio-${value}`}>{children}</label>
  </>
)`
  appearance: none;
  visibility: hidden;
  & + label{
    display: inline-block;
    vertical-align: top;

    font-size: ${px.vw(14)};
    @media (min-aspect-ratio: 18/10) {
      font-size: ${px.vh(14)};
    }
    ${px.portrait}{
      font-size: ${px.mvw(14)};
    }
    ${px.tablet}{
      font-size: ${px.tvw(16)};
    }

    font-weight: normal;
    line-height: ${45/14};

    cursor: pointer;

    color: #98A1B2;
    opacity: 0.7;
    border: ${1/14}em solid rgba(152, 161, 178, 0.5);
    &:hover{
      border: ${1/14}em solid rgba(152, 161, 178);
      opacity: 1;
    }

    height: ${45/14}em;
    margin-right: ${8/14}em;
    margin-bottom: ${8/14}em;
    padding: 0 ${24/14}em;
  }
  &[data-alt=true] + label{
    color: #475266;
  }

  &:checked + label{
    border: ${1.5/14}em solid #FFFFFF;
    color: #FFF;
    opacity: 1;
    font-weight: 600;
    letter-spacing: -0.012em;
  }
  &:checked[data-alt=true] + label{
    border: ${1.5/14}em solid #171C26;
    color: #171C26;
  }
`;

const RadioGroup = styled.div`
  margin-top: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    margin-top: ${px.vh(16)};
  }
  ${px.portrait}{
    margin-top: ${px.mvw(16)};
    br{
      display: none;
    }
  }
`;

const SendButton = styled(
  ({children,...p}) => <input type="submit" {...p} value={children} />
)`
  display: block;
  appearance: none;
  outline: none;
  border: none;

  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  font-weight: 600;
  line-height: ${61/16};
  text-align: center;
  letter-spacing: -0.00em;
  color: #171C26;
  &[data-alt=true]{
    color: #CEDAF2;
  }

  cursor: pointer;
  width: ${310/16}em;
  height: ${61/16}em;
  margin-top: ${40/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 100%;
  }

  background: #00AEEA;
  &:hover{
    background: #00BEFF;
  }
  &:ative{
    background: #00A5DE;
  }
`;

const Terms = styled.div`
  font-size: ${px.vw(10)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(10)};
  }
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
  opacity: 0.7;
  margin-top: ${8/10}em;
  max-width: ${300/10}em;
  ${px.portrait}{
    font-size: ${px.mvw(10)};
    max-width: none;
    width: 100%;
  }
`;

const timeout = keyframes`
  from{
    width: 0%;
  }
  to{
    width: 100%;
  }
`;
const Popup = styled(({sending,sent,error,onClose,...p}) => (

    <div data-animate={!sending} data-visible={sending||sent||error} {...p}>
      <div onAnimationEnd={onClose} data-error={error}/>
      <div>
        <h3>
          {
            sending ? 'A enviar mensagem.' :
            sent ? 'Obrigado!' :
            error ? 'Ups! O envio falhou.' : ''
          }
        </h3>
        <p>
          {
            sending ? 'Por favor, aguarde.' :
            sent ? 'Recebemos sua mensagem e entraremos em contato o mais breve possível.' :
            error ? 'Algo está errado. Tente novamente mais tarde.' : ''
          }
        </p>
        {!sending ? <button onClick={onClose}>Ok</button> : null}
      </div>
    </div>

))`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  background: #475266;
  width: ${491/16}em;
  height: ${200/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 100%;
  }

  transition-duration: 300ms;
  transition-property: opacity;
  opacity: 1;
  &[data-visible=false]{
    opacity: 0;
    pointer-events: none;
  }
  & div:first-child{
    height: ${10/16}em;
    width: 0%;
  }
  &[data-visible=true][data-animate=true]{
    & div:first-child{
      animation: 5s ${timeout} linear both;
      background: #00AEEA;
      &[data-error=true]{
        background: #FF4B4B;
      }
    }
  }
  & div:last-child{
    padding: ${40/16}em;
  }
  & h3{
    margin: 0;
    color: #CEDAF2;
    font-style: normal;
    font-weight: 600;
    font-size: ${20/16}em;
    line-height: 1.4;
  }
  & p{
    margin: 0;
    color: #CEDAF2;
    font-style: normal;
    font-weight: normal;
    font-size: ${14/16}em;
    margin-top: ${8/14}em;
    line-height: 1.5;
  }
  & button{
    display: block;
    width: max-content;
    margin: 0;
    padding: 0;
    appearance: none;
    outline: none;
    border: none;
    background: none;
    color: #00AEEA;
    font-style: normal;
    font-weight: bold;
    font-size: ${14/16}em;
    margin-top: ${20/14}em;
    line-height: 1;
    cursor: pointer;
  }

`;

const InlineLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
`;

export default function ContactForm({privateInsurance,hideInsuranceTypes=false,preselect=undefined,...p}){

	const [sending,setSending] = useState(false);
	const [sent,setSent] = useState(false);
	const [error,setError] = useState(false);

  const root = useRef();

  // useEffect(()=>{
  //   if(!root.current)
  //     return;
  //   elementWithinViewport(root.current, {
  //     onEnter: e => {
  //       document.documentElement.dataset.showcaptcha = true;
  //     },
  //     onExit: e => {
  //       document.documentElement.dataset.showcaptcha = false;
  //     }
  //   });
  // },[root]);

	const submit = useCallback((event)=>{
    event.preventDefault();

    const form = event.target.form;
    setSending(true);
    setError(false);
    const token = '';

    window.grecaptcha.ready(()=>{
      window.grecaptcha.execute(
        '6LeU3rMZAAAAAIDXju2XxOprKi9NcBgjezM08DZp',
        // '6LfWN7QZAAAAAFFHUEhNj7WeH60gmXLt3P_7_cRT',
        {action: 'submit'}
      ).then((token)=>{
    		try{
    			var request = new XMLHttpRequest();
    			request.open("POST", "https://us-central1-host-portugal-seguro.cloudfunctions.net/sendContact");
    			// request.open("POST", "http://localhost:5001/host-portugal-seguro/us-central1/sendContact");
    			request.setRequestHeader('Access-Control-Allow-Headers', '*');
    			request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    			request.onload = function(){
    				if(this.status !== 204){
    					setError(true);
    					setSending(false);
    				}else{
    					setSent(true);
    					setSending(false);
    				}
    			};
    			request.onerror = function(){
    				setError(true);
    				setSending(false);
    			}
          request.send(serialize(form)+`&recaptchatoken=${token}`);
    		}catch(error){
    			setError(true);
    			setSending(false);
    		}
      }).catch(e => {
        setError(true);
        setSending(false);
      });
    });
	},[setError,setSent,setSending]);

  const handlePopupClose = useCallback(()=>{
    setError(false);
    setSent(false);
    setSending(false);
  },[setError,setSent,setSending]);

  return (
    <Root ref={root} {...p}>
      <Popup
        sending={sending}
        sent={sent}
        error={error}
        onClose={handlePopupClose}
      />
      <Headline data-alt={privateInsurance}>
        Entramos em contacto
      </Headline>
      <Bottomline data-alt={privateInsurance}>
        Gostamos de conversar com pessoas, esclarecer dúvidas <br/>
        e compartilhar informações úteis.
      </Bottomline>
      <Form enctype="application/x-www-form-urlencoded">
        <TextField data-alt={privateInsurance} placeholder="Nome" name="name"/>
        <TextField data-alt={privateInsurance} placeholder="E-mail" name="email"/>
        <TextField data-alt={privateInsurance} placeholder="Telefone" name="phone"/>
        <div data-hide={hideInsuranceTypes}>
          <SelectInsuranceType data-alt={privateInsurance}>Selecione os tipos de seguro</SelectInsuranceType>
          <RadioGroup>{!privateInsurance ? <>
            <Radio preselect={preselect} name="insurancetype" value="acidentes-de-trabalho">
              Acidentes de trabalho
            </Radio>
            <Radio preselect={preselect} name="insurancetype" value="saude">
              Saúde
            </Radio>
            <Radio preselect={preselect} name="insurancetype" value="automovel">
              Automóvel
            </Radio>
            <Radio preselect={preselect} name="insurancetype" value="multirriscos">
              Multirriscos
            </Radio><br/>
            <Radio preselect={preselect} name="insurancetype" value="pequeno-negocio">
              Pequeno negócio
            </Radio>
            <Radio preselect={preselect} name="insurancetype" value="produtos-empresas">
              Produtos empresas
            </Radio>
            <Radio preselect={preselect} name="insurancetype" value="cyber-safety">
              Cyber safety
            </Radio>
          </>:<>
            <Radio preselect={preselect} data-alt={privateInsurance} name="insurancetype" value="auto">
              Auto
            </Radio>
            <Radio preselect={preselect} data-alt={privateInsurance} name="insurancetype" value="habitacao">
              Habitação
            </Radio>
            <Radio preselect={preselect} data-alt={privateInsurance} name="insurancetype" value="saude">
              Saúde
            </Radio>
            <Radio preselect={preselect} data-alt={privateInsurance} name="insurancetype" value="pets">
              Pets
            </Radio><br/>
            <Radio preselect={preselect} data-alt={privateInsurance} name="insurancetype" value="poupanca">
              Poupança
            </Radio>
            <Radio preselect={preselect} data-alt={privateInsurance} name="insurancetype" value="viagem-e-lazer">
              Trabalho e lazer
            </Radio>
            <Radio preselect={preselect} data-alt={privateInsurance} name="insurancetype" value="familia">
              Família
            </Radio>
          </>}</RadioGroup>
        </div>
        <SendButton onClick={submit} data-alt={privateInsurance}>
          Enviar
        </SendButton>
        <Terms data-alt={privateInsurance}>
          Ao clicar no botão, concordo que os meus dados sejam tratados de acordo com a <span/>
          <InlineLink to="/privacidade">Política de Privacidade</InlineLink>.
          <div style={{height: '0.9em'}}/>
          Este site é protegido por reCAPTCHA e aplicam-se a <span/>
          <InlineLink external={true} to="https://policies.google.com/privacy">Política de Privacidade</InlineLink> e os <span/>
          <InlineLink external={true} to="https://policies.google.com/terms">Termos de Serviço</InlineLink> da Google.
        </Terms>
      </Form>
    </Root>
  );
}
