import React,{useMemo,useEffect,useRef,useState} from 'react';
import styled from 'styled-components';

import px from 'px.js';

import Video from 'Video.js';
import Link from 'Link';

import videoLight from './assets/404 on light.webm';
import posterLight from './assets/404 on light.png';
import videoDark from './assets/404 on dark.webm';
import posterDark from './assets/404 on dark.png';

import {serviceList} from 'Services/services';

const Root = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  min-height: 100vh;

  ${px.portrait}{
    min-height: unset;
    font-size: ${px.mvw(20)};
    padding-left: ${16/20}em;
    padding-right: ${16/20}em;
    padding-top: ${64/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }

  padding-bottom: ${40/20}em;
  position: relative;
`;

const Title = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/7) {
    font-size: calc(${px.vh(40)}*${10/7});
  }
  max-width: ${362/40}em;
  ${px.portrait}{
    font-size: ${px.mvw(20)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;

const Description = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/7) {
    font-size: calc(${px.vh(20)}*${10/7});
  }
  max-width: ${362/20}em;

  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  margin-top: ${24/20}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
`;

const Animation = styled(Video)`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/7) {
    font-size: calc(${px.vh(16)}*${10/7});
  }
  position: absolute;
  width: ${960/16}em;
  bottom: ${-220/16}em;
  right: ${40/16}em;
  ${px.portrait}{
    position: unset;
    font-size: ${px.mvw(16)};
    width: 100%;
    margin-top: ${-32/16}em;
    margin-bottom: ${-32/16}em;
  }

`;

const Content = styled.div`
  position: absolute;
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/7) {
    font-size: calc(${px.vh(16)}*${10/7});
  }
  left: ${40/16}em;
  bottom: ${90/16}em;
  ${px.portrait}{
    position: unset;
    left: unset;
    bottom: unset;
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
`;

const Button = styled(Link)`
  display: inline-block;
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/7) {
    font-size: calc(${px.vh(14)}*${10/7});
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
    width: 100%;
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  height: ${61/14}em;
  line-height: ${61/14};
  padding: 0 ${61/14}em;

  margin-top: ${40/14}em;

  font-weight: 600;
  text-align: center;
  letter-spacing: -0.00em;
  text-decoration: none;
  color: #171C26;
  &[data-alt=true]{
    color: #CEDAF2;
  }
  background: #00AEEA;
  &:hover{
    background: #00BEFF;
  }
  &:ative{
    background: #00A5DE;
  }
`;

export default function E404({path,privateInsurance}){

  const hide = useMemo(()=>{
    return path.startsWith('/noticias') || [
      '/',
      '/sobre-nos',
      '/sinistros-e-assistencia',
      '/contactos',
      '/cookies',
      '/privacidade',
    ].includes(path) || serviceList.includes(path);
  },[path])


  return hide ? null : (
    <Root>
      <Animation
        enable={true}
        src={privateInsurance?videoLight:videoDark}
        poster={privateInsurance?posterLight:posterDark}
      />
      <Content>
        <Title data-alt={privateInsurance}>Ups! Algo está errado.</Title>
        <Description data-alt={privateInsurance}>Talvez o link já não esteja disponível ou possa haver um erro temporário.</Description>
        <Button data-alt={privateInsurance} to="/">Ir para a página inicial</Button>
      </Content>
    </Root>
  );
}
