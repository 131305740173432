import React,{useCallback} from 'react';

export default function Link({to,external,newtab=true,...p}){

  const handleClick = useCallback((event)=>{
    if(to){
      window.history.go(to);
      document.getElementById('approot').scrollTo(0,0);
    }
    event.preventDefault();
  },[to]);

  return !to ? <span {...p}/> : (
    external ? (
      <a
        href={to}
        rel="noopener noreferrer"
        {...(newtab ? {
          target: "_blank"
        }:{})}
        {...p}
      />
    ):(
      <a href={to} onClick={handleClick} {...p}/>
    )
  );
}
