import React from 'react';
import styled from 'styled-components';

import px from 'px.js';

import close from './assets/close.svg';
import closeAlt from './assets/close-alt.svg';

import ContactForm from 'ContactForm';

const Backdrop = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background: #171C26;
  &[data-alt=true]{
    background: #EDF2FA;
  }
`;
const Root = styled.div`
  position: fixed;
  z-index: 10;
  background: #171C26;
  &[data-alt=true]{
    background: #EDF2FA;
  }

  font-size: ${px.vw(20)};
  left: ${px.vw(386)};
  top: max((100vh - ${px.vw(820)})/2,${px.vh(32)});
  width: ${px.vw(668)};
  padding: ${45/20}em ${40/20}em;
  max-height: 100vh;
  overflow-y: hidden;

  ${px.portrait}{
    overflow-y: scroll;
    font-size: ${px.mvw(16)};
    padding: ${32/16}em ${16/16}em;
    top: ${16/16}em;
    left: ${16/16}em;
    width: calc(100vw - ${32/16}em);
    height: calc(100vh - ${32/16}em);
  }


`;

const Close = styled(
  ({alt,...p}) => <img alt="close" src={alt ? closeAlt : close} {...p}/>
)`
  position: absolute;
  font-size: ${px.vw(16)};

  width: 2em;
  right: 2em;
  top: 3em;

  cursor: pointer;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 1.5em;
    right: 1em;
    top: 2em;
  }
`;

export default function Popup({preselect,privateInsurance,onClose}){
  return <>
    <Backdrop data-alt={privateInsurance}/>
    <Root data-alt={privateInsurance}>
      <Close alt={privateInsurance} onClick={onClose}/>
      <ContactForm preselect={preselect} privateInsurance={privateInsurance}/>
    </Root>
  </>;
}
