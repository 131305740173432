import React,{useEffect,useRef,useState,useCallback,useMemo} from 'react';
import styled from 'styled-components';

import px from 'px.js';

import Link from 'Link';

const Popup = styled(({hide,...p}) => {

  const [consent,setConsent] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      setConsent(localStorage.getItem('cookie-consent') || false);
    },3000);
  },[setConsent]);

  const handleAccept = useCallback(()=>{
    localStorage.setItem('cookie-consent',true);
    setConsent(true);
  },[setConsent]);

  return (
    <div data-visible={!consent && !hide} {...p}>
      <p>
        Este website utiliza cookies para proporcionar uma melhor experiência aos seus utilizadores. Ao navegar no nosso website concorda com a utilização dos cookies nos termos definidos na nossa <Link to='/cookies'>Política de Cookies</Link>.
      </p>
      <button onClick={handleAccept}>Aceitar</button>
    </div>
  );
})`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  position: fixed;
  z-index: 20;
  left: 0;
  bottom: 0;
  background: #475266;
  width: ${491/16}em;
  padding: ${40/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 100%;
  }

  & a{
    color: #98A1B2;
    text-decoration: underline;
    &:hover{
      color: inherit;
    }
  }

  transition-duration: 300ms;
  transition-property: opacity;
  opacity: 1;
  &[data-visible=false]{
    opacity: 0;
    pointer-events: none;
  }
  & p{
    margin: 0;
    color: #CEDAF2;
    font-style: normal;
    font-weight: normal;
    font-size: ${14/16}em;
    margin-top: ${8/14}em;
    line-height: 1.5;
  }
  & button{
    display: block;
    width: max-content;
    margin: 0;
    padding: 0;
    appearance: none;
    outline: none;
    border: none;
    background: none;
    color: #00AEEA;
    font-style: normal;
    font-weight: bold;
    font-size: ${14/16}em;
    margin-top: ${20/14}em;
    line-height: 1;
    cursor: pointer;
  }
`;

export default Popup;
