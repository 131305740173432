import React,{useMemo} from 'react';
import styled from 'styled-components';

import px from 'px.js';
import RawLink from 'Link';

import E404 from 'E404';

import arrow from 'Home/News/assets/arrow.svg';

import news from './news.js';

var newsList = [];
for(const id in news)
  newsList.push({id:id,...news[id]});
newsList = newsList.sort((a,b) => parseInt(b.id) - parseInt(a.id));

const Root = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  padding-left: ${40/20}em;
  padding-right: ${40/20}em;
  padding-top: ${208/20}em;
  padding-bottom: ${80/20}em;

  ${px.portrait}{
    font-size: ${px.mvw(20)};
    padding-left: ${16/20}em;
    padding-right: ${16/20}em;
    padding-top: ${120/20}em;
    padding-bottom: ${80/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }

  /* min-height: 100vh; */
`;
const Title = styled.div`
  font-size: ${px.vw(68)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(68)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(40)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(40)};
  }
  font-weight: bold;
  line-height: .96;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const Subtitle = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(30)};
  }
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  font-weight: bold;
  line-height: .96;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const Description = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    br{
      display: none;
    }
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
    br{
      display: unset;
    }
  }
  margin-top: ${24/20}em;
  margin-bottom: ${66/20}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
`;
const Header = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  line-height: 1;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
  & > *{
    vertical-align: top;
  }
  & > span{
    &:first-child{
      font-weight: bold;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    &:last-child{
      float: right;
    }
  }
  padding-bottom: 1em;
  margin-bottom: ${40/12}em;
  border-bottom: 1px solid #47526680;
  &[data-alt=true]{
    border-bottom: 1px solid #98A1B280;
  }

  ${px.portrait}{
    font-size: ${px.mvw(12)};
    margin-bottom: ${24/12}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
  }
`;
const Grid = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }

  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${24/12}em;

  ${px.portrait}{
    font-size: ${px.mvw(12)};
    grid-template-columns: 1fr;
    grid-auto-flow: unset;
    grid-column-gap: ${0/12}em;
    grid-row-gap: ${60/12}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
  }

  & > *{
    vertical-align: top;
    position: relative;
  }
`;
const MainHeadline = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(40)};
    margin-bottom: -0.5em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(40)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const Headline = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  margin-bottom: ${16/20}em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const NewsWrapper = styled.div`
  padding-bottom: ${40/16}em;
`;
const NewsBody = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
`;
const NewsPicture = styled.img`
  width: 100%;
  margin-top: ${128/12}em;
  margin-bottom: ${20/12}em;

  ${px.portrait}{
    margin-top: ${80/12}em;
    margin-bottom: ${16/12}em;
  }
  ${px.landscape}{
    &[data-expanded=true]{
      width: calc(200% + ${24/12}em);
    }
  }
`;
const Spacer = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
  height: 1em;
`;
const Arrow = styled(
  p => <img alt="" src={arrow} {...p}/>
)`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  height: ${10/14}em;
  margin-left: ${10/14}em;
  transition-duration: 300ms;
`;
const Link = styled(
  p => <RawLink {...p}><span>Ler</span><Arrow/></RawLink>
)`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.00em;
  color: #00AEEA;
  cursor: pointer;

  position: absolute;
  left: 0;
  bottom: 0;

  text-decoration:  none;

  & > *{
    vertical-align: top;
  }
  & > ${Arrow}{
    position: relative;
    top: 0.15em;
  }
  &:hover > ${Arrow}{
    margin-left: 1.5em;
  }
`;

export default function News({path,privateInsurance}){

  const hide = useMemo(()=>{
    return !path || !path.startsWith('/noticias');
  },[path]);

  const newsId = useMemo(()=>(
    (path.match(/^\/noticias\/([0-9]+)/) || ['',null])[1]
  ),[path]);

  const paragraphs = useMemo(()=>{
    if(hide)
      return [];
    if(!newsId){
      return [];
    }
    if(newsId && !news[newsId]){
      return [];
    }
    const list = [];
    var i = 0;
    for(const title in news[newsId].body){
      list.push(<React.Fragment key={i++}>
        {title ? <Subtitle data-alt={privateInsurance}>{title}</Subtitle> : null}
        {news[newsId].body[title].map( (body,key) =>
          <p key={key}>{body}</p>
        )}
      </React.Fragment>);
    }
    return list;
  },[newsId,hide,path,privateInsurance]);

  const hightlightedArticle = useMemo(()=>{
    return newsId ? null : newsList[0];
  });

  const newsgrid = useMemo(()=>{
    const currentId = newsId || hightlightedArticle.id;
    const grid = [];
    for(const article of newsList){
      if(grid.length >= 3)
        break;
      if(article.id === currentId)
        continue;
      grid.push(article);
    }
    return grid;
  },[newsId,hightlightedArticle]);

  return hide ? null : (newsId && !news[newsId]) ? (
    <E404 path={'/error'+path} privateInsurance={privateInsurance}/>
  ) : (
    <Root>
      { newsId === null ? <>
        <Title data-alt={privateInsurance}>Últimas notícias</Title>
        <Description data-alt={privateInsurance}>Aceda às últimas notícias do universo Portugal Seguro,<br/> dos nossos parceiros e do mundo em geral.</Description>
        <Header data-alt={privateInsurance}>
          <span>{hightlightedArticle.category}</span>
          <span>{hightlightedArticle.date}</span>
        </Header>
        <Grid>
          <MainHeadline data-alt={privateInsurance}>
            {hightlightedArticle.title}
          </MainHeadline>
          <NewsWrapper>
            <NewsBody data-alt={privateInsurance}>
              {hightlightedArticle.summary}
              <br/><br/>
              {hightlightedArticle['summary-extension'] || null}
              <br/>
              <br/>
            </NewsBody>
            <Link to={`/noticias/${hightlightedArticle.id}`} />
          </NewsWrapper>
        </Grid>
      </> : <>
        <Header data-alt={privateInsurance}>
          <span>{news[newsId].category}</span>
          <span>{news[newsId].date}</span>
        </Header>
        <Grid>
          <div>
            <Title data-alt={privateInsurance}>
              {news[newsId].title}
            </Title>
            <NewsPicture data-expanded={news[newsId]['expand-picture']} src={require(`./assets/${newsId}.jpg`)} />
            <NewsBody data-alt={privateInsurance}>
              {news[newsId].pictureDescription}
            </NewsBody>
          </div>
          <NewsBody data-alt={privateInsurance}>
            {paragraphs}
          </NewsBody>
        </Grid>
      </> }
      <Spacer/><Spacer/><Spacer/><Spacer/><Spacer/>
      { newsId === null ? null : (
        <Subtitle data-alt={privateInsurance}>
          Pode gostar também
        </Subtitle>
      )}
      <Grid>
        {newsgrid.map((article,idx)=>(
          <div>
            <Header data-alt={privateInsurance}>
              <span>{article.category}</span>
              <span>{article.date}</span>
            </Header>
            <Headline data-alt={privateInsurance}>
              {article.title}
            </Headline>
            <NewsWrapper>
              <NewsBody data-alt={privateInsurance}>
                {article.summary}
              </NewsBody>
              <Link to={`/noticias/${article.id}`}/>
            </NewsWrapper>
          </div>
        ))}
      </Grid>
    </Root>
  );
}
