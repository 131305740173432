import React,{useCallback,useRef,useEffect,useState} from 'react';
import styled,{keyframes} from 'styled-components';

import elementWithinViewport from 'element-within-viewport';

import px from 'px.js';
import TopBar from 'TopBar';

import Link from 'Link';

import UnstyledVideo from 'Video';

import partners from './assets/partners.svg';
import partnersAlt from './assets/partners-alt.svg';

const animExpand = keyframes`
  from{
    clip-path: circle(${95/20}em at 91% 85%)
  }
  to{
    clip-path: circle(max(130vh,120vw) at 91% 85%)
  }
`;
const animFadeIn = keyframes`
  from{
    clip-path: circle(0% at 50% 50%);
    opacity: 0;
  }
  to{
    clip-path: circle(50% at 50% 50%);
    opacity: 1;
  }
`;
const animFadeOut = keyframes`
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
`;

const Root = styled.div`
  background: ${p => p['data-privateinsurance'] ? '#EDF2FA' : '#171C26'};
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  scroll-snap-align: start;

  position: ${p => p['data-expanded'] ? 'relative' : 'absolute'};
  z-index: ${p => p['data-expanded'] ? 0 : 1};
  top: 0;
  left: 0;
  width: 100vw;
  font-size: ${px.vw(20)};
  clip-path: ${p => p['data-expanded'] ? 'none' : `circle(${95/20}em at 91% 85%)`};
  &[data-expanded=false][data-expanding=true]{
    animation: ${animExpand} 2s both cubic-bezier(0.33, 1, 0.68, 1);
  }
  &[data-expanded=false][data-expanding=false]{
    cursor: pointer;
  }

  ${px.portrait}{
    &[data-expanded=false]{
      display: none;
    }
    font-size: ${px.mvw(8)};
    padding-right: ${px.mvw(16)};
    padding-left: ${px.mvw(16)};
  }

  ${px.tablet}{
    font-size: ${px.tvw(8)};
    padding-right: ${px.tvw(32)};
    padding-left: ${px.tvw(32)};
  }
`;

const Video = styled(UnstyledVideo)`
  position: absolute;
  width: 100vw;
  z-index: -1;
  bottom: -10vw;
  left: 0;
  ${px.portrait}{
    width: 300vw;
    left: -100vw;
  }
  ${px.tablet}{
    width: 200vw;
    left: -50vw;
    bottom: -20vw;
  }
`;

const Headline = styled.h1`
  margin: 0;
  padding: 0;
  margin-left: ${px.vw(0)};
  margin-top: calc(50vh - ${px.vw(363)}/2);
  font-size: ${px.vw(68)};
  font-weight: bold;
  line-height: 0.96;
  text-align: center;
  letter-spacing: -0.04em;
  color: ${p => p['data-privateinsurance'] ? '#171C26' : '#CEDAF2'};
  & strong{
    color: #00AEEA;
  }
  ${px.portrait}{
    text-align: left;
    margin-top: calc(${px.mvh(460)} - ${px.mvw(200)});
    font-size: ${px.mvw(40)};
    br{
      display: none;
    }
  }
  ${px.tablet}{
    margin-top: ${px.mvh(180)};
    font-size: ${px.mvw(25)};
    br{
      display: initial;
    }
  }
`;
const Bottomline = styled.div`
  font-size:  ${px.vw(20)};
  margin-top:  ${px.vw(24)};
  line-height: 1.4;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${p => p['data-privateinsurance'] ? '#475266' : '#98A1B2'};
  ${px.portrait}{
    font-size:  ${px.mvw(14)};
    margin-top:  ${px.mvw(24)};
    text-align: left;
    line-height: 1.5;
    br{
      display: none;
    }
  }
`;
const Button = styled(
  ({children,...p}) => <div {...p}><div>{children}</div></div>
)`
  & > div{
    display: inline-block;
    padding: 0 ${px.vw(34)};
    height: ${px.vw(61)};
    font-weight: 600;
    font-size: ${px.vw(14)};
    line-height: ${px.vw(61)};
    color: ${p => p['data-privateinsurance'] ? '#CEDAF2': '#171C26' };
    text-align: center;

    ${px.portrait}{
      min-width: ${px.mvw(310)};
      padding: 0 ${px.mvw(20)};
      font-size: ${px.mvw(14)};
      height: ${px.mvw(61)};
      line-height: ${px.mvw(61)};
    }

    background: #00AEEA;
    cursor: pointer;
    &:hover{
      background: #00BEFF;
    }
    &:ative{
      background: #00A5DE;
    }
  }
  text-align: center;
  margin-top:  ${px.vw(40)};
  margin-left: auto;
  margin-right: auto;
  ${px.portrait}{
    text-align: left;
    margin-top:  ${px.mvw(40)};
  }
`;

const Partners = styled(
  ({alt,...p}) => <img alt="" src={alt ? partnersAlt : partners} {...p} />
)`
  position: absolute;
  left: ${px.vw(40)};
  bottom: ${px.vw(31)};
  width: ${px.vw(340)};
  height: ${px.vw(26)};

  ${px.portrait}{
    left: ${px.mvw(16)};
    bottom: ${px.mvw(65)};
    width: ${px.mvw(340)};
    height: ${px.mvw(26)};
  }
  ${px.tablet}{
    left: ${px.tvw(16)};
    bottom: ${px.tvw(65)};
    width: ${px.tvw(340)};
    height: ${px.tvw(26)};
  }
`;

const SwitchButton = styled.div`
  position: absolute;
  user-select: none;
  font-size: inherit;
  /* font-size: ${px.vw(20)}; */
  ${px.portrait}{
    /* font-size: ${px.mvw(8)}; */
  }
  width: ${190/20}em;
  height: ${190/20}em;
  left: calc(91% -  ${190/40}em);
  top: calc(85% -  ${190/40}em);
  font-weight: bold;
  line-height: 1.16;
  padding-top: calc(${(190/2/20)}em - 1.16em);
  text-align: center;
  letter-spacing: -0.00em;
  clip-path: circle(50% at 50% 50%);
  color: ${p => p['data-privateinsurance'] ? '#171C26' : '#CEDAF2'};
  background: ${p => p['data-privateinsurance'] ? '#EDF2FA' : '#171C26'};

  &[data-expanding=true]{
    animation: ${animFadeOut} 0.5s both;
  }

  &[data-fake=true][data-delaying=false]{
    animation: ${animFadeIn} 0.5s both 0.3s;
    &[data-expanding=true]{
      pointer-events: none;
    }
  }

`;

const en = false;

export default function Header({animate=false,delaying,privateinsurance=false,onExpanded=(()=>{}),expanded=true,expanding=false,expand=(()=>{})}){

  const root = useRef();
  const [animationVisible,setAnimationVisible] = useState(true);

  const handleExpanded = useCallback((ev)=>{
    if(ev.target !== root.current)
      return;
    if(expanding)
      onExpanded();
  },[root,onExpanded,expanding]);

  useEffect(()=>{
    if(!root.current)
      return;
    // elementWithinViewport(root.current, {
    //   onEnter: e => { setAnimationVisible(true) },
    //   onExit: e => { setAnimationVisible(false) }
    // });
  },[root,setAnimationVisible]);

  const [animateDelayed,setAnimateDelayed] = useState(animate);
  useEffect(()=>{
    if(animate)
      setAnimateDelayed(true);
    else
      setTimeout(()=>setAnimateDelayed(animate),500);
  },[animate]);

  return (
    <Root
      ref={root}
      onAnimationEnd={handleExpanded}
      data-expanded={expanded}
      data-expanding={expanding}
      data-privateinsurance={privateinsurance}
    >
      <Video
        enable={(expanded || expanding) && animationVisible && animateDelayed}
        src={require(`./assets/header on ${privateinsurance?'light':'dark'}.webm`)}
        src2={require(`./assets/header on ${privateinsurance?'light':'dark'}.mp4`)}
        poster={require(`./assets/header on ${privateinsurance?'light':'dark'}.png`)}
      />
      <TopBar delaying={delaying} alt={privateinsurance} hide={!expanding}/>
      <Headline data-privateinsurance={privateinsurance}>
      {
        privateinsurance ? (
          en ?
            <span>We secure <strong>your</strong> future</span>
          : <span>Asseguramos o <strong>seu</strong> futuro</span>
        ):(
          en ?
            <span>We secure the future<br/>of your <strong>business</strong></span>
          : <span>Asseguramos o futuro<br/> do seu <strong>negócio</strong></span>
        )
      }
      </Headline>
      <Bottomline data-privateinsurance={privateinsurance}>
      {
        privateinsurance ? (
          en ?
            <span>Choose the insurance that best suits your health<br/> protection needs and have access to the largest private<br/> medical network, at fair prices</span>
          : <span>
                  Escolha o seguro que mais se adequa às suas necessidades de <br/>
                  proteção na saúde e tenha acesso à maior rede médica <br/>
                  privada, a preços vantajosos. <br/>
            </span>
        ):(
          en ?
            <span>Protect your employees, your activity, the safety<br/> of goods and customer satisfaction</span>
          : <span>
          Proteja as instalações, o conteúdo, os colaboradores e <br/>
          os seus clientes. Proteja o sucesso da sua empresa!
            </span>
        )
      }</Bottomline>
      <Link to={privateinsurance?'/particular/saude':'/empresas/multirriscos'}><Button data-privateinsurance={privateinsurance}>
        {
          privateinsurance ? (
            en ?
              <span>Explore health insurances</span>
            : <span>Seguros de saúde</span>
          ):(
            en ?
            <span>Explore small business insurances</span>
            : <span>Seguros Multirriscos</span>
          )
        }
      </Button></Link>
      <Partners alt={privateinsurance}/>
      { !expanded ?
        <SwitchButton onClick={expand} data-privateinsurance={privateinsurance} data-expanding={expanding}>
          {privateinsurance ? <>Seguros para<br/> particulares</> : <>Seguros para<br/> empresas</> }
        </SwitchButton> : null
      }
      { (!expanded && expanding) || delaying ?
        <SwitchButton data-privateinsurance={!privateinsurance} data-delaying={delaying} data-fake={true} data-expanding={expanding}>
          {!privateinsurance ? <>Seguros para<br/> particulares</> : <>Seguros para<br/> empresas</> }
        </SwitchButton> : null
      }
    </Root>
  );
}
