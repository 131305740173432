export default {
  privateInsurance: true,
  title: 'Família',
  description: 'Proteja o que é mais importante para si – a sua família​​​​​​​​​​​​',
  buttons: [
    'Seguro Acidentes Pessoais',
    'Responsabilidade Civil Familiar',
    'Proteção Vital da Família',
  ],
  pages: [
    {
      subtitle: 'Acidentes Pessoais',
      body: [
        'P​orque para nós o mais importante são as pessoas, temos uma oferta de Acidentes Pessoais que lhe oferece uma proteção eficaz e abrangente em caso de acidente. Desde planos​​​​​​​​​​​​​​ simples e acessíveis para a proteção e o apoio financeiro a si e à sua família em caso de acidente, até planos com grande amplitude de proteção, com capitais muito elevados e serviços de apoio ímpares. Para que a vida não pare.​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Acidentes Pessoais 1',
          body: ['O plano Acidentes Pessoais mais simples. Duas coberturas e capitais essenciais.​​'],
          picture: 7,
        },
        {
          title: 'Acidentes Pessoais 2',
          body: ['O plano que complementa a proteção para quando os seguros obrigatórios não cobrem. Capitais elevados e serviços de apoio ímpares.​​'],
          picture: 7,
        },
        {
          title: 'Acidentes Pessoais 3',
          body: ['O plano que vai além dos tempos livres, protege até em trabalho ou em deslocações de carro. Os capitais mais elevados e o mesmo serviço de apoio Fidelidade.​​'],
          picture: 7,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Adequado a situações graves',
          body: ['Os planos AP2e AP3 garantem um capital elevado pensado para acidentes com consequências graves. Disponibiliza um conjunto de coberturas que vão muito além do simples pagamento de uma indemnização ou reembolso de despesas.​​​'],
        },
        {
          title: 'Consigo desde o 1º momento',
          body: ['Em caso de acidente, os planos AP2 e AP3 disponibilizam o apoio e a orientação necessários desde o primeiro momento, assegurando o encaminhamento para a rede de prestadores da Fidelidade, para que não tenha que antecipar o pagamento das despesas médicas.​​​'],
        },
        {
          title: 'Compromisso com o futuro',
          body: ['Porque o pagamento de uma indemnização pode não ser suficiente em caso de um acidente grave, os planos AP2 e AP3 têm associado o Compromisso WeCare ​- um projeto que visa apoiar a reabilitação física e a reintegração social, profissional e familiar. Para que a vida nunca pare.​​'],
        },
      ],
    },
    {
      subtitle: 'Responsabilidade Civil Familiar',
      body: [
        'Sabia que, a um custo muito reduzido, pode precaver-se dos prejuízos causados aos outros por si, pelos seus filhos, pelos seus empregados domésticos ou até mesmo pelos seus animais de companhia? ​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Responsabilidade Civil Familiar',
          body: ['Responsabilidade Civil Familiar é um seguro que garante a responsabilidade civil pelos danos causados a terceiros por todos os membros do seu agregado familiar, empregados domésticos ao seu serviço e animais de companhia (desde que não sejam legalmente qualificados como perigosos ou potencialmente perigosos).'],
          picture: 7,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Simples mas completo',
          body: ['Produto de fácil subscrição, que cobre os danos causados não só pela Pessoa Segura como pelos membros do seu agregado familiar, empregados domésticos e animais de companhia.'],
        },
        {
          title: 'Flexível e adequado',
          body: [
            'Possibilidade de contratação de capitais elevados a um preço acessível.',
            'Possibilidade de alargar o âmbito do seguro a outras situações da vida privada, tornando-o assim mais abrangente.',
          ],
        },
        {
          title: 'Cobertura de responsabilidade civil dos portadores de armas',
          body: ['Se possuir licença de uso e porte de arma pode ficar protegido incluindo no seguro de Responsabilidade Civil Familiar a cobertura respetiva.​'],
        },
      ],
    },
    {
      subtitle: 'Proteção Vital da Família',
      body: [
        'O Proteção Vital da Família é um seguro inovador inteiramente concebido para que a vida da sua família não pare.​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Proteção Vital da Família',
          body: ['Com um leque abrangente de serviços de assistência, este seguro evolui com a sua família, respondendo às necessidades de proteção de cada pessoa, nas diferentes etapas da vida.'],
          picture: 7,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Proteção integrada para a família',
          body: ['Coberturas que se dirigem a toda a família, consoante a idade de cada pessoa e a fase de vida em que se encontra.'],
        },
        {
          title: 'Dos mais novos aos mais idosos',
          body: [
            'Aconselhamento pediátrico para os mais novos, e personalizado para os seniores.',
            'Terapia da fala para crianças até aos 16 anos através da rede de prestadores Multicare.',
          ],
        },
        {
          title: 'Organização e pagamento do funeral',
          body: ['Com um telefonema, a sua família ou os amigos podem contar com profissionais para tratar de todo o funeral. Os custos estão assegurados.'],
        },
      ],
    },
  ]
};
