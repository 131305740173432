const vantagens = {
  title2: 'Vantagens',
  boxes2:[
    {
      title: 'Check-Up gratuito',
      body: ['Levar o seu amigo de quatro patas ao veterinário apenas quando está doente, não é a melhor solução. Lembre-se que a medicina preventiva é fundamental para tratar atempadamente muitas doenças.​'],
    },
    {
      title: 'Capital para cirurgias',
      body: ['Capital específico para cirurgias, incluindo doença, acidente e esterilização. Esta cobertura garante o pagamento de despesas efetuadas, com os atos de diagnóstico ou terapêutica.'],
    },
    {
      title: 'Um seguro para a vida',
      body: ['Sem limite de permanência, se subscrito até aos 8 anos. Quanto mais cedo fizer o seguro para o seu animal, mais ele irá beneficiar do seguro até ao fim da sua vida.​'],
    },
    {
      title: 'Reembolso das despesas',
      body: ['Pode solicitar o reembolso das despesas médicas relativas a consultas, exames, cirurgias e vacinas.'],
    },
  ],
}

export default {
  privateInsurance: true,
  title: 'Pets',
  description: 'Os melhores amigos olham um pelo outro',
  buttons: [
    'Pets 1 | 2 | 3',
    'Seguro Pet Vital',
  ],
  pages: [
    {
      subtitle: 'Pets 1 | 2 | 3',
      body: [
        'Se a companhia do seu amigo é indispensável, indispensável é também o seguro Pets que lhe apresenta três soluções de proteção distintas. Desde a base que garante os danos causados pelo animal e serviços de assistência, a outras soluções que incluem medicina preventiva e reembolso de despesas médicas.',
      ],
      boxes:[
        {
          title: 'Pet 1',
          body: ['Um seguro simples para quem apenas se quer proteger contra possíveis danos causados pelos seus animais de companhia.'],
          picture: 6,
        },
        {
          title: 'Pet 2',
          body: ['​O seguro ideal para quem se preocupa com as despesas de saúde de rotina dos seus amigos de quatro patas e não abdica de proteção jurídica.​'],
          picture: 6,
        },
        {
          title: 'Pet 3',
          body: ['Para quem deseja proteger os seus animais de companhia em caso de doenças graves e acidentes.'],
          picture: 6,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Seguro Pet Vital',
      body: [
        'Se acredita que o seu animal de companhia merece a melhor proteção de saúde, prevenção e bem-estar, o Pet Vital é a escolha perfeita.',
      ],
      boxes:[
        {
          title: 'Seguro Pet Vital',
          body: ['A escolha ideal de quem procura uma proteção mais completa para o seu melhor amigo. O Pet Vital garante-lhe o descanso que precisa, sabendo que todos os cuidados são assegurados.'],
          picture: 6,
        },
      ],
      ...vantagens
    },
  ]
};
