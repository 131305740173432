import React,{useState,useCallback} from 'react';
import styled from 'styled-components';

import px from 'px.js';

import doubleQuote from './assets/doublequote.svg';
import arrow from './assets/arrow.svg';
import arrowAlt from './assets/arrow-alt.svg';
import doubleQuoteAlt from './assets/doublequote-alt.svg';
import avatar from './assets/avatar.png';

const Root = styled.div`
  ${px.portrait}{
    display: none;
  }
`;

const Text = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(40)}; */
  }
  width: 100%;
  margin-top: ${40/40}em;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  letter-spacing: -0.02em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
    text-align: left;
    margin-left: 0;
    margin-top: ${24/30}em;
    max-width: ${px.mvw(375 - 16*2)};
    br{
      display: none;
    }
  }
  ${px.tablet}{
    font-size: ${px.tvw(30)};
    max-width: ${px.tvw(375)};
  }
`;
const Person = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(40)}; */
  }
  margin-top: ${86/40}em;
  ${px.portrait}{
    font-size: ${px.mvw(40)};
    margin-top: ${48/40}em;
  }
`;
const PersonAvatar = styled.img`
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(20)}; */
  }
  width:  ${90/20}em;
  height:  ${90/20}em;
  ${px.portrait}{
    width:  ${px.mvw(60)};
    height:  ${px.mvw(60)};
    margin-left: 0;
  }
`;
const PersonName = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(20)}; */
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    text-align: left;
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  letter-spacing: -0.01em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
  margin-top: ${16/20}em;/
`;
const PersonDescription = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(16)}; */
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    text-align: left;
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.00em;
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
  margin-top: ${4/16}em;
`;



const Arrow = styled(({alt,flip,...p}) => <div {...p}/>)`
  position: absolute;
  width:  ${px.vw(80)};
  height:  ${px.vw(18)};
  top:  ${px.vw(396)};
  left:  ${p => px.vw(p.flip ? 'none' : 40)};
  right:  ${p => px.vw(p.flip ? 40 : 'none')};
  @media (min-aspect-ratio: 18/10) {
    width:  ${px.vh(80)};
    height:  ${px.vh(18)};
    top:  ${px.vh(396)};
    left:  ${p => px.vh(p.flip ? 'none' : 40)};
    right:  ${p => px.vh(p.flip ? 40 : 'none')};
  }
  ${px.portrait}{
    width:  ${px.mvw(80)};
    height:  ${px.mvw(18)};
    top: auto;
    bottom:  ${px.mvw(0)};
    left:  ${p => px.mvw(p.flip ? (80+32) : 16)};
  }

  background-image: url(${p => p.alt ? arrowAlt : arrow});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;

  opacity: 0.4;
  &:hover{
    opacity: 1;
  }
  transition-duration: 300ms;
  transition-property: opacity;

  transform: scaleX(${p => p.flip ? -1 : 1});

  cursor: pointer;
`;
const DoubleQuote = styled(
  ({alt,...p}) => <img alt="" src={alt?doubleQuoteAlt:doubleQuote} {...p}/>
)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width:  ${px.vw(40)};
  height:  ${px.vw(32)};
  @media (min-aspect-ratio: 18/10) {
    width:  ${px.vh(40)};
    height:  ${px.vh(32)};
  }
  ${px.portrait}{
    width:  ${px.mvw(40)};
    height:  ${px.mvw(32)};
  }
  ${px.tablet}{
    margin-left: ${px.tvw(375/2 - 40)};
  }
`;

const Grid = styled(({page,...p}) => <div {...p}/>)`
  width: ${px.vw(1440*3)};
  height: ${px.vh(810)};
  display: grid;
  grid-template-columns: ${px.vw(1440)} ${px.vw(1440)} ${px.vw(1440)};
  transition-duration: 500ms;
  transition-property: transform;
  transform: translateX(-${p => p.page*100}vw);

  ${px.portrait}{
    width: ${100*3}vw;
    height: 100%;
  }
`;
const Wrapper = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    /* font-size: ${px.vh(40)}; */
  }
  display: block;
  width: ${1280/40}em;
  margin-left: ${40/40}em;
  position: relative;

  ${px.portrait}{
    width: 100vw;
  }
`;

const en = false;

const pages = 3;

export default function Quote({privateInsurance}){

  const [page,setPage] = useState(0);
  const next = useCallback(()=>{
    setPage(page => (page-1+pages)%pages);
  },[setPage]);
  const previous = useCallback(()=>{
    setPage(page => (page+1+pages)%pages);
  },[setPage]);

  return (
    <Root data-alt={privateInsurance}>
        <Wrapper>
          <DoubleQuote alt={privateInsurance}/>
          <Text data-alt={privateInsurance}>
              Bem-vindo(a).<br/>
              Aproveito esta oportunidade para partilhar consigo um dos principais objetivos da Portugal Seguro: construir relações positivas que perdurem no tempo, de estar presente e disponível, onde e quando precisar de nós.
              <br/><br/>
              Esperamos ter a oportunidade de apresentar soluções e serviços adaptados às suas necessidades, de poder trabalhar consigo e realizar com êxito, os projetos necessários para o bom desenvolvimento do seu negócio.
          </Text><Person>
            <PersonAvatar src={avatar}/>
            <PersonName data-alt={privateInsurance}>Rómulo Costa</PersonName>
            <PersonDescription data-alt={privateInsurance}>CEO</PersonDescription>
          </Person>
        </Wrapper>
    </Root>
  );
}
