import React from 'react';
import Link from 'Link';

export default <>
  <h1>Política de Privacidade</h1>
  <h2>PROPRIEDADE e responsabilidade</h2>
  www.portugalseguro.pt é propriedade de: <br/>
  <strong>Portugal Seguro - Mediação de Seguros, Lda</strong><br/>
  Rua Vista Alegre, Nº 125, R/C Esq. <br/>
  4590-221 Figueiró<br/>
  Portugal<br/>
  <br/>
  NIF	<strong>PT513767207</strong><br/>
  Diretor Executivo: <strong>Rómulo Costa</strong><br/>
  <br/>
  E-mail de contato do proprietário: <strong>geral@portugalseguro.pt</strong><br/>
  <br/>
  Última revisão da Política de Privacidade: <strong>17 de Julho de 2020</strong><br/>
  <br/>
  <br/>
  <br/>
  <h2>A posição da Portugal seguro em relação ao tratamento dos seus dados pessoais</h2>
  <br/>
  Para a Portugal Seguro - Mediação de Seguros, Lda (adiante, "Portugal Seguro”), a privacidade e a proteção dos dados pessoais dos seus clientes e dos demais titulares dos dados são fundamentais. Por isso, a Portugal Seguro está determinada em cumprir toda a legislação aplicável em matéria de proteção de dados pessoais, respeitando os princípios fundamentais e os direitos dos titulares dos dados.<br/>
  <br/>
  A presente Política de Privacidade complementa outras disposições contratuais e informações que possam ser prestadas pela Portugal Seguro aos seus clientes, bem como as demais políticas e normativos que sejam criados para a proteção de dados.<br/>
  <br/>
  A Portugal Seguro aconselha a leitura desta Política e de outros documentos que lhe possam ser transmitidos ou comunicados e que versem sobre a privacidade e a proteção dos seus dados pessoais.<br/>
  <br/>
  O responsável pelo tratamento será a empresa da Portugal Seguro que lhe presta o serviço e oferece produtos que nesse âmbito decide quais os dados recolhidos, meios de tratamento e finalidades para que os dados são usados.<br/>
  <br/>
  Em certos casos, a Portugal Seguro atuará como subcontratante, procedendo ao tratamento dos seus dados por conta de outra entidade, que actuará como responsável pelo tratamento, o que sucederá designadamente com as companhias de seguros, quando a Portugal Seguro lhes preste serviços no âmbito da gestão e execução de um contrato de seguro em que o titular dos dados seja parte (por exemplo, para efeitos de gestão de sinistros).<br/>
  <br/>
  Nesses casos, recomendamos a consulta da política de privacidade e/ou demais informações sobre o tratamento dos seus dados junto dos responsáveis pelo tratamento.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Conceitos essenciais</h2>
  <br/>
  <strong>O que são dados pessoais?</strong><br/>
  <br/>
  Dados pessoais são qualquer informação, seja de que natureza for ou em que suporte estiver, relativa a uma pessoa singular identificada ou identificável. É considerada identificável qualquer pessoa que possa ser identificada, direta ou indiretamente, em especial por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrónica ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular.<br/>
  <br/>
  <strong>Quem são os titulares dos dados pessoais tratados pela Portugal Seguro?</strong><br/>
  <br/>
  Os titulares dos dados são as pessoas singulares a quem os dados pessoais digam respeito.<br/>
  Por exemplo, enquanto responsável pelo tratamento, a Portugal Seguro poderá tratar dados pessoais cujos titulares são os seus clientes (pessoas singulares), que contratam os serviços e produtos da Portugal Seguro, os seus antigos clientes e os seus potenciais clientes. Enquanto subcontratante, a Portugal Seguro poderá tratar dados pessoais cujos titulares são tomadores de seguros, os beneficiários ou pessoas seguras, de acordo com determinado contrato de seguro, ou as pessoas indicadas como testemunhas, na ocorrência de sinistros.<br/>
  <br/>
  <strong>Quais são os dados pessoais tratados pela Portugal Seguro?</strong><br/>
  <br/>
  A Portugal Seguro apenas recolhe e trata os dados pessoais necessários para lhe prestar um serviço de qualidade, com vista a poder oferecer-lhe os produtos e serviços que melhor se adequem às suas necessidades e a executar com excelência os serviços que tenha contratado, e para poder cumprir com as suas obrigações enquanto subcontratante.<br/>
  A Portugal Seguro também tratará os dados pessoais que sejam necessários para o cumprimento de obrigações legais a que esteja sujeita ou para satisfação dos seus próprios interesses legítimos.<br/>
  <br/>
  <strong>No âmbito da prestação de serviços, a Portugal Seguro procede ao tratamento das seguintes categorias de dados pessoais:</strong><br/>
  <br/>
  <span data-bullet={true}>•</span> Dados de identificação, relativos ao tomador, pessoas seguras, lesados e beneficiários, consoante aplicável (g., nome, morada, naturalidade, nacionalidade, dupla nacionalidade, cartão de cidadão, sexo, data de nascimento, contacto telefónico, endereço de correio electrónico, número de contribuinte, estado civil, profissão);<br/>
  <span data-bullet={true}>•</span> Dados de registo de sinistros no ramo vida (g., assento de óbito, certificado de óbito, habilitação de herdeiros, relatório médico, recibo da agência funerária, auto de ocorrência de acidente, relatório de autópsia e resultados de exames de alcoolémia e toxicologia, ordem de pagamento a ser preenchida pelo beneficiário, comprovativo de IBAN);<br/>
  <span data-bullet={true}>•</span> Dados de registo de sinistros no ramo saúde (g., histórico de saúde do segurado, relatórios médicos, documentos de suporte à resolução de sinistro);<br/>
  <span data-bullet={true}>•</span> Dados de registo de sinistros no ramo acidentes de trabalho (g., data de ativação do seguro e descrição do sinistro, vencimento, prémios, extras, gratificações, subsídio de alimentação, dados de suporte para a resolução dos sinistro);<br/>
  <span data-bullet={true}>•</span> Dados de registo de sinistros no ramo acidentes pessoais (g., descrição do sinistro, informação médica, documentação de suporte ao sinistro, beneficiários legais);<br/>
  <span data-bullet={true}>•</span> Dados de registo de sinistros no ramo automóvel (g., dados de participação de sinistros Auto, identificação de terceiros lesados, identificação de testemunhas);<br/>
  <span data-bullet={true}>•</span> Dados de registo de sinistros noutros ramos (g., dados de participação de sinistros);<br/>
  <span data-bullet={true}>•</span> Dados de identificação do objeto seguro (g., tipo de veículo, tipo de aeronave, tipo de embarcação, matrícula, marcada, modelo, ano de fabrico, número de chassis, data da matrícula, cilindrada, número de lugares, potência, número de apólice, identificação de outros objetos seguros, como jóias, peças de arte, habitação, recheio da habitação ou animais);<br/>
  <span data-bullet={true}>•</span> Dados de identificação do objeto não seguro (e.g., número de apólices de seguros, matrículas de veículos);<br/>
  <span data-bullet={true}>•</span> Dados de identificação do património pessoal (e.g., jóias, quadros, peças de arte, antiguidades, imóveis, recheio de habitações, veículos e outros objetos pessoais, incluindo fotografias dos mesmos);<br/>
  <span data-bullet={true}>•</span> Dados de cobrança (g., NIB/IBAN, banco, swift, assinatura, nome titular da conta, morada, número de apólice);<br/>
  <span data-bullet={true}>•</span> Dados de saúde e hábitos de vida (g., informação relativa a hábitos de vida, como alimentação, desporto, consumo de álcool, tabagismo, índices biométricos, histórico de doenças).<br/>
  <br/>
  Portugal Seguro trata as categorias de dados pessoais acima elencadas também nos casos em que os titulares dos dados sejam menores de idade.<br/>
  <br/>
  A Portugal Seguro apenas trata as categorias especiais de dados acima referidas, isto é, dados relativos à sua saúde, dados biométricos ou dados genéticos, enquanto subcontratante.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Razões pelas quais tratamos os seus dados e em que situações</h2>
  <br/>
  Enquanto responsável pelo tratamento, a Portugal Seguro apenas trata dados pessoais nas seguintes situações:<br/>
  <br/>
  <strong>Para execução de um contrato celebrado consigo ou para a realização de diligências pré-contratuais a seu pedido</strong><br/>
  <br/>
  No âmbito da prestação de serviços e oferta de produtos que o cliente pretenda contratar, a Portugal Seguro pode precisar de tratar os seus dados pessoais. Tal sucederá, por exemplo, nos seguintes casos:<br/>
  <span data-bullet={true}>•</span> Registo e prova de transações comerciais e informações pré-contratuais;<br/>
  <span data-bullet={true}>•</span> Respostas a pedidos de informação realizados por clientes ou potenciais clientes;<br/>
  <span data-bullet={true}>•</span> Pedidos de simulação para apresentação de propostas de seguro;<br/>
  <span data-bullet={true}>•</span> Acompanhamento da gestão e execução do contrato, o que inclui, entre outros, a apresentação de propostas de seguros, de acordo com os interesses do cliente.<br/>
  <br/>
  <strong>Para o cumprimento de obrigações legais a que a Portugal Seguro está sujeita</strong><br/>
  <br/>
  No exercício da sua atividade, a Portugal Seguro está sujeita a determinadas obrigações legais e regulamentares, cujo cumprimento pode implicar a necessidade de proceder ao tratamento dos seus dados pessoais, tais como:<br/>
  <span data-bullet={true}>•</span> Cumprimento de obrigações de retenção, pagamento ou declaração para efeitos fiscais;<br/>
  <span data-bullet={true}>•</span> Cumprimento de obrigações legais decorrentes de pedidos de autoridades públicas (eg., Autoridade de Supervisão de Seguros e Fundos de Pensões e Tribunais Judiciais);<br/>
  <span data-bullet={true}>•</span> Cumprimento de procedimentos em matéria de prevenção e combate ao branqueamento de capitais e financiamento do terrorismo.<br/>
  <br/>
  <strong>Para satisfação de interesses da Portugal Seguro</strong><br/>
  <br/>
  A Portugal Seguro utiliza os seus dados pessoais para desenvolver, melhorar e promover os seus serviços e para defender os seus direitos e interesses legais, incluindo:<br/>
  <br/>
  Melhoria da qualidade do serviço, o que inclui:<br/>
  <span data-bullet={true}>•</span> a realização de estudos de mercado;<br/>
  <span data-bullet={true}>•</span> a análise do atendimento prestado telefonicamente;<br/>
  <br/>
  Marketing e comunicação, o que inclui:<br/>
  <span data-bullet={true}>•</span> o envio de comunicações a clientes e a ex-clientes sobre produtos e serviços da Portugal Seguro;<br/>
  <span data-bullet={true}>•</span> análise e gestão dos pedidos feitos no site e outros canais.<br/>
  <br/>
  Gestão de reclamações e acompanhamento de processos judiciais, o que inclui:<br/>
  <span data-bullet={true}>•</span> a análise e acompanhamento de reclamações apresentadas pelos clientes quanto aos serviços da Portugal Seguro;<br/>
  <span data-bullet={true}>•</span> a análise e acompanhamento de processos litigiosos ou pré-litigiosos em que a Portugal Seguro seja parte.<br/>
  <br/>
  <strong>Para ir de encontro às suas escolhas</strong><br/>
  <br/>
  A Portugal Seguro trata ainda os seus dados pessoais quando tenha dado o seu consentimento prévio e expresso para esse efeito e quando esse consentimento respeite todos os requisitos legais. Tal sucederá nos seguintes casos:<br/>
  <br/>
  <span data-bullet={true}>•</span> Prospeção comercial<br/>
  <span data-bullet={true}>•</span> Melhoria da Qualidade de Serviço.<br/>
  <br/>
  <strong>Tratamento de dados enquanto subcontratante</strong><br/>
  <br/>
  Quando a Portugal Seguro atua como subcontratante, ou seja, por conta de outras entidades, em particular companhias de seguros, as finalidades do tratamento dos dados pessoais serão determinadas por essas entidades, enquanto responsáveis pelo tratamento. Nesses casos, a Portugal Seguro tratará os seus dados pessoais apenas para essas finalidades e de acordo com as instruções que lhe forem transmitidas por esses responsáveis pelo tratamento.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Transmissão de dados pessoais e possíveis destinatários dos seus dados pessoais</h2>
  <br/>
  Para que a Portugal Seguro consiga cumprir todos os seus deveres e lhe possa prestar o melhor serviço possível, poderá ter que comunicar ou dar acesso aos seus dados pessoais a outras entidades.<br/>
  <br/>
  A Portugal Seguro apenas comunicará ou dará acesso aos seus dados pessoais às seguintes entidades:<br/>
  <br/>
  <span data-bullet={true}>•</span> Prestadores de serviços que prestem serviços à Portugal Seguro (ex., serviços contratualizados com terceiros para a prestação de serviços de gestão de data center);<br/>
  <span data-bullet={true}>•</span> Às empresas de seguros e ou resseguros com as quais tenham sido celebrados os contratos de seguro ou resseguro; <br/>
  <span data-bullet={true}>•</span> Autoridades públicas, como, por exemplo, as Autoridades Tributárias ou Tribunais Judiciais.<br/>
  <br/>
  A Portugal Seguro apenas comunicará os dados pessoais que sejam indispensáveis à prestação dos serviços contratados ou ao cumprimento de obrigações legais a que esteja sujeita.<br/>
  <br/>
  Em alguns casos, a Portugal Seguro pode ter que proceder a transferências internacionais dos seus dados pessoais (i.e., para fora da União Europeia).<br/>
  Caso a Comissão Europeia tenha declarado, através de uma decisão de adequação, que o país localizado fora da União Europeia em causa garante um nível de proteção de dados pessoais equivalente ao decorrente da legislação da União Europeia, a transferência de dados terá por base essa decisão de adequação.<br/>
  Pode consultar as decisões de adequação existentes em www.eur-lex.europa.eu.<br/>
  <br/>
  Nos casos em que a transferência seja feita para países ou organizações localizadas fora da União Europeia, em relação aos quais não haja decisão de adequação da Comissão, a Portugal Seguro assegurará que essas transferências de dados se realizam no estrito cumprimento das disposições legais e que são implementadas garantias adequadas para assegurar a proteção dos seus dados.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Durante que período os seus dados são tratados e conservados?</h2>
  <br/>
  A Portugal Seguro apenas tratará os seus dados pessoais para os fins acima indicados e apenas durante o período de tempo que se revele necessário ao cumprimento desses fins. São os seguintes os períodos de conservação dos seus dados pessoais:<br/>
  <br/>
  <strong>Registo e Prova de Transações Comerciais e Informações Pré-contratuais</strong><br/>
  No caso de celebração de contratos à distância, o prazo aplicável corresponderá à duração do contrato, podendo a este prazo acrescer o período de tempo que for necessário até ao cumprimento de todas as obrigações emergentes do contrato.<br/>
  <br/>
  <strong>Acompanhamento da gestão e execução do contrato</strong><br/>
  Duração do contrato de seguro.<br/>
  <br/>
  <strong>Prospeção comercial</strong><br/>
  1 ano, contado deste a obtenção do contacto do titular dos dados.<br/>
  <br/>
  <strong>Marketing e comunicação</strong><br/>
  1 ano após o término do contrato ou após ter havido contacto através dos worksites da Portugal Seguro.<br/>
  <br/>
  <strong>Gestão de reclamações e acompanhamento de processos judiciais</strong><br/>
  Durante a duração do litígio ou da reclamação.<br/>
  <br/>
  <strong>Melhoria da qualidade de serviço</strong><br/>
  Prazo geral de 1 ano.<br/>
  <br/>
  <strong>Cumprimento de Obrigações Legais</strong><br/>
  10 anos para o cumprimento de obrigações fiscais;<br/>
  7 anos para o cumprimento de obrigações em matéria de prevenção do branqueamento de capitais e financiamento do terrorismo.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Decisões individuais automatizadas</h2>
  <br/>
  A Portugal Seguro não adota decisões individuais automatizadas, isto é, decisões tomadas exclusivamente com base no tratamento automatizado dos seus dados pessoais e que produzam efeitos na sua esfera jurídica ou o afetem significativamente de modo similar.<br/>
  <br/>
  Caso a Portugal Seguro venha a adotar este tipo de decisões, será informado desse facto, bem como da lógica subjacente a essas decisões e a importância e eventuais consequências de tal tratamento para o titular dos dados.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Direitos dos titulares dos dados</h2>
  <br/>
  <strong>Direito de acesso</strong><br/>
  <br/>
  Sempre que o solicitar, tem o direito de obter confirmação sobre se os seus dados pessoais são tratados pela Portugal Seguro, bem como informações relativas a esse tratamento (e.g., quais as finalidades do tratamento, quais os destinatários dos seus dados e quais os prazos de conservação dos seus dados).<br/>
  Tem ainda o direito de obter uma cópia dos seus dados pessoais objeto de tratamento pela Portugal Seguro.<br/>
  <br/>
  <strong>Direito de retificação</strong><br/>
  <br/>
  Sempre que considerar que os seus dados pessoais estão incorretos ou incompletos, pode requerer a sua retificação ou que os mesmos sejam completados.<br/>
  <br/>
  <strong>Direito ao apagamento</strong><br/>
  <br/>
  Em certas situações, pode solicitar o apagamento dos seus dados pessoais. Nesses casos, a Portugal Seguro procederá ao apagamento dos seus dados, exceto se o mesmo for necessário para algum dos seguintes efeitos:<br/>
  <br/>
  <span data-bullet={true}>•</span> exercício de liberdade de expressão e de informação;<br/>
  <span data-bullet={true}>•</span> cumprimento de obrigação legal que exija o tratamento e que se aplique à Portugal Seguro;<br/>
  <span data-bullet={true}>•</span> motivos de interesse público no domínio da saúde pública;<br/>
  <span data-bullet={true}>•</span> fins de arquivo de interesse público, fins de investigação científica ou histórica ou fins estatísticos, na medida em que o exercício do direito ao apagamento prejudique gravemente a obtenção dos objetivos desse tratamento; <br/>
  <span data-bullet={true}>•</span> declaração, exercício ou defesa de um direito num processo judicial.<br/>
  <br/>
  <strong>Direito à limitação do tratamento</strong><br/>
  <br/>
  Em determinados casos, pode solicitar à Portugal Seguro que restrinja o acesso a dados pessoais ou que suspenda as atividades de tratamento. Tal sucederá, por exemplo, nos casos em que conteste a exatidão dos seus dados pessoais, durante um período de tempo que permita à Portugal Seguro verificar a sua exatidão, ou nos casos em que tiver apresentado oposição ao tratamento, até que se verifique se os interesses legítimos da Portugal Seguro prevalecem sobre os seus.<br/>
  <br/>
  <strong>Direito de portabilidade</strong><br/>
  <br/>
  Nos casos previstos na legislação aplicável, tem o direito de receber os dados pessoais que lhe digam respeito e que tenha fornecido à Portugal Seguro num formato estruturado, de uso corrente e de leitura automática. Tem ainda o direito de pedir que a Portugal Seguro transmita esses dados a outro responsável pelo tratamento, desde que tal seja tecnicamente possível.<br/>
  <br/>
  <strong>Direito de oposição</strong><br/>
  <br/>
  Tem o direito de se opor ao tratamento dos seus dados pessoais a qualquer momento, por motivos relacionados com a sua situação particular, quando esse tratamento se basear no interesse legítimo da Portugal Seguro ou quando o mesmo for realizado para fins diversos daqueles para os quais os dados foram recolhidos, mas que sejam compatíveis com os mesmos.<br/>
  A Portugal Seguro deixará, nesses casos, de tratar os seus dados pessoais, a não ser que tenha razões legítimas para realizar esse tratamento e que estas que prevaleçam sobre os seus interesses.<br/>
  Pode também opor-se, a qualquer momento e sem necessidade de justificação, ao tratamento dos seus dados para fins de marketing direto.<br/>
  <br/>
  <strong>Direito de não ficar sujeito a decisões individuais automatizadas</strong><br/>
  <br/>
  A Portugal Seguro não adota decisões individuais automatizadas, incluindo definição de perfis, que produzam efeitos na sua esfera jurídica ou o afetem significativamente de forma similar.<br/>
  <br/>
  <strong>Direito a retirar o seu consentimento</strong><br/>
  <br/>
  Nos casos em que o tratamento dos dados seja feito com base no seu consentimento, poderá retirar o consentimento a qualquer momento.<br/>
  Caso retire o seu consentimento, os seus dados pessoais deixarão de ser tratados, exceto se existir outro fundamento, como o contrato ou o interesse legítimo da Portugal Seguro, que permita esse tratamento.<br/>
  <br/>
  <strong>Direito de apresentar reclamações junto da autoridade de controlo</strong><br/>
  <br/>
  Tem o direito de apresentar reclamações junto da autoridade de controlo competente, relativamente a matérias relacionadas com o tratamento dos seus dados pessoais.<br/>
  Em Portugal, a autoridade de controlo competente é a Comissão Nacional de Proteção de Dados.<br/>
  Para mais informações, aceda a <Link external={true} to="https://www.cnpd.pt" >www.cnpd.pt</Link><br/>
  <br/>
  <br/>
  <br/>
  <h2>Como pode exercer os seus direitos</h2>
  <br/>
  Pode exercer os seus direitos através dos seguintes canais:<br/>
  <br/>
  <span data-bullet={true}>•</span> E-mail: pode exercer os seus direitos através de e-mail, para o endereço <Link external={true} newtab={false} to="mailto:geral@portugalseguro.pt">geral@portugalseguro.pt</Link>.<br/>
  <span data-bullet={true}>•</span> Carta: pode exercer os seus direitos através de carta, dirigida à Portugal Seguro - Mediação de Seguros, Lda. e enviada para a morada Praceta Santa Eulália 5, 4590-530 Paços de Ferreira.<br/>
  <span data-bullet={true}>•</span> Telefone: pode exercer os seus direitos através de chamada telefónica para 255 161 043.<br/>
  <br/>
  O exercício dos seus direitos é gratuito.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Recolha indireta dos seus dados pessoais</h2>
  <br/>
  É possível que a Portugal Seguro tenha recolhido os seus dados pessoais através de terceiros ou de outros meios e ainda que não seja cliente da Portugal Seguro.<br/>
  Tal acontecerá, por exemplo, nos casos em que o seu contacto seja fornecido por algum familiar ou entidade terceira, quando seja beneficiário de determinado seguro, quando seja colaborador de um cliente da Portugal Seguro ou quando seja membro de um órgão social de uma pessoa coletiva que seja cliente da Portugal Seguro.<br/>
  Sempre que a Portugal Seguro recolher os seus dados através de terceiros ou outros meios, a Portugal Seguro procurará prestar-lhe as informações relativas ao tratamento dos seus dados pessoais na primeira oportunidade.<br/>
  <br/>
  <h2>Medidas de segurança, técnicas e organizativas</h2>
  <br/>
  Para garantir a proteção da segurança dos dados pessoais que lhe são disponibilizados, a Portugal Seguro adotou diversas medidas de segurança, técnicas e organizativas, de forma a proteger os dados pessoais contra a destruição, a perda, a alteração, a divulgação ou o acesso não autorizados a dados pessoais e contra qualquer outra forma de tratamento ilícito.<br/>
  <br/>
  Nos casos em que a Portugal Seguro subcontrate a outras entidades a prestação de serviços que envolvam a cedência de dados pessoais, essas entidades ficarão obrigadas a adotar as medidas técnicas e organizativas necessárias de forma a protegerem os dados pessoais contra a destruição, a perda, a alteração, a divulgação, o acesso não autorizado ou qualquer outro tipo de tratamento ilícito.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Responsabilidade sobre serviços e website</h2>
  <br/>
  Aconselhamos a que consulte as regras sobre utilização de cookies constantes do website da Portugal Seguro, podendo também consultar a Política de Cookies da Portugal Seguro <Link to="/cookies">aqui</Link>.<br/>
  <br/>
  O website da Portugal Seguro pode eventualmente conter links para outros websites, produtos ou serviços de terceiros. A Portugal Seguro não tem relação com esses terceiros, nem estes estão abrangidos pela presente Política de Privacidade. A Portugal Seguro aconselha, por isso, a que se informe sobre as regras definidas por esses terceiros para o tratamento dos seus dados junto dos mesmos.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Fique atualizado quanto à proteção dos seus dados pessoais e quanto ao tratamento dos mesmos pela Portugal seguro</h2>
  <br/>
  As informações constantes deste documento poderão ter que ser alvo de alteração ao longo do tempo.<br/>
  Por isso, aconselhamos que consulte a política de privacidade constante neste site ou em www.portugalseguro.pt/privacidade/, onde estas informações estarão a todo o tempo atualizadas, para que possa sempre estar ao corrente do tratamento que é feito dos seus dados.<br/>
  <br/>
  Sempre que haja alterações em matéria de tratamento dos seus dados pessoais, a Portugal Seguro irá informá-lo através do site www.portugalseguro.pt/privacidade/ ou através dos outros canais de comunicação normalmente utilizados.<br/>
  <br/>
  <br/>
  <br/>
  Este site utiliza Cookies. Ao navegar, está a consentir o seu uso. Para mais informação, leia a nossa  <Link to="/cookies">política de cookies</Link>.<br/>
</>;
