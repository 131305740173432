import React,{useState,useCallback,useEffect,useRef,useMemo} from 'react';
import styled from 'styled-components';

import elementWithinViewport from 'element-within-viewport';

import logo from './assets/logo.svg';
import logoAlt from './assets/logo-alt.svg';

import Link from 'Link';

import px from 'px.js';

const Root = styled.div`
  user-select: none;
  position: fixed;

  ${px.landscape}{
    &[data-absolute=true]{
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &[data-hide=true]{
    opacity: 0;
  }

  top: ${px.vw(0)};
  z-index: 3;

  transition-duration: 500ms;
  transition-property: top;

  &[data-slideout=true]{
    top: ${px.vw(-120)};
  }

  ${px.portrait}{
    &[data-slideout=true]{
      top: ${px.mvw(-120)};
    }
    height: ${px.mvw(64)};
    width: 100%;
    background: #171C26D0;
    &[data-alt=true]{
      background: #EDF2FAD0;
    }
  }
  ${px.tablet}{
    &[data-slideout=true]{
      top: ${px.tvw(-120)};
    }
    height: ${px.tvw(64)};
  }

`;

const Logo = styled(
  ({alt,...p}) => <div {...p}><img alt="Portugal Seguro" src={alt?logoAlt:logo}/></div>
)`
  position: absolute;
  overflow-x: hidden;

  top: ${px.vw(40)};
  left: ${px.vw(40)};
  width: ${px.vw(131*1.5)};
  & img{
    width: ${px.vw(131*1.5)};
    height: ${px.vw(48*1.5)};
    transition-duration: 200ms;
  }

  @media (min-aspect-ratio: 18/10) {
    top: ${px.vh(40)};
    left: ${px.vh(40)};
    width: ${px.vh(131*1.5)};
    & img{
      width: ${px.vh(131*1.5)};
      height: ${px.vh(48*1.5)};
    }
  }

  ${px.portrait}{
    top: ${px.mvw(16)};
    left: ${px.mvw(16)};
    width: ${px.mvw(131)};
    & img{
      width: ${px.mvw(131)};
      height: ${px.mvw(48)};
    }
  }
  ${px.tablet}{
    top: ${px.tvw(16)};
    left: ${px.tvw(16)};
    width: ${px.tvw(131*1)};
    & img{
      width: ${px.tvw(131*1)};
      height: ${px.tvw(48*1)};
    }
  }

  transition-duration: 300ms;
  /* transition-delay: 1s; */
  &[data-min=true][data-open=false][data-absolute=false]{
    width: ${px.vw(40)};
    & img{
      width: ${px.vw(131)};
      height: ${px.vw(48)};
    }
    @media (min-aspect-ratio: 18/10) {
      width: ${px.vh(40)};
      & img{
        width: ${px.vh(131)};
        height: ${px.vh(48)};
      }
    }
  }
  &[data-min=true][data-open=false][data-open=false]{ /* increase specificity */
    ${px.portrait}{
      width: ${px.mvw(40*0.75)};
      & img{
        width: ${px.mvw(131*0.75)};
        height: ${px.mvw(48*0.75)};
      }
    }
    ${px.tablet}{
      width: ${px.tvw(40*0.75)};
      & img{
        width: ${px.tvw(131*0.75)};
        height: ${px.tvw(48*0.75)};
      }
    }
  }
`;

const BurgerButton = styled(
  p => <div {...p}><div>&nbsp;</div><div>&nbsp;</div></div>
)`
  position: absolute;
  left: ${px.vw(1358)};
  top: ${px.vw(48)};
  @media (min-aspect-ratio: 18/10) {
    top: ${px.vh(48)};
  }
  width: ${px.vw(32)};
  height: ${px.vw(32)};
  overflow: visible;
  cursor: pointer;

  ${px.portrait}{
    left: ${px.mvw(327)};
    top: ${px.mvw(12)};
    width: ${px.mvw(32)};
    height: ${px.mvw(32)};
  }
  ${px.tablet}{
    left: ${px.mvw(335)};
    top: ${px.tvw(16)};
    width: ${px.tvw(32)};
    height: ${px.tvw(32)};
  }

  & div{
    position: absolute;
    border-bottom: ${px.vw(2)} solid #CEDAF2;
    height: 0;
    width: ${px.vw(32)};
    transform-origin: 50% 50%;
    transform: translate(0,0) rotate(0);
    transition: transform 0.5s 0s, top 0.5s 0.1s;
    /* transition-property: transform,top; */

    top: ${px.vw(12)};
    &:last-child{
      top: ${px.vw(24)};
    }

    ${px.portrait}{
      border-bottom: ${px.mvw(2)} solid #CEDAF2;
      width: ${px.mvw(32)};
      top: ${px.mvw(12)};
      &:last-child{
        top: ${px.mvw(24)};
      }
    }
    ${px.tablet}{
      border-bottom: ${px.tvw(2)} solid #CEDAF2;
      width: ${px.tvw(32)};
      top: ${px.tvw(12)};
      &:last-child{
        top: ${px.tvw(24)};
      }
    }
  }
  &[data-alt=true] div{
    border-bottom: ${px.vw(2)} solid #171C26;
  }

  &[data-open=true] div{
    top: ${px.vw(18)};
    transition: transform 0.5s .1s, top 0.5s 0s;
    &:first-child{
      transform: rotate(-45deg);
    }
    &:last-child{
      transform: rotate(45deg);
    }
  }

  ${px.portrait}{
    &[data-alt=true] div{
      border-bottom: ${px.mvw(2)} solid #171C26;
    }
    &[data-open=true] div{
      top: ${px.mvw(18)};
    }
  }
  ${px.tablet}{
    &[data-alt=true] div{
      border-bottom: ${px.tvw(2)} solid #171C26;
    }
    &[data-open=true] div{
      top: ${px.tvw(18)};
    }
  }


`;


export default function TopBar({className,delaying,path,scrollElement,alt,hide,burgerOpen,onBurgerClick}){

  const [min,setMin] = useState(false);
  const [slideOut,setSlideOut] = useState(false);
  const root = useRef();
  const footer = useRef();

  const checkScroll = useCallback(()=>{
    setMin(scrollElement.current.scrollTop > window.innerHeight/5);
    setSlideOut(
      scrollElement.current.scrollTop > footer.current.offsetTop - window.innerHeight/4
    );
  },[setMin,setSlideOut,scrollElement]);

  useEffect(()=>{
    if(delaying)
      return;
    if(!scrollElement)
      return;

    footer.current = document.querySelector('#pagefooter');

    const node = scrollElement.current;
    node.addEventListener('scroll',checkScroll);
    return ()=>{
      node.removeEventListener('scroll',checkScroll);
    };
  },[scrollElement,checkScroll,delaying,footer]);

  const absolute = useMemo(()=>(
    !scrollElement || path !== '/'
  ),[scrollElement,path]);


  return (
    <Root ref={root} data-hide={hide} data-slideout={slideOut} data-alt={alt} data-absolute={absolute}>
      <Link to='/'><Logo alt={alt} data-min={min} data-open={burgerOpen} data-absolute={absolute}/></Link>
      <BurgerButton data-open={burgerOpen} data-alt={alt} onClick={onBurgerClick} />
    </Root>
  );
}
