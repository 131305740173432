export default {
  privateInsurance: true,
  title: '',
  description: '',
  buttons: [],
  pages: [
    {
      subtitle: '',
      body: [
        '',
      ],
      boxes:[
        {
          title: '',
          body: [''],
          picture: 1,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: '',
          body: [''],
        },
      ],
    },
  ]
};
