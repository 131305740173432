const vantagens = {
  title2: 'Vantagens',
  boxes2:[
    {
      title: 'Flexibilidade e conveniência',
      body: ['Pode facilmente criar uma solução de proteção adequada à realidade e dimensão da sua empresa, com um leque de coberturas indispensáveis e que abrange 90% dos sinistros participados.​'],
    },
    {
      title: 'Vantagens e preço mais atrativo',
      body: ['Beneficie de descontos sobre o prémio comercial do seu seguro caso possua meios de prevenção contra intrusão e/ou incêndio ou opte pelo pagamento anual por débito direto. Se preferir, pode, também, fasear o seu pagamento de acordo com a periodicidade que mais lhe convier.​'],
    },
    {
      title: 'Atendimento personalizado 24 horas',
      body: ['Se necessário, enviamos profissionais especializados - canalizadores, eletricistas, etc. – à sua empresa, com um custo/hora fixo e oferta do valor de deslocação.​'],
    },
  ],
}

export default {
  privateInsurance: false,
  title: 'Multirriscos',
  description: 'Para vários riscos, uma proteção eficaz',
  buttons: ['Base','Negócio','Estabelecimento','Total'],
  pages: [
    {
      subtitle: 'Base',
      body: [
        'A proteção essencial para a sua atividade.',
        'Uma solução simples e fácil de contratar que garante a proteção essencial da sua empresa.​​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Base',
          body: [
            'Oferta base para a proteção essencial do seu negócio.',
            '',
            '',
            '• Tempestades',
            '• Inundações',
            '• Furto ou Roubo',
          ],
          picture: 6,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Negócio',
      body: [
        'Para que os imprevistos não parem a sua atividade.',
        'Garantimos a proteção adequada à sua atividade, porque os negócios não são todos iguais.​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Negócio',
          body: [
            'Proteja-se contra imprevistos.',
            '',
            '',
            '• Interrupção da Atividade',
            '• Avaria de Máquinas, Equipamentos Eletrónicos e Informáticos',
            '• Responsabilidade Civil Extracontratual por Intoxicação Alimentar',
          ],
          picture: 6,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Estabelecimento',
      body: [
        'Uma proteção adaptada à sua atividade.',
        'Prepare-se para os imprevistos com mais coberturas e uma proteção mais alargada.​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Estabelecimento',
          body: [
            'Uma proteção completa, adaptada à sua empresa.',
            '',
            '',
            '• Atos de Vandalismo',
            '• Quebra de Vidros, Espalhos, Letreiros e Anúncios Luminosos',
            '• Valores em Caixa, Cofre e em Trânsito',
          ],
          picture: 6,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Total',
      body: [
        'Máxima proteção para a sua atividade.',
        'Conjugue todas as coberturas e garanta a proteção mais elevada para a sua empresa.​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Total',
          body: [
            'Conjugue todas as coberturas e garanta a máxima proteção.',
            '',
            '',
            '• Derrame Acidental de Sistemas de Proteção Contra Incêndio',
            '• Honorários de Técnicos',
            '• Danos em Bens do Senhorio',
          ],
          picture: 6,
        },
      ],
      ...vantagens
    },
  ]
};
