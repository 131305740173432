export default {
  privateInsurance: true,
  title: 'Auto',
  description: 'Soluções Automóvel para conduzir seguro na estrada e na vida',
  buttons: [
    'Auto 1 | 2 | 3 | 4',
    'Autoestima',
    'Clássicos',
    'Mota',
  ],
  pages: [
    {
      subtitle: 'Auto 1 | 2 | 3 | 4',
      body: [
        'Não existem dois condutores iguais. É por isso que agora há um seguro para cada um. Desde o seguro auto obrigatório, com serviços mínimos, até à modalidade de máxima segurança e conforto, para quem valoriza a sua proteção, da sua família e contra danos provocados no seu automóvel.',
      ],
      boxes:[
        {
          title: 'Auto 1',
          body: ['O seguro auto obrigatório para quem quer um preço mais reduzido, com serviços essenciais mínimos incluídos como Proteção ao Condutor e  Assistência em Viagem.​'],
          picture: 2,
        },
        {
          title: 'Auto 2',
          body: ['O seguro auto obrigatório para quem quer um preço muito económico e valoriza serviços para resolver pequenos imprevistos em estrada. Opção de Proteção Jurídica e Quebra Isolada de Vidros.'],
          picture: 2,
        },
        {
          title: 'Auto 3',
          body: ['O seguro auto que já inclui proteção ao automóvel no caso de furto, incêndio, tempestades ou vandalismo, com um preço muito acessível.​​'],
          picture: 2,
        },
        {
          title: 'Auto 4',
          body: ['O seguro auto de danos próprios para quem valoriza máxima segurança, conforto e a proteção mais abrangente para danos provocados ao automóvel. Opção de veículo de substituição, em caso de acidente ou avaria.'],
          picture: 2,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Rede de distribuição nacional',
          body: ['Com a maior rede de distribuição do país, garantimos o apoio de 12.000 agentes, de norte a sul e ilhas.​'],
        },
        {
          title: 'Proteção vital do condutor',
          body: ['Uma proteção abrangente para o condutor, que nem sempre está protegido. Em caso de acidente, esta cobertura garante o pagamento de despesas médicas, tratamentos e assistência, que possibilitem a recuperação da qualidade de vida do condutor e o apoio à estabilidade financeira da sua família, até ao valor de 500.000€, independentemente de estarem ou não definidas as responsabilidades pelo acidente.​​​​​'],
        },
        {
          title: 'Poupança Auto',
          body: ['Agora é bem mais fácil pagar o seu seguro automóvel. Temos para si o Poupança Auto​, um seguro de vida de capitalização que constitui uma solução inovadora para o ajudar a pagar o prémio anual do seu seguro automóvel.​​'],
        },
        {
          title: 'Proteção mesmo quando está parado',
          body: ['O seu seguro automóvel encontra-se inalterado quando o seu carro está parado de forma a protegê-lo contra furtos ou roubo, fenómenos da natureza, atos de vandalismo ou até quebra isolada de vidros​.'],
        },
        {
          title: 'Oficinas Help-a-Car',
          body: ['Em caso de acidente, entre dois ligeiros e sem feridos, leve o seu veículo à oficina Help-a-Car mais próxima. Tratam de tudo por si, com prioridade no atendimento: participa o acidente, perita o veículo e dá início à regularização do sinistro. Tudo num tempo médio de 30 minutos e com a entrega de uma viatura de cortesia até o seu automóvel ser reparado.'],
        },
        {
          title: 'Regularização de sinistros',
          body: ['Garantimos o compromisso da regularização de sinistros em 10 dias úteis, ao contrário dos 32 dias úteis de prazo legal, e garantia de 2 anos nas reparações efetuadas na Rede de Oficinas Convencionadas ou Oficinas Help-a-Car​.​'],
        },
      ],
    },
    {
      subtitle: 'Autoestima',
      body: [
        '​Quantos mais anos um carro tem, mais atenção precisa. Se não tem um seguro com Danos Próprios por achar que já não se justifica, descubra o AUTOESTIMA. Com este seguro o seu automóvel fica protegido com um capital seguro correspondente a 70% do seu valor de mercado e em caso de acidente, a reparação é feita na Rede de Oficinas AUTOESTIMA com garantia de 2 anos.​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Autoestima',
          body: ['O seguro auto, exclusivo para carros usados, que inclui proteção de danos próprios adequada ao automóvel a um preço mais acessível do que um tradicional seguro de danos próprios.Inclui veículo de cortesia, em caso de imobilização por acidente.​'],
          picture: 2,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Proteção abrangente',
          body: ['Em caso de acidente o seu automóvel fica protegido até 70% do valor do veículo. Caso exceda esse valor, tem a proteção habitual já associada à perda total. '],
        },
        {
          title: 'Qualidade e garantia ',
          body: ['Em caso de acidente, a reparação é feita na rede exclusiva de oficinas AUTOESTIMA selecionadas pela Fidelidade. Contará com profissionais qualificados para deixar o seu carro como novo. Qualquer reparação tem 2 anos de garantia.​​​​​'],
        },
        {
          title: 'Veículo de cortesia',
          body: ['Porque a sua vida não pode parar e o seu bem-estar é importante para nós, disponibilizamos, em caso de acidente, um veículo de cortesia.​​​'],
        },
        {
          title: 'Serviço de procura',
          body: ['O seu seguro automóvel encontra-se inalterado quando o seu carro está parado de forma a protegê-lo contra furtos ou roubo, fenómenos da natureza, atos de vandalismo ou até quebra isolada de vidros​.'],
        },
        {
          title: 'Proteção mesmo quando está parado',
          body: ['O seu seguro automóvel encontra-se inalterado quando o seu carro está parado de forma a protegê-lo contra furtos ou roubo, fenómenos da natureza, atos de vandalismo ou até quebra isolada de vidros​'],
        },
        {
          title: 'Poupança Auto',
          body: ['Agora é bem mais fácil pagar o seu seguro automóvel. Temos para si o Poupança Auto​, um seguro de vida de capitalização que constitui uma solução inovadora para o ajudar a pagar o prémio anual do seu seguro automóvel.​​'],
        },
      ],
    },
    {
      subtitle: 'Clássicos',
      body: [
        'Para muitos, uma peça de arte viva, para outros, um verdadeiro companheiro de décadas de histórias. Ter um “Clássico” é uma experiência única, por isso, o seguro Automóvel Clássicos foi pensado para quem quer proteger esse valioso património.',
      ],
      boxes:[
        {
          title: 'Clássicos 1 | 2 | 3',
          body: [
            'Dispomos de 3 ofertas diferentes para que possa proteger adequadamente o seu património automóvel. ',
            'Proteja o seu clássico  contra acidentes, furto ou roubo e mesmo contra atos de vandalismo.',
          ],
          picture: 2,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Responsabilidade civil cruzada',
          body: ['Esta cobertura protege-o em caso de colisão do seu “clássico” com o seu veículo de utilização habitual ou um dos veículos do seu agregado familiar.'],
        },
        {
          title: 'Proteção vital do condutor',
          body: ['O seguro Automóvel Clássicos inclui a proteção vital do condutor, uma cobertura que lhe garante, caso seja responsável pelo acidente, o pagamento de despesas médicas, tratamentos e assistência, até ao valor de 500.000€.'],
        },
        {
          title: 'Serviço de assistência',
          body: ['Em caso de acidente ou avaria, em percursos mais longos em Portugal, a “assistência em viagem clássicos” tem um serviço de reboque dedicado ao transporte do seu veículo, desde o local do acidente ou da avaria, até ao local da reparação ou domicílio em Portugal. Pode ainda continuar a sua viagem num veículo de aluguer, caso o serviço esteja disponível localmente. '],
        },
      ],
    },
    {
      subtitle: 'Mota',
      body: [
        'Desfrute dos melhores momentos sobre rodas.',
        'Deixe as preocupações connosco e conduza com puro prazer.',
      ],
      boxes:[
        {
          title: 'Mota',
          body: ['O seguro Mota além da garantia de proteção a terceiros dá acesso a uma rede de assistência em viagem e proteção jurídica.'],
          picture: 2,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Se tiver um acidente',
          body: ['A cobertura de Responsabilidade Civil garante os danos corporais e materiais causados a terceiros, e a cobertura de Proteção Jurídica garante a sua defesa.​'],
        },
        {
          title: 'Se tiver uma avaria',
          body: ['A cobertura de Assistência em Viagem disponibiliza o envio de um reboque e/ou uma nova peça de substituição que não exista no local.​'],
        },
        {
          title: 'Quebra isolada de vidros',
          body: ['Uma cobertura opcional que assegura a reparação ou substituição dos vidros em consequência de quebra.​'],
        },
      ],
    },
  ]
};
