export default {
  privateInsurance: false,
  title: 'Cyber Safety',
  description: 'O melhor hack para a sua empresa, para que não sofra um',
  buttons: ['Cyber Safety'],
  pages: [
    {
      subtitle: 'Cyber Safety',
      body: [
        'Um seguro especial que está atento a problemas que poucos de nós imaginamos que possam acontecer, desde intrusão de terceiros em sistemas informáticos até violação do direito de intimidade pessoal.​',
      ],
      boxes:[
        {
          title: 'Cyber Safety',
          body: ['Descubra qual o grau de exposição da sua empresa a riscos cibernéticos e saiba como é que os serviços e coberturas do seguro Cyber Safety podem proteger o seu negócio.​​​​​​​​​​​​​​​​​​'],
          picture: 3,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Oferta de diagnóstico RGPD',
          body: ['Realize um auto diagnóstico relativo ao grau de conformidade legal da sua empresa no que diz respeito à proteção de dados de caráter pessoal.​​'],
        },
        {
          title: 'Análise do site',
          body: ['Efetue uma análise e diagnóstico ao risco do site da sua empresa.​​'],
        },
        {
          title: 'Análise detalhada das vulnerabilidades',
          body: ['Analise a sua rede e receba um relatório com diferentes níveis de risco para ajudar a priorizar intervenções mais urgentes.​​'],
        },
        {
          title: 'Assistência tecnológica',
          body: ['Obtenha assistência tecnológica e informática de técnicos especializados para assistência e Configuração Remota do sistema informático; análise e resolução de vulnerabilidades, deteção e eliminação de malware e muito mais​'],
        },
        {
          title: 'Serviços de Prevenção',
          body: ['Analise os seus equipamentos informáticos e corrija os problemas existentes.​​'],
        },
        {
          title: 'Backups informáticos',
          body: ['Efetue cópias de Segurança dos seus dados (backups) em ambiente seguro na “nuvem” para preservar a informação fulcral do seu negócio'],
        },
      ],
    },
  ]
};
