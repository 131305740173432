export default {
  privateInsurance: true,
  title: 'Poupança',
  description: 'Para que possa preparar o seu futuro',
  buttons: [
    'Savings',
    'Poupança Auto',
    'Poupança Objetivo',
  ],
  pages: [
    {
      subtitle: 'Savings',
      body: [
        'Seja uma casa de praia, um carro desportivo, uma grande viagem ou os estudos dos filhos, todos temos objetivos, projetos e sonhos que queremos concretizar. E para isso, só precisa de começar a poupar!​​​​​​​​​​​​​​​​​​​',
        '',
        'O seguro Savings é um seguro de vida individual que integra soluções de poupança que lhe permitem definir e atingir os seus objetivos, de forma 100% mobile.',
      ],
      boxes:[
        {
          title: 'Savings',
          body: [
            'Se tem objetivos de poupança que quer alcançar e quer fazê-lo ao seu ritmo, esta é a solução para si!',
            '',
            '• 3 opções de poupança',
            '• Sem custos de utilização',
            '• Entregas a partir de 25€',
          ],
          picture: 3,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Cómodo',
          body: ['Poupe para os seus objetivos a partir de 25€ por mês.'],
        },
        {
          title: '100% mobile',
          body: ['Faça a gestão das suas poupanças, onde e quando quiser, no seu smartphone.'],
        },
        {
          title: 'Sem custos de utilização',
          body: ['Se precisar, pode resgatar as suas poupanças, em qualquer momento, sem penalizações.'],
        },
      ],
    },
    {
      subtitle: 'Poupança Auto',
      body: [
        'Agora é bem mais fácil pagar o seu seguro automóvel. Temos para si o Poupança Auto, um seguro de vida de capitalização que constitui uma solução inovadora para o ajudar a pagar o prémio anual do seu seguro automóvel.​​​​​​​​​​​​​​​​​​​​​​​​​​​​',
        'O Poupança Auto é um seguro de capitalização com capital e rendimento garantido, que lhe permite resgatar sem qualquer penalização, total ou parcialmente, a sua poupança para pagar o seu Seguro Automóvel.',
      ],
      boxes:[
        {
          title: 'Poupança Auto',
          body: [
            'A poupança que o ajuda a pagar o seu seguro automóvel.',
            '',
            '• Pague o seguro automóvel sem esforço',
            '• Resgate sem penalização',
            '• Capital e Rendimento garantidos',
          ],
          picture: 3,
        },
      ],
      title2: 'Garantias',
      boxes2:[
        {
          title: 'Em caso de vida da Pessoa Segura no termo do contrato',
          body: ['Pagamento do Capital Garantido nessa data.'],
        },
        {
          title: 'Em caso de morte da Pessoa Segura ao longo da vigência do contrato',
          body: ['Pagamento do Capital Garantido na data da comunicação da morte.'],
        },
        {
          title: 'Capital garantido',
          body: ['Corresponde, em cada momento do contrato, ao valor das entregas deduzido de eventuais resgates efetuados, capitalizado com as taxas definidas no início de cada exercício.'],
        },
        {
          title: 'Rendimento',
          body: ['A sua poupança cresce todos os anos porque tem um rendimento garantido, com uma taxa definida, no início de cada ano, em função da Euribor a 3 meses (em 2020, a taxa é de 0,05%).'],
        },
      ],
    },
    {
      subtitle: 'Poupança Objetivo',
      body: [
        'Tem um sonho mas não tem como o realizar? Criamos para si o Poupança Objetivo que o ajuda a poupar para realizar aquele sonho há tanto tempo adiado.​​​​​​​​​​​​​​​​​​​​​​',
        '',
        'O Poupança Objetivo é um seguro de capitalização de médio/longo prazo, com uma duração mínima de 5 anos e 1 dia e máxima de 15, que para além do rendimento anual garantido lhe dá um bónus anual de permanência.',
      ],
      boxes:[
        {
          title: 'Poupança Auto',
          body: [
            'O seguro para quem pretende poupar para alcançar um objetivo.',
            '',
            '• Taxa anual de rendimento garantido',
            '• Bónus de permanência de 0.25%',
            '• Entregas a partir de 25€/mês',
          ],
          picture: 3,
        },
      ],
      title2: 'Garantias',
      boxes2:[
        {
          title: 'Em caso de vida da Pessoa Segura no termo do contrato',
          body: ['Pagamento do Capital Garantido nessa data.'],
        },
        {
          title: 'Em caso de morte da Pessoa Segura ao longo da vigência do contrato',
          body: ['Pagamento do Capital Garantido na data da comunicação da morte.'],
        },
        {
          title: 'Capital garantido',
          body: ['Corresponde, em cada momento do contrato, ao valor das entregas não resgatadas, revalorizadas às taxas de juro anuais brutas, definidas semestralmente e acrescidas do bónus de permanência atribuído em cada ano.'],
        },
        {
          title: 'Rendimento',
          body: ['O Poupança Objetivo garante um rendimento calculado a uma taxa de juro anual bruta. Aos contratos em vigor a 31 de dezembro de cada ano, à taxa ou taxas de rendimento garantido, acrescerá um bónus de permanência de 0,25%.'],
        },
      ],
    },
  ]
};
