export default {
  privateInsurance: false,
  title: 'Produtos Empresas',
  description: 'Saiba que pode contar sempre com a Portugal Seguro',
  buttons: [
    'Pessoas',
    'Património',
    'Responsabilidades',
  ],
  pages: [
    {
      subtitle: 'Pessoas',
      body: [
        'Proteja quem dá vida ao seu negócio.',
      ],
      boxes:[
        {
          title: 'Acidentes de Trabalho',
          body: [
            'Terá capacidade financeira caso um colaborador reclame uma indeminização em tribunal devido a um acidente de trabalho?',
            'Para os seus colaboradores a melhor proteção.',
          ],
          picture: 4,
        },
        {
          title: 'Seguro de Saúde ',
          body: [
            '​Tem disponível o tratamento adequado no caso de ter uma doença grave?',
            'Pelo bem-estar dos seus colaboradores e respetivos familiares.',
          ],
          picture: 5,
        },
        {
          title: 'Vida Risco',
          body: [
            'Proteja o sucesso da sua empresa.',
            '​Proteja a vida de quem dá vida ao seu negócio.',
          ],
          picture: 6,
        },
        {
          title: 'Acidentes Pessoais',
          body: [
            'O que acontece ao seu negócio se tiver um acidente grave nos momentos de lazer?',
            'Salvaguarde a segurança dos seus colaboradores em caso de acidente.',
          ],
          picture: 7,
        },
      ],
      title2: '',
      boxes2:[],
    },
    {
      subtitle: 'Património',
      body: [
        'Para que o seu negócio não pare nunca.',
      ],
      gridtemplate: 3,
      boxes:[
        {
          title: 'Multirriscos Negócios',
          body: [
            'E se os equipamentos vitais à sua atividade se avariarem?',
            'Proteja as instalações, o conteúdo, os colaboradores e os seus clientes.',
          ],
          picture: 6,
        },
        {
          title: 'Automóvel',
          body: [
            'As encomendas dos seus clientes podem esperar caso a sua viatura sofra um acidente?',
            'Salvaguarde a segurança dos seus colaboradores em caso de acidente.',
          ],
          picture: 2,
        },
        {
          title: 'Equipamento Eletrónico',
          body: [
            'Se na consequência de um ato de vandalismo forem causados danos ao seu equipamento eletrónico, o negócio ressente-se?',
            'Proteger o equipamento eletrónico da sua empresa é essencial.',
          ],
          picture: 3,
        },
        {
          title: 'Mercadorias Transportadas',
          body: [
            'As suas mercadorias estão protegidas em caso de capotamento do meio de transporte terrestre?',
            'Para o transporte seguro das suas mercadorias.',
          ],
          picture: 7,
        },
        {
          title: 'Perdas de Exploração',
          body: ['Garantia de indemnização dos prejuízos sofridos resultantes da interrupção ou redução da atividade segura exercida pela Empresa, em consequência de sinistro de danos materiais garantidos.​​​​​​'],
          picture: 7,
        },
        {
          title: 'Bens em Leasing',
          body: ['Garantia de indemnização dos prejuízos sofridos resultantes da interrupção ou redução da atividade segura exercida pela Empresa, em consequência de sinistro de danos materiais garantidos.​​​​​​'],
          picture: 7,
        },
      ],
      title2: '',
      boxes2:[],
    },
    {
      subtitle: 'Responsabilidades',
      body: [
        'A salvaguarda contra danos causados a terceiros.',
      ],
      boxes:[
        {
          title: 'Responsabilidade Civil',
          body: [
            'E se um cliente sofrer uma intoxicação alimentar? ',
            'Uma proteção à medida do seu negócio.',
          ],
          picture: 7,
        },
        {
          title: 'Responsabilidade Ambiental',
          body: [
            'A sua empresa preocupa-se em proteger o Meio Ambiente?',
            'A atitude mais responsável.',
          ],
          picture: 7,
        },
      ],
      title2: '',
      boxes2:[],
    },
  ]
};
