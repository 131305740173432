import React from 'react';

import styled,{css} from 'styled-components';

const { detect } = require('detect-browser');
const browser = detect();

const compatibleBrowser = true && browser && (
  (browser.name === 'chrome' && parseInt(browser.version) >= 25)
  || (browser.name === 'firefox' && parseInt(browser.version) >= 28)
  || (browser.name === 'opera' && parseInt(browser.version) >= 28)
);

const Img = styled.img`
  &:focus{
    outline: none;
  }
  pointer-events: none;
`;
const VideoTag = styled.video`
  &:focus{
    outline: none;
  }
  pointer-events: none;
`;

export default function Video({enable,src,src2,poster,webp=false,...p}){

  return (compatibleBrowser && enable) ? (
    webp ? (
      <Img onClick={()=>{}} src={src} {...p}/>
    ):(
      <VideoTag  key={src} autoPlay={true} muted={true} loop={true} poster={poster} {...p}>
        <source src={src} />
        {src2 ? <source src={src2} /> : null}
      </VideoTag>
    )
  ):(
    <Img alt="" src={poster} {...p}/>
  );
}
