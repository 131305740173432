import React,{useEffect,useState,useCallback,useRef} from 'react';
import styled from 'styled-components';

import UnstyledVideo from 'Video';

import px from 'px.js';

import RawLink from 'Link';

import chevron from './assets/chevron.svg';
import chevronAlt from './assets/chevron-alt.svg';

const duration = 500;

const Root = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: #171C26;
  overflow-y: hidden;

  transition-duration: ${duration}ms;
  transition-property: opacity,height;
  opacity: 0;
  &[data-open=true]{
    opacity: 1;
  }
  &[data-alt=true]{
    background: #EDF2FA;
  }

  ${px.portrait}{
    transition-duration: ${duration*1.5}ms;
    &[data-open=false]{
      height: 0;
    }
  }
`;
const Column = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  width: ${346/12}em;

  ${px.portrait}{
    font-size: ${px.mvw(12)};
    display: block;
    width: 100%;
    margin-bottom: ${px.mvw(60)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
    margin-bottom: ${px.tvw(60)};
  }

  ${px.landscape}{
    &:last-child{
      margin-top: ${(1.1*2*40+32)/12}em;
    }
  }
`;
const Content = styled.div`
  margin-top: ${px.vw(208)};
  margin-left: ${px.vw(386)};
  @media (min-aspect-ratio: 18/10) {
    margin-top: ${px.vh(208)};
    margin-left: ${px.vh(386)};
  }
  ${px.portrait}{
    height: calc(100vh - ${px.mvw(80)});
    margin: 0;
    margin-top: ${px.mvw(80)};
    padding: ${px.mvw(16)};
    padding-top: ${px.mvw(121-80)};
    overflow-y: scroll;
  }
  ${px.tablet}{
    height: calc(100vh - ${px.tvw(80)});
    margin-top: ${px.tvw(80)};
    padding: ${px.tvw(16)};
    padding-top: ${px.tvw(121-80)};
  }
`;
const Foldable = styled.div`
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    height: ${(20*(1.1+1)*7-20)/20}em;
    margin-top: 0;
    transition-duration: ${duration}ms;
    overflow-y: hidden;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
`;
const Title = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  font-weight: 600;
  line-height: 1.1;
  text-align: left;
  letter-spacing: -0.04em;
  margin-bottom: ${32/40}em;
  position: relative;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
    border: 1px solid transparent;
    display: inline-block;
    &[data-open=false] + ${Foldable}{
      height: 0;
      margin-top: -2em;
      margin-bottom: ${32/40 + 3}em;
    }
    &:after{
      content: ' ';
      position: absolute;
      height: ${px.mvw(15)};
      width: ${px.mvw(24)};
      right: ${px.mvw(-60)};
      background-image: url(${chevron});
      background-size: 100% 100%;
      top: 1em;
      transform: scaleY(1);
      transition-duration: ${duration}ms;
    }
    &[data-open=true]:after{
      transform: scaleY(-1);
    }
    &[data-alt=true]:after{
      background-image: url(${chevronAlt});
    }
  }
  ${px.tablet}{
    font-size: ${px.tvw(30)};
    &:after{
      height: ${px.tvw(15)};
      width: ${px.tvw(24)};
      right: ${px.tvw(-60)};
    }
  }
`;
const Video = styled(UnstyledVideo)`
  position: absolute;
  width: 60vw;
  left: -30vw;
  bottom: -30vw;
  @media (min-aspect-ratio: 18/10) {
    width: auto;
    height: 100vh;
    left: -50vh;
    bottom: -50vh;
  }
  z-index: -1;
  ${px.portrait}{
    display: none;
  }
`;
const Link = styled(RawLink)`
  display: block;
  cursor: pointer;
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
  font-weight: normal;
  line-height: 1.1;
  text-align: left;
  letter-spacing: -0.01em;
  margin-bottom: 1em;
  color: #98A1B2;
  transition-duration: 100ms;
  transition-property: color;
  &:hover{
    color: #CEDAF2;
    font-weight: 600;
    letter-spacing: -0.02em;
  }
  &[data-alt=true]{
    color: #475266;
    &:hover{
      color: #171C26;
    }
  }

  text-decoration: none;

  &[data-title=true]{
    font-weight: bold;
    text-transform: uppercase;
    color: #CEDAF2;
    &[data-alt=true]{
      color: #171C26;
    }
    &:hover{
      color: #00AEEA;
      letter-spacing: -0.01em;
    }
  }
`;

export default function Burguer({privateInsurance,open}){

  const [zIndex,setZIndex] = useState(-1);
  const [animate,setAnimate] = useState(open);
  const content = useRef();

  const [titleOpen1,setTitleOpen1] = useState(false);
  const [titleOpen2,setTitleOpen2] = useState(false);

  const handleTitleClick1 = useCallback(()=>{
    if(!titleOpen1)
      setTitleOpen2(false);
    setTitleOpen1(!titleOpen1);
  },[titleOpen1,setTitleOpen1,setTitleOpen2]);
  const handleTitleClick2 = useCallback(()=>{
    if(!titleOpen2)
      setTitleOpen1(false);
    setTitleOpen2(!titleOpen2);
  },[titleOpen2,setTitleOpen2,setTitleOpen1]);

  useEffect(()=>{
    if(!content.current)
      return;
    if(open){
      setZIndex(2);
      setAnimate(true);
      content.current.scrollTo(0,0);
      setTitleOpen1(false);
      setTitleOpen2(false);
    }else{
      setTitleOpen1(false);
      setTitleOpen2(false);
      setTimeout(()=>{
        setZIndex(-1);
        setAnimate(false);
      },duration);
    }
  },[open,setZIndex,content]);

  return (
    <Root style={{zIndex: zIndex}} data-alt={privateInsurance} data-open={open}>
      <Video
        enable={animate}
        src={require(`Home/GetInTouch/assets/get in touch on ${privateInsurance?'light':'dark'}.webm`)}
        poster={require(`Home/GetInTouch/assets/get in touch on ${privateInsurance?'light':'dark'}.png`)}
      />
      <Content ref={content}>
        <Column>
          <Title
            onClick={handleTitleClick1}
            data-open={titleOpen1}
            data-privateinsurance={false}
            data-alt={privateInsurance}
          >
            Seguros para <br/>empresas
          </Title>
          <Foldable>
            <Link to="/empresas/acidentes-de-trabalho" data-alt={privateInsurance}>Acidentes de trabalho</Link>
            <Link to="/empresas/saude" data-alt={privateInsurance}>Saúde</Link>
            <Link to="/empresas/automovel" data-alt={privateInsurance}>Automóvel</Link>
            <Link to="/empresas/multirriscos" data-alt={privateInsurance}>Multirriscos</Link>
            <Link to="/empresas/pequeno-negocio" data-alt={privateInsurance}>Pequeno negócio</Link>
            <Link to="/empresas/produtos-empresas" data-alt={privateInsurance}>Produtos empresas</Link>
            <Link to="/empresas/cyber-safety" data-alt={privateInsurance}>Cyber safety</Link>
          </Foldable>
        </Column>
        <Column>
          <Title
            onClick={handleTitleClick2}
            data-open={titleOpen2}
            data-alt={privateInsurance}
          >
            Seguros para <br/>particulares
          </Title>
          <Foldable>
            <Link to="/particular/auto" data-alt={privateInsurance}>Auto</Link>
            <Link to="/particular/habitacao" data-alt={privateInsurance}>Habitação</Link>
            <Link to="/particular/saude" data-alt={privateInsurance}>Saúde</Link>
            <Link to="/particular/pets" data-alt={privateInsurance}>Pets</Link>
            <Link to="/particular/familia" data-alt={privateInsurance}>Família</Link>
            <Link to="/particular/viagens-e-lazer" data-alt={privateInsurance}>Viagens e lazer</Link>
            <Link to="/particular/poupanca" data-alt={privateInsurance}>Poupança</Link>
          </Foldable>
        </Column>
        <Column>
          <Link to="/sinistros-e-assistencia" data-title={true} data-alt={privateInsurance}>Sinistros e assistência</Link>
          <Link to='/sobre-nos' data-title={true} data-alt={privateInsurance}>Sobre nós</Link>
          <Link to='/noticias' data-title={true} data-alt={privateInsurance}>Notícias</Link>
          <Link to='/contactos' data-title={true} data-alt={privateInsurance}>Contactos</Link>
        </Column>
      </Content>
    </Root>
  );
}
