export default {
  privateInsurance: true,
  title: 'Viagem e Lazer',
  description: 'Aproveite o seu tempo livre sem preocupações',
  buttons: [
    'Seguro Acidentes Pessoais',
    'Bicicleta',
    'Viagem ',
    'Caçadores',
  ],
  pages: [
    {
      subtitle: 'Acidentes Pessoais',
      body: [
        'P​orque para nós o mais importante são as pessoas, temos uma oferta de Acidentes Pessoais que lhe oferece uma proteção eficaz e abrangente em caso de acidente. Desde planos​​​​​​​​​​​​​​ simples e acessíveis para a proteção e o apoio financeiro a si e à sua família em caso de acidente, até planos com grande amplitude de proteção, com capitais muito elevados e serviços de apoio ímpares. Para que a vida não pare.​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Acidentes Pessoais 1',
          body: ['O plano Acidentes Pessoais mais simples. Duas coberturas e capitais essenciais.​​'],
          picture: 4,
        },
        {
          title: 'Acidentes Pessoais 2',
          body: ['O plano que complementa a proteção para quando os seguros obrigatórios não cobrem. Capitais elevados e serviços de apoio ímpares.​​'],
          picture: 4,
        },
        {
          title: 'Acidentes Pessoais 3',
          body: ['O plano que vai além dos tempos livres, protege até em trabalho ou em deslocações de carro. Os capitais mais elevados e o mesmo serviço de apoio Fidelidade.​​'],
          picture: 4,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Adequado a situações graves',
          body: ['Os planos AP2e AP3 garantem um capital elevado pensado para acidentes com consequências graves. Disponibiliza um conjunto de coberturas que vão muito além do simples pagamento de uma indemnização ou reembolso de despesas.​​​'],
        },
        {
          title: 'Consigo desde o 1º momento',
          body: ['Em caso de acidente, os planos AP2 e AP3 disponibilizam o apoio e a orientação necessários desde o primeiro momento, assegurando o encaminhamento para a rede de prestadores da Fidelidade, para que não tenha que antecipar o pagamento das despesas médicas.​​​'],
        },
        {
          title: 'Compromisso com o futuro',
          body: ['Porque o pagamento de uma indemnização pode não ser suficiente em caso de um acidente grave, os planos AP2 e AP3 têm associado o Compromisso WeCare ​- um projeto que visa apoiar a reabilitação física e a reintegração social, profissional e familiar. Para que a vida nunca pare.​​'],
        },
      ],
    },
    {
      subtitle: 'Bicicleta',
      body: [
        'Pedale connosco e esteja sempre protegido. Só assim poderá desfrutar de todos os seus passeios de lazer ou da deslocação para o seu trabalho.',
      ],
      boxes:[
        {
          title: 'Bicicleta',
          body: [
            'Pedale connosco e esteja sempre protegido.',
            '',
            '• Responsabilidade Civíl - 7.290.000€',
            '• Proteção ao Condutor - Morte ou Invalidez Permanente - 50.000€',
            '• Proteção ao Condutor - Despesas de Tratamento - 5.000€',
          ],
          picture: 4,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Maior proteção para o condutor',
          body: ['Porqu​e as pessoas são a nossa prioridade, o seguro Bicicleta proporciona maior proteção ao condutor através da cobertura de Acidentes Pessoais, para os imprevistos que lhe possam acontecer, disponibilizando um capital de 50.000€ para danos ou lesões corporais.'],
        },
        {
          title: 'Proteção a terceiros',
          body: ['O seguro Bicicleta tem um capital de 7.290.000€ de Responsabilidade Civil que assegura o pagamento de danos materiais e/ou pessoais causados a terceiros.​'],
        },
        {
          title: 'Preço',
          body: ['Porque apresenta o preço fixo de 45,88€*, por bicicleta segura e sem necessidade de simulação. Preço válido até​ 31-01-2020.​​​​'],
        },
      ],
    },
    {
      subtitle: 'Viagem ',
      body: [
        'Basta indicar o destino e a duração da viagem, e escolher o plano e capital adequado às suas necessidades. ',
        'Em trabalho ou de férias, em qualquer parte do mundo, vai encontrar a proteção que precisa.',
        'Pode dispor de um alargado conjunto de coberturas e ainda assistência às pessoas.',
      ],
      boxes:[
        {
          title: 'Portugal',
          body: [
            'Para viagens em Portugal',
            '',
            '• 1 Plano - Portugal',
            '• 4 Níveis de Capital',
            '• Assistência às Pessoas - Nível Portugal',
          ],
          picture: 4,
        },
        {
          title: 'Estrangeiro',
          body: [
            'Para viagens fora de Portugal',
            '',
            '• 2 Planos - Tour e VIP',
            '• 6 Níveis de Capital',
            '• Assistência às Pessoas - Nível Estrangeiro',
          ],
          picture: 4,
        },
        {
          title: 'Neve',
          body: [
            'Para quem pratica desportos de neve',
            '',
            '• 2 Planos - Portugal Neve e Estrangeiro Neve',
            '• 6 Níveis de Capital',
            '• Assistência às Pessoas - Nível Neve Portugal ou Estrangeiro',
          ],
          picture: 4,
        },
      ],
      title2: '',
      boxes2:[],
    },
    {
      subtitle: 'Caçadores',
      body: [
        'O seguro Caçadores é um seguro que se destina a salvaguardar os principais riscos que envolvem a prática da caça e a posse ou detenção de armas de caça.​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​',
        'Com diferentes tipos de proteção, cabe-lhe a si escolher o Plano que melhor se adequa às suas necessidades.',
      ],
      boxes:[
        {
          title: 'Obrigatório por Lei',
          body: ['Permite cumprir a obrigação legal de celebrar o Seguro de Responsabilidade Civil do Caçador, por danos causados a terceiros, durante a prática da caça.​'],
          picture: 4,
        },
        {
          title: 'Calibre I',
          body: ['Durante a prática da caça, o caçador fica protegido por danos causados a terceiros, bem como em danos nas suas armas. Garante assistência ao caçador em caso de doença ou acidente, assim como proteção jurídica.​'],
          picture: 4,
        },
        {
          title: 'Calibre II',
          body: ['Para além de cumprir a obrigação legal com o Seguro de Responsabilidade Civil do Caçador, garante capitais de acidentes pessoais em caso de morte ou invalidez permanente, despesas de tratamento e despesas de funeral.​'],
          picture: 4,
        },
        {
          title: 'Calibre III',
          body: ['Plano mais completo, com um leque de coberturas e capitais alargado, com destaque à extensão dos acidentes pessoais aos acompanhantes do caçador e garante ainda os acidentes com os cães de caça.​'],
          picture: 4,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Obrigação Legal',
          body: ['Os Seguros de Responsabilidade Civil do Caçador e de Responsabilidade Civil dos Portadores de Armas são obrigatórios por lei e liberta-o das preocupações relativas ao pagamento de indemnizações a terceiros por danos corporais ou materiais que lhe possa causar, durante a prática da caça.​'],
        },
        {
          title: 'Flexibilidade e inovação',
          body: [
            'Conjunto alargado de soluções em que o caçador pode escolher uma combinação de coberturas e capitais que melhor se adaptem às suas necessidades específicas.​',
            'Proteção em consequência de sinistros ocorridos no percurso de ida e regresso do local de caça e nos acidentes pessoais dos seus que o acompanham na prática da caça.​',
          ],
        },
        {
          title: 'Segurança',
          body: ['O seguro Caçadores protege-o a si em caso de acidente ocorrido enquanto caça e aos seus que o acompanham nessa atividade, através do recebimento de um capital em caso de morte ou invalidez permanente, reembolso de despesas de tratamento e despesas com o funeral.​'],
        },
        {
          title: 'Cães e armas',
          body: ['Pode garantir, em caso de acidente com os seus cães de caça, e/ou o pagamento da reparação ou substituição das armas de caça em consequência de furto, roubo, quebra e explosão, ocorridos no local da caça e durante o seu exercício, em função do plano contratado.​'],
        },
      ],
    },
  ]
};
