import React from 'react';
import Link from 'Link';

export default <>
  <h1>Política de Cookies</h1>
  A empresa Portugal Seguro - Mediação de Seguros, Lda (doravante "Portugal Seguro”) pretende proporcionar um acesso seguro, personalizado e eficiente aos utilizadores dos seus websites.<br/>
  Para tal, utilizamos ficheiros informáticos de reduzida dimensão designados de cookies que armazenamos no seu dispositivo (computador, tablet, telemóvel, ou outro dispositivo móvel) de modo a podermos identificá-lo em acessos futuros, recordar as suas ações e preferências e melhorar a sua experiência de navegação.<br/>
  <br/>
  Ao navegar no nosso website concorda com a utilização dos cookies nos termos definidos nesta Política de Cookies. Quaisquer alterações à presente Política serão publicadas nesta página, pelo que se recomenda que a mesma seja visitada regularmente.<br/>
  <br/>
  <strong>Última atualização em 17 de julho 2020</strong><br/>
  <br/>
  <br/>
  <br/>
  <h2>O que são cookies?</h2>
  <br/>
  Os cookies são ficheiros informáticos (software) de reduzida dimensão que são armazenados no dispositivo do utilizador, através do seu navegador de internet , por ocasião do acesso ao website.<br/>
  <br/>
  Os cookies incluem um ou mais identificadores únicos que são enviados para o navegador de internet ou outra parte do dispositivo do cliente ou utilizador e aí ficam instalados. Cada website e aplicação dispõem da capacidade de enviar os seus próprios cookies para o navegador de internet utilizado, bem como de lhes aceder, caso as preferências do navegador de internet do utilizador assim o permitam. Para proteção da sua privacidade, o seu navegador de internet só permite que um website aceda aos cookies que já tenha enviado para si, ficando-lhe vedado o acesso aos cookies que tenham sido enviados por outros sites.<br/>
  <br/>
  Para saber mais sobre o que são cookies, bem como de que modo pode geri-los ou impedir a sua utilização no seu dispositivo, visite www.allaboutcookies.org, no qual poderá encontrar informações sobre como modificar as configurações aplicáveis ao navegador de internet que utilizar.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Para que servem os cookies?</h2>
  <br/>
  A colocação de cookies torna a utilização do site mais simples e eficiente e ajudará o site a reconhecer o seu dispositivo na próxima vez que o visitar.<br/>
  <br/>
  Os cookies utilizados pela Portugal Seguro retêm, por exemplo, informação relacionada com códigos identificadores do dispositivo, e informação de sessão para garantir a segurança e experiência de navegação. Assim, como poderá ver mais abaixo, os cookies podem facilitar a sua próxima visita e tornar os sites mais úteis para si.<br/>
  <br/>
  Em regra, para instalar determinados cookies no seu dispositivo necessitamos do seu consentimento prévio, razão pela qual existe um aviso sobre este assunto no [cabeçalho] do site.<br/>
  <br/>
  A qualquer momento, o utilizador pode, através do seu navegador de internet, decidir ser notificado sobre a receção de cookies, bloquear a respetiva entrada no seu dispositivo ou remover os cookies previamente instalados.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Porque utilizamos os cookies?</h2>
  <br/>
  A utilização de cookies na Internet é usual e não prejudica os dispositivos dos utilizadores. Os cookies executam diversas funções, nomeadamente no auxílio dos responsáveis do site a perceber o modo como este é utilizado, facilitando a respetiva navegação, guardando as suas preferências e, de modo geral, melhorando a sua experiência de utilização, servindo ainda para garantir que o site lhe mostra conteúdo relevante.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Que tipo de cookies utilizamos?</h2>
  <br/>
  Os utilizadores podem configurar os seus dispositivos e os navegadores de internet que utilizam para aceitarem todos ou alguns cookies, para os notificarem sempre que um cookie é emitido, ou para nunca receberem cookies. Sendo cada navegador diferente, verifique o menu de ajuda do seu navegador para aprender a alterar as suas preferências em matéria de cookies.<br/>
  <br/>
  <strong>Relativamente às suas funcionalidades e duração, os cookies utilizados pela Portugal Seguro podem ser:</strong><br/>
  <br/>
  <strong>1. Cookies de sessão</strong> — são cookies temporários que permanecem no seu navegador de internet até sair do site. A informação que mantêm permite gerir, de forma segura, as diversas operações realizadas por si durante uma sessão de acesso ao nosso site, permitindo-nos identificar problemas e fornecer uma melhor experiência de navegação.<br/>
  <br/>
  <strong>2. Cookies persistentes</strong> — São cookies que ficam armazenados no navegador de internet do seu dispositivo durante um período de tempo definido e que são acedidos e utilizados sempre que faz uma nova visita ao site.<br/>
  <br/>
  <strong>Relativamente à função, os cookies utilizados pela Portugal Seguro são:</strong><br/>
  <br/>
  <strong>1. Cookies essenciais</strong> — Alguns cookies são essenciais para aceder a áreas específicas do nosso site. Permitem a navegação no site e a utilização das suas aplicações, tal como aceder a áreas seguras do site através de login. Sem estes cookies, determinados serviços não podem ser prestados. De um modo geral, não é necessário o consentimento do utilizador para instalar cookies essenciais no seu dispositivo quando este acede ao site.<br/>
  <br/>
  <strong>2. Cookies analíticos</strong> — São utilizados anonimamente para efeitos de criação e análise de estatísticas, no sentido de melhorar o funcionamento do site.<br/>
  <br/>
  <strong>3. Cookies de funcionalidade</strong> — Guardam as preferências do utilizador relativamente à utilização do site, para que não seja necessário voltar a configurar o site cada vez que o visita.<br/>
  <br/>
  O nosso site poderá conter links para outros sítios de Internet ou aplicações, incluindo dos nossos parceiros. Caso aceda a um desses links ou aplicações, por favor note que cada um terá a sua própria política de cookies e não nos responsabilizamos por essas políticas. Por favor leia as políticas de cookies de outros sítios de Internet ou aplicações antes de utilizar os mesmos.<br/>
  <br/>
  <br/>
  <br/>
  <h2>O uso de cookies pode ser bloqueado ou limitado?</h2>
  <br/>
  Como referido, os cookies contribuem para uma melhor experiência de utilização do nosso site. Depois de autorizar o uso de cookies, poderá sempre desativar parte ou a totalidade dos cookies. Caso desactive os cookies acima identificados como essenciais, poderá ver-se impedido de utilizar o nosso website. Caso desactive os cookies de funcionalidade, poderá ver-se impedido de aceder às funções por si proporcionadas.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Como é que os cookies devem ser geridos?</h2>
  <br/>
  Todos os navegadores de internet permitem ao utilizador aceitar, recusar ou apagar cookies, através da gestão das definições no navegador de internet.<br/>
  Caso desative os cookies pode impedir que alguns serviços da internet funcionem corretamente, afetando, parcial ou totalmente, a navegação e funcionalidades do site.<br/>
  <br/>
  <br/>
  <br/>
  <h2>Contactos</h2>
  <br/>
  Para quaisquer informações adicionais ou para exercer os seus direitos, por favor contacte-nos em qualquer momento através do email <Link external={true} newtab={false} to="mailto:geral@portugalseguro.pt">geral@portugalseguro.pt</Link><br/>
  <br/>
  <br/>
  <br/>
  <br/>
</>;
