import React,{useCallback,useState} from 'react';
import styled from 'styled-components';
import serialize from 'form-serialize';

import px from 'px.js';

import facebook from './assets/facebook.svg';
import instagram from './assets/instagram.svg';
import linkedin from './assets/linkedin.svg';

import _RawLink from 'Link';
const RawLink = styled(_RawLink)`
  text-decoration: none;
`;
const ExternalLink = styled.a.attrs({
  rel: 'noopener noreferrer',
})`
  text-decoration: none;
`;

const Root = styled.div`
  background: #1F2633;
  border: 1px solid transparent;
  position: relative;

  &[data-snap=true]{
    scroll-snap-align: end;
  }

  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }

  padding-left: 1em;
  padding-right: 1em;
  padding-top: 2em;


  ${px.portrait}{
    font-size: ${px.vh(16)};
    padding: 1em;
    padding-top: ${80/16}em;
  }

`;

const Grid = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${24/12}em;
  ${px.portrait}{
    grid-template-columns: 1fr;
    grid-auto-flow: unset;
    grid-row-gap: ${24/12}em;
    & > [data-trailer]{
      order: 3;
      padding-top: ${24/12}em;
    }
  }
`;

const Column = styled.div`
  display: block;
  vertical-align: top;
  /* width: ${px.vw(336)};
  @media (min-aspect-ratio: 18/10) {
    width: ${px.vh(336)};
  } */
`;

const Title = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(12)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: 0.04em;
  color: #CEDAF2;
  margin-bottom: ${24/14}em;
  text-transform: uppercase;

  &[data-link=true]{
    text-decoration: none;
    cursor: pointer;
    &:hover{
      color: #00AEEA;
    }
  }
`;
const Link = styled(RawLink)`
  display: block;
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: 0.00em;
  color: #98A1B2;
  margin-bottom: ${12/14}em;

  text-decoration: none;

  & ,& > *{
    color: #98A1B2;
    &:not([data-notclickable]){
      cursor: pointer;
      &:hover{
        color: #CEDAF2;
      }
    }
  }
`;
const LinkHighlight = styled(Link)`
  font-weight: bold;
  color: #CEDAF2;
  margin-bottom: ${4/14}em;
`;
const Separator = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(6)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(6)};
  }
  height: 1em;
  &[data-half=true]{
    height: 0.5em;
  }
`;

const TextField = styled(
  p => <input type="text" {...p}/>
)`
  display: block;
  border: none;
  outline: none;
  background: transparent;

  &:placeholder{
    color: #98A1B2B3;
    &[data-alt=true]{
      color: #475266B3;
    }
  }

  color: #CEDAF2;
  border-bottom: 1px solid #CEDAF2;
  &:focus{
    border-bottom: 1px solid #00AEEA;
  }
  &[data-alt=true]{
    color: #171C26;
    border-bottom: 1px solid #171C26;
    &:focus{
      border-bottom: 1px solid #00AEEA;
    }
  }

  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }

  width:  ${322/20}em;
  height:  ${53/20}em;
  margin-top:  ${16/20}em;
  letter-spacing: -0.01em;
  padding-left: 0;

  ${px.portrait}{
    font-size: ${px.mvw(20)};
    width:  100%;
    height:  ${41/20}em;
    margin-top:  ${28/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
    max-width: 80%;
  }
`;

const SendButton = styled(
  ({children,...p}) => <input type="submit" {...p} value={children} />
)`
  display: block;
  appearance: none;
  outline: none;
  border: none;

  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  font-weight: 600;
  line-height: ${61/16};
  text-align: center;
  letter-spacing: -0.00em;
  color: #171C26;
  &[data-alt=true]{
    color: #CEDAF2;
  }

  cursor: pointer;
  width: ${322/16}em;
  height: ${61/16}em;
  margin-top: ${24/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 100%;
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
    width: 80%;
  }

  background: #00AEEA;
  &:hover{
    background: #00BEFF;
  }
  &:ative{
    background: #00A5DE;
  }
`;

const Terms = styled.div`
  font-size: ${px.vw(10)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(10)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(10)};
  }
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
  opacity: 0.7;
  margin-top: ${10/10}em;
  max-width: ${322/10}em;

  ${px.tablet}{
    font-size: ${px.tvw(10)};
    max-width: 80%;
  }
`;

const SocialWrapper = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  margin-top: ${108/12}em;
  ${px.portrait}{
    font-size: ${px.mvw(12)};
    margin-top: ${40/12}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
  }
`;
const SocialLink = styled(
  ({src,...p}) => (
    <a
      {...p}
      rel="noopener noreferrer"
      target="_blank"
    ><img src={src}/></a>
  )
)`
  font-size: ${px.vw(32)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(32)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(32)}
  }
  ${px.tablet}{
    font-size: ${px.tvw(32)};
  }

  display: inline-block;
  & img{
    height: 1em;
    width: 1em;
  }
  margin-right: 0.5em;

  cursor: pointer;
`;

const Legal = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }

  border-top: 1px solid #98A1B280;
  padding-top: ${40/20}em;
  padding-bottom: ${40/20}em;
  margin-top: ${60/20}em;

  ${px.portrait}{
    font-size: ${px.mvw(20)};
    padding-bottom: ${20/20}em;
    & [data-desktop]{
      display: none;
    }
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
  ${px.landscape}{
    & ${Link}:nth-child(2){
      float: right;
    }
    & ${Link}[data-mobile]{
      display: none;
    }
  }

  & ${Link}{
    vertical-align: top;
    display: inline-block;
  }
`;

const en = false;

const InlineLink = styled(RawLink)`
  color: inherit;
  text-decoration: underline;
`;


const NewsletterSent = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  background: #1F2633;

  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: 0.00em;
  color: #98A1B2;

  transition-duration: 1s;
  transition-property: opacity;
  opacity: 0;
  pointer-events: none;

  &[data-enable=true]{
    pointer-events: initial;
    opacity: 1;
  }
`;

export default function Footer({path}){

  const [sent,setSent] = useState(false);

  const submit = useCallback((event)=>{
    event.preventDefault();

    const form = event.target.form;
    const formData = serialize(form);
    setTimeout(()=>form.reset(),2000);

    setSent(true);
    setTimeout(()=>setSent(false),5000);

    window.grecaptcha.ready(()=>{
      window.grecaptcha.execute(
        '6LeU3rMZAAAAAIDXju2XxOprKi9NcBgjezM08DZp',
        // '6LfWN7QZAAAAAFFHUEhNj7WeH60gmXLt3P_7_cRT',
        {action: 'submit'}
      ).then((token)=>{
    		try{
    			var request = new XMLHttpRequest();
    			request.open("POST", "https://us-central1-host-portugal-seguro.cloudfunctions.net/newsletterSubscribe");
    			// request.open("POST", "http://localhost:5001/host-portugal-seguro/us-central1/newsletterSubscribe");
    			request.setRequestHeader('Access-Control-Allow-Headers', '*');
    			request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    			request.onload = function(){
    				if(this.status !== 204){
    					// failed
    				}else{
    					// ok
    				}
    			};
    			request.onerror = function(){
    				// failed
    			}
          request.send(formData+`&recaptchatoken=${token}`);
    		}catch(error){
          // failed
    		}
      }).catch(e => {
        // failed
      });
    });

  },[]);

  return (
    <Root id="pagefooter" data-snap={path==='/'}>
      <Grid>
        <Column data-trailer>
          <Title>Portugal Seguro</Title>
          <Link external={true}
            to="https://www.google.com/maps/place/Portugal+Seguro+-+Media%C3%A7%C3%A3o+de+Seguros,+Lda./@41.2783544,-8.3744397,17z/data=!3m1!4b1!4m5!3m4!1s0xd248cc04e8b7b95:0x3cc2ba7f34627674!8m2!3d41.2783544!4d-8.372251"
          >Praceta Santa Eulália nº 5<br/> 4590-530 Paços de Ferreira</Link>
          <Link external={true} newtab={false}
            to="tel:+351915207888"
          >+351 915 207 888</Link>
          <Link external={true} newtab={false}
            to="mailto:geral@portugalseguro.pt"
          >geral@portugalseguro.pt</Link>
          <Separator/><Separator/>
          <LinkHighlight data-notclickable>2ª feira – 6ª feira</LinkHighlight>
          <Link data-notclickable>09:30 – 12:30 e 14:00 – 19:00</Link>
          <SocialWrapper>
            <SocialLink src={facebook} href="https://www.facebook.com/PortugalSeguro.pt"/>
            <SocialLink src={instagram} href="https://www.instagram.com/portugalseguro/"/>
            <SocialLink src={linkedin} href="https://www.linkedin.com/company/42962217"/>
          </SocialWrapper>
        </Column>
        <Column>
          <Title>Seguros para empresas</Title>
          <Link to="/empresas/acidentes-de-trabalho">Acidentes de trabalho</Link>
          <Link to="/empresas/saude">Saúde</Link>
          <Link to="/empresas/automovel">Automóvel</Link>
          <Link to="/empresas/multirriscos">Multirriscos</Link>
          <Link to="/empresas/pequeno-negocio">Pequeno negócio</Link>
          <Link to="/empresas/produtos-empresas">Produtos empresas</Link>
          <Link to="/empresas/cyber-safety">Cyber safety</Link>
        </Column>
        <Column>
          <Title>Seguros para particulares</Title>
          <Link to="/particular/auto">Auto</Link>
          <Link to="/particular/habitacao">Habitação</Link>
          <Link to="/particular/saude">Saúde</Link>
          <Link to="/particular/pets">Pets</Link>
          <Link to="/particular/familia">Família</Link>
          <Link to="/particular/viagens-e-lazer">Viagens e lazer</Link>
          <Link to="/particular/poupanca">Poupança</Link>
          <Separator/><Separator/>
          <Separator/><Separator/>
          <Separator data-half={true}/>
          <RawLink to='/sinistros-e-assistencia'><Title data-link={true}>Sinistros e assistência</Title></RawLink>
          <RawLink to='/sobre-nos'><Title data-link={true}>Sobre nós</Title></RawLink>
        </Column>
        <Column style={{float: 'right'}}>
          <Title>Newsletter</Title>
          <form style={{position:'relative'}}>
            <NewsletterSent data-enable={sent}>
              Registo realizado com sucesso. Obrigado.
            </NewsletterSent>
            <TextField placeholder="E-mail" name="email"/>
            <SendButton onClick={submit}>{ en ?
                'Subscribe to a newsletter'
              : 'Subscrever newsletter'
            }</SendButton>
            <Terms>{ en ?
                'By clicking on the button I consent to my data being used for contact purposes and I agree with the privacy policy'
              : <>
                  Ao clicar no botão, concordo que os meus dados sejam tratados de acordo com a <span/>
                  <InlineLink to="/privacidade">Política de Privacidade</InlineLink>.
                  <div style={{height: '0.9em'}}/>
                  Este site é protegido por reCAPTCHA e aplicam-se a <span/>
                  <InlineLink external={true} to="https://policies.google.com/privacy">Política de Privacidade</InlineLink> e os <span/>
                  <InlineLink external={true} to="https://policies.google.com/terms">Termos de Serviço</InlineLink> da Google.
                </>
            }</Terms>
          </form>
        </Column>
      </Grid>
      <Legal>
        <Link data-notclickable>
          <RawLink to="/privacidade">
            Política de Privacidade
          </RawLink> / <RawLink to="/cookies">
            Política de Cookies
          </RawLink> / <ExternalLink href="https://www.livroreclamacoes.pt/inicio">
            Livro de Reclamações
          </ExternalLink>
        </Link>
        <Link data-notclickable>Copyright © 2020 Portugal Seguro. Todos os direitos reservados. <span data-desktop>Desenvolvido por Webkorp.</span></Link>
        <Link data-notclickable data-mobile>Desenvolvido por Webkorp.</Link>
      </Legal>
    </Root>
  );
}
