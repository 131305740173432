
const vantagensComuns = [
  {
    title: 'Acompanhamento e reintegração',
    body: ['O Compromisso WeCare tem como objetivo tratar da reintegração das pessoas que sofreram acidentes graves, de automóvel e acidentes de trabalho, de uma forma completa, em todos os aspetos da sua vida - social, profissional e familiar.'],
  },
  {
    title: 'Assistência recomendada',
    body: ['Acesso a rede clínica privada, disponível em qualquer ponto do País, incluindo Regiões Autónomas dos Açores e da Madeira, que permite aceder a um corpo clínico com vasta experiência em todas as áreas e Hospitais especializados por todo o país.​​'],
  },
]

export default {
  privateInsurance: false,
  title: 'Acidentes de Trabalho',
  description: 'A proteção para si e para os seu colaboradores',
  buttons: [
    'Acidentes de Trabalho Conta de Outrem',
    'Trabalhador Independente',
  ],
  pages: [
    {
      subtitle: 'Acidentes de Trabalho Conta de Outrem',
      body: [
        'O seguro de Acidentes de Trabalho Conta de Outrem é obrigatório e destina-se a todas as Entidades Empregadoras, Singulares ou Coletivas, com trabalhadores efetivos ou a prazo, a tempo inteiro ou parcial.​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Acidentes de Trabalho Conta de Outrem',
          body: ['Este seguro providencia assistência médica especializada a todos os seus Colaboradores em caso de acidente de trabalho e protege a sua empresa contra as responsabilidades e despesas daí decorrentes, incluindo casos de incapacidade temporária ou permanente (absoluta ou parcial) e morte.'],
          picture: 4,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        ...vantagensComuns,
        {
          title: 'Segurança e saúde no trabalho',
          body: ['Acesso a serviços de Segurança e Saúde no Trabalho, através da empresa Safemode – Proteção de Pessoas, Património e Ambiente.​'],
        },
      ],
    },
    {
      subtitle: 'Trabalhador Independente',
      body: [
        'O seguro de Acidentes de Trabalho - Trabalhador Independente é obrigatório. Destina-se a todos os profissionais que exerçam uma atividade por conta própria, mesmo que exerçam, simultaneamente, uma atividade por conta de outrem.​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Trabalhador Independente',
          body: ['Este seguro providencia assistência médica especializada em caso de acidente de trabalho, protegendo-o contra as responsabilidades e despesas daí decorrentes. Permite-lhe, ainda, contratar a cobertura facultativa de Acidentes Pessoais que o protegerá em caso de acidentes ocorridos durante as suas atividades extraprofissionais.'],
          picture: 4,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        ...vantagensComuns,
        {
          title: 'Proteção 24 horas',
          body: ['Ao contratar as coberturas de Acidentes Pessoais, fica também salvaguardado em caso de acidente extraprofissional, garantindo proteção em todos os momentos do seu dia.'],
        },
      ],
    },
  ]
};
