import React,{useRef,useState,useEffect} from 'react';
import styled from 'styled-components';
import elementWithinViewport from 'element-within-viewport';

import px from 'px.js';

import RawLink from 'Link';
import Video from 'Video';

import arrow from 'Services/assets/arrow.svg';

const Root = styled.div`
  background: #171C26;
  min-height: 100vh;
  border: 1px solid transparent;
  position: relative;
  scroll-snap-align: start;

  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }

  padding-left: ${40/20}em;
  padding-right: ${40/20}em;
  padding-top: calc(${160/20}em + ${px.halfVh(160)});

  ${px.portrait}{
    font-size: ${px.mvw(20)};
    padding-left: ${16/20}em;
    padding-right: ${16/20}em;
    padding-top: ${120/20}em;
  }

  &[data-privateinsurance=true]{
    background: #EDF2FA;
  }

`;

const Headline = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
    br{
      display: none;
    }
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-privateinsurance=true]{
    color: #171C26;
  }
`;

const Bottomline = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top:  ${24/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(14)};
    margin-top: ${20/14}em;
    br{
      display: none;
    }
  }
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-privateinsurance=true]{
    color: #475266;
  }
`;

const Boxes = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top: ${60/16}em;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${24/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    grid-template-columns: 100%;
    grid-auto-flow: unset;
    grid-row-gap: ${16/16}em;
  }
`;
const Box = styled.div`
  display: block;
  position: relative;
  background: #1F2633;

  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  padding: ${32/16}em;
  padding-bottom: ${68/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    padding: ${24/16}em;
    padding-bottom: ${68/16}em;
  }

  overflow: hidden;
`;
const Arrow = styled(
  p => <img alt="" src={arrow} {...p}/>
)`
`;

const BoxPicture = styled(Video)`
  font-size: ${px.vw(16)};
  position: relative;
  width: 50%;
  left: ${-24/16}em;
  top: ${-16/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 70%;
  }
`;
const BoxTitle = styled.div`
  position: relative;
  z-index: 1;
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.01em;
  color: #CEDAF2;
  /* margin-top: ${-56/20}em; */
  margin-bottom: ${16/20}em;
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    margin-bottom: ${8/20}em;
  }
`;
const BoxText = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B3;
`;
const BoxLink = styled(RawLink)`
  display: block;
  position: absolute;
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  left: ${32/14}em;
  bottom: ${32/14}em;

  ${px.portrait}{
    font-size: ${px.mvw(14)};
    left: ${24/14}em;
  }

  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: 0.00em;
  color: #00AEEA;
  &:hover{
    color: #00BEFF;
  }
  & img{
    height: 0.57em;
    margin-left: 0.57em;
    transition-duration: 300ms;
  }

  cursor: pointer;
  &:hover img{
    margin-left: 1em;
  }
  text-decoration: none;
`;

const en = false;

export default function Services({privateInsurance,animate}){

  const root = useRef();
  const [animationVisible,setAnimationVisible] = useState(true);

  useEffect(()=>{
    if(!root.current)
      return;
    elementWithinViewport(root.current, {
      onEnter: e => { setAnimationVisible(true) },
      onExit: e => { setAnimationVisible(false) }
    });
  },[root,setAnimationVisible]);

  return (
    <Root ref={root} data-privateinsurance={privateInsurance}>
      <Headline data-privateinsurance={privateInsurance}>{!privateInsurance ? (en ?
        <span>We think about the safety<br/> of each organization</span> :
        <span>Pensamos na segurança<br/> de cada organização</span>
      ):(en ?
        <span>We think about the safety<br/> of each person</span> :
        <>Pensamos na segurança de<br/> cada pessoa</>

      )}</Headline>
      <Bottomline data-privateinsurance={privateInsurance}>{!privateInsurance ? (en ?
        <span>At Portugal Seguro we look at organizations in an integral way. We<br/> know that all areas require customized solutions in order to ensure<br/> the best functioning. So we know how to help.</span> :
        <span>
        Na Portugal Seguro, olhamos para as organizações de maneira integral. Sabemos que
        <br/>todas as áreas exigem soluções personalizadas para garantir o melhor funcionamento.
        <br/>Sabemos como ajudar.
        </span>
      ):(en ?
        <span>At Portugal Seguro we deal with people every day.<br/> And it is with them, with their families and with<br/> society that we are committed</span> :
        <>Na Portugal Seguro, interagimos com as pessoas todos os dias. E é com cada um, <br/>
com suas famílias e com a sociedade que estamos comprometidos.
</>
      )}</Bottomline>
      <Boxes>
        <Box>
          <BoxPicture
            enable={animationVisible && animate}
            src={require(`Services/assets/${!privateInsurance?5:2}.webm`)}
            poster={require(`Services/assets/${!privateInsurance?5:2}.png`)}
          />
          <BoxTitle>{!privateInsurance ? (
            'Saúde'
          ):(
            'Auto'
          )}</BoxTitle>
          <BoxText>{!privateInsurance ? (
            'Escolha a solução de saúde que melhor se adequa a si ou às pessoas da sua organização.'
          ):(
            'Soluções automóvel para conduzir seguro na estrada e na vida.'
          )}</BoxText>
          <BoxLink to={!privateInsurance?'/empresas/saude':'/particular/auto'}>{en?'Explore':'Saber mais'}<Arrow/></BoxLink>
        </Box>
        <Box>
          <BoxPicture
            enable={animationVisible && animate}
            src={require(`Services/assets/${!privateInsurance?2:1}.webm`)}
            poster={require(`Services/assets/${!privateInsurance?2:1}.png`)}
          />
          <BoxTitle>{!privateInsurance ? (
            'Automóvel'
          ):(
            'Habitação'
          )}</BoxTitle>
          <BoxText>{!privateInsurance ? (
            'Cobertura inovadora no mercado, a única que realmente protege o condutor.'
          ):(
            'Proteção para a sua habitação, recheio, agregado familiar e responsabilidade civil.'
          )}</BoxText>
          <BoxLink to={!privateInsurance?'/empresas/automovel':'/particular/habitacao'}>{en?'Explore':'Saber mais'}<Arrow/></BoxLink>
        </Box>
        <Box>
          <BoxPicture
            enable={animationVisible && animate}
            src={require(`Services/assets/${!privateInsurance?3:7}.webm`)}
            poster={require(`Services/assets/${!privateInsurance?3:7}.png`)}
          />
          <BoxTitle>{!privateInsurance ? (
            'Cyber Safety'
          ):(
            'Família'
          )}</BoxTitle>
          <BoxText>{!privateInsurance ? (
            'Saiba como é que os serviços e coberturas do seguro Cyber Safety podem proteger o seu negócio.'
          ):(
            'Proteja o que é mais importante para si - a sua família.'
          )}</BoxText>
          <BoxLink to={!privateInsurance?'/empresas/cyber-safety':'/particular/familia'}>{en?'Explore':'Saber mais'}<Arrow/></BoxLink>
        </Box>
      </Boxes>
    </Root>
  );
}
