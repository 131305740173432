import React,{useState,useCallback,useRef,useEffect} from 'react';
import styled from 'styled-components';

import SwipeListener from 'swipe-listener';

import px from 'px.js';

import doubleQuote from './assets/doublequote.svg';
import arrow from './assets/arrow.svg';
import doubleQuoteAlt from './assets/doublequote-alt.svg';
import arrowAlt from './assets/arrow-alt.svg';
import avatar from './assets/avatar.png';

const Root = styled.div`
  background: #171C26;
  &[data-alt=true]{
    background: #EDF2FA;
  }
  height: 100vh;
  width: 98vw;
  border: 1px solid transparent;
  position: relative;
  scroll-snap-align: start;

  padding-top: ${px.vw(200)};
  @media (min-aspect-ratio: 18/10) {
    padding-top: calc(${px.vh(200)});
  }
  ${px.portrait}{
    padding: ${px.mvw(16)};
    padding-top: ${px.mvw(100)};
    width: 100vw;
    height: ${px.mvw(740)};
  }

  ${px.tablet}{
    height: ${px.tvw(880)};
  }
`;

const Text = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  max-width: ${864/40}em;
  margin-top: ${40/40}em;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  letter-spacing: -0.02em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
  margin-left: auto;
  margin-right: auto;
  ${px.portrait}{
    font-size: ${px.mvw(30)};
    text-align: left;
    margin-left: 0;
    margin-top: ${24/30}em;
    max-width: ${px.mvw(375 - 16*2)};
    br{
      display: none;
    }
  }
  ${px.tablet}{
    font-size: ${px.tvw(30)};
    max-width: ${px.tvw(375)};
  }
`;
const Person = styled.div`
  position: absolute;
  width: 100%;
  top: 30vh;

  ${px.portrait}{
    position: absolute;
    top: auto;
    bottom: ${px.mvw(48)};
  }
`;
const PersonAvatar = styled.img`
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width:  ${px.vw(60)};
  height:  ${px.vw(60)};
  margin-top: ${px.vw(118)};
  @media (min-aspect-ratio: 18/10) {
    width:  ${px.vh(60)};
    height:  ${px.vh(60)};
    margin-top: ${px.vh(118)};
  }
  ${px.portrait}{
    width:  ${px.mvw(60)};
    height:  ${px.mvw(60)};
    margin-top: ${px.mvw(48)};
    margin-left: 0;
  }
`;
const PersonName = styled.div`
  font-size: ${px.vw(20)};
  text-align: center;
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    text-align: left;
  }
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.01em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
  margin-top: ${16/20}em;
`;
const PersonDescription = styled.div`
  font-size: ${px.vw(16)};
  text-align: center;
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    text-align: left;
  }
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.00em;
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
  margin-top: ${4/16}em;
`;



const Arrow = styled(({alt,flip,...p}) => <div {...p}/>)`
  position: absolute;
  width:  ${px.vw(80)};
  height:  ${px.vw(18)};
  top:  ${px.vw(396)};
  left:  ${p => px.vw(p.flip ? 'none' : 40)};
  right:  ${p => px.vw(p.flip ? 40 : 'none')};
  @media (min-aspect-ratio: 18/10) {
    width:  ${px.vh(80)};
    height:  ${px.vh(18)};
    top:  ${px.vh(396)};
    left:  ${p => px.vh(p.flip ? 'none' : 40)};
    right:  ${p => px.vh(p.flip ? 40 : 'none')};
  }
  ${px.portrait}{
    width:  ${px.mvw(80)};
    height:  ${px.mvw(18)};
    top: auto;
    bottom:  ${px.mvw(0)};
    left:  ${p => px.mvw(p.flip ? (80+32) : 16)};
  }

  background-image: url(${p => p.alt ? arrowAlt : arrow});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;

  opacity: 0.4;
  &:hover{
    opacity: 1;
  }
  transition-duration: 300ms;
  transition-property: opacity;

  transform: scaleX(${p => p.flip ? -1 : 1});

  cursor: pointer;
`;
const DoubleQuote = styled(
  ({alt,...p}) => <img alt="" src={alt?doubleQuoteAlt:doubleQuote} {...p}/>
)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width:  ${px.vw(40)};
  height:  ${px.vw(32)};
  @media (min-aspect-ratio: 18/10) {
    width:  ${px.vh(40)};
    height:  ${px.vh(32)};
  }

  ${px.portrait}{
    width:  ${px.mvw(40)};
    height:  ${px.mvw(32)};
  }
  ${px.tablet}{
    margin-left: ${px.tvw(375/2 - 40)};
  }
`;

const Grid = styled(React.forwardRef(({page,...p},ref) => <div ref={ref} {...p}/>))`
  width: ${px.vw(1440*3)};
  height: ${px.vh(810)};
  display: grid;
  grid-template-columns: ${px.vw(1440)} ${px.vw(1440)} ${px.vw(1440)};
  transition-duration: 500ms;
  transition-property: transform;
  transform: translateX(-${p => p.page*100}vw);


  ${px.portrait}{
    width: ${100*3}vw;
    height: 100%;
  }
`;
const Wrapper = styled.div`
  display: block;
  width: ${px.vw(1440)};
  height: ${px.vh(810)};
  position: relative;
  &:last-child{
    & ${Text}{
      ${px.landscape}{
        margin-top: 2em;
      }
    }
  }
  ${px.portrait}{
    height: 100%;
    width: 100vw;
  }
`;

const en = false;

const pages = 3;

export default function Quote({privateInsurance}){

  const [page,setPage] = useState(0);
  const next = useCallback(()=>{
    setPage(page => (page-1+pages)%pages);
  },[setPage]);
  const previous = useCallback(()=>{
    setPage(page => (page+1+pages)%pages);
  },[setPage]);

  const grid = useRef();
  useEffect(()=>{
    if(!grid.current)
      return;
    SwipeListener(grid.current);
    grid.current.addEventListener('swipe',e => {
      const directions = e.detail.directions;
      if (directions.left)
        previous();
      else if(directions.right)
        next();
    });
  },[grid,next,previous]);

  return (
    <Root data-alt={privateInsurance}>
      <Grid ref={grid} page={page}>
        <Wrapper>
          <DoubleQuote alt={privateInsurance}/>
          <Text data-alt={privateInsurance}>
            {en ?
              ''
            : 'Agradeço todo o vosso empenho, profissionalismo, eficiência e seriedade com que a Portugal Seguro tem resolvido todas as questões relacionadas com os nossos seguros.'}
          </Text>
          <Person>
            <PersonAvatar src={require('./assets/Joao_Fernandes.png')}/>
            <PersonName data-alt={privateInsurance}>João Fernandes</PersonName>
            <PersonDescription data-alt={privateInsurance}>{ en ? '' : 'Diretor-Geral'}</PersonDescription>
          </Person>
        </Wrapper>
        <Wrapper>
          <DoubleQuote alt={privateInsurance}/>
          <Text data-alt={privateInsurance}>
            {en ?
              ''
            : 'Como parceiro na área de seguros, sempre obtivemos da vossa parte um serviço de excelência. Conhecendo os profissionais por detrás da marca, não seria expectável outra atitude.'}
          </Text>
          <Person>
            <PersonAvatar src={require('./assets/Jose_Pinto.png')}/>
            <PersonName data-alt={privateInsurance}>José Pinto</PersonName>
            <PersonDescription data-alt={privateInsurance}>{ en ? '' : 'Diretor-Geral'}</PersonDescription>
          </Person>
        </Wrapper>
        <Wrapper>
          <DoubleQuote alt={privateInsurance}/>
          <Text data-alt={privateInsurance}>
            {en ?
              'Professionalism, competence and dedication to each client make Portugal Seguro a reference company in the sector'
            : 'Profissionalismo, competência e dedicação a cada cliente fazem da Portugal Seguro uma empresa de referência no setor.'}
          </Text>

          <Person>
            <PersonAvatar src={require('./assets/Miguel_Nazario.png')}/>
            <PersonName data-alt={privateInsurance}>Miguel Nazário</PersonName>
            <PersonDescription data-alt={privateInsurance}>{ en ? '' : 'Sócio-Gerente'}</PersonDescription>
          </Person>
        </Wrapper>
      </Grid>
      <Arrow onClick={next} alt={privateInsurance}/>
      <Arrow onClick={previous} alt={privateInsurance} flip={true}/>
    </Root>
  );
}
