export default {
  privateInsurance: true,
  title: 'Saúde',
  description: 'Soluções que se adaptam à sua saúde',
  buttons: [
    'Multicare  1 | 2 | 3',
    'Multicare Proteção Vital',
    'Multicare 60+',
    'Multicare Dental',
  ],
  pages: [
    {
      subtitle: 'Multicare 1 | 2 | 3',
      body: [
        'Escolha o seguro que mais se adequa às suas necessidades de proteção na saúde e tenha acesso à maior rede médica privada, a preços vantajosos. Todos os planos proporcionam o acesso imediato, exclusivo e sem qualquer custo, a cuidados primários a partir de sua casa, 24 horas por dia, 7 dias por semana com o serviço de Medicina Online.',
      ],
      boxes:[
        {
          title: 'Multicare 1',
          body: ['Garante a proteção básica sem ter que pagar muito.​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​ Para além de Internamento Hospitalar permite o acesso à Rede Multicare para a realização de consultas, exames e tratamentos, estomatologia, terapêuticas não convencionais e óticas.'],
          picture: 5,
        },
        {
          title: 'Multicare 2',
          body: ['Para quem se preocupa com a saúde e bem-estar da sua família. Acresce ao Multicare 1 a disponibilização de consultas, exames e tratamentos na Rede Multicare ou fora dela. A cobertura de Parto, Cesariana e Interrupção Involuntária da Gravidez poderá também ser contratada.'],
          picture: 5,
        },
        {
          title: 'Multicare 3',
          body: ['Para quem a saúde é uma prioridade, dispondo de um conjunto alargado de coberturas, com capitais elevados para uma proteção mais abrangente.'],
          picture: 5,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Medicina online',
          body: ['A Multicare disponibiliza aos seus clientes, desde 2016, a cobertura de Medicina Online, um serviço médico por telefone, email ou vídeo-consulta, 24h/7 dias, assegurado por médicos de Medicina Geral e Familiar, sem qualquer custo adicional.​​'],
        },
        {
          title: 'Consultas de especialidade',
          body: [
            'Para além das consultas de Medicina Geral e Familiar, a Medicina Online disponibiliza-lhe consultas das especialidades de Dermatologia e Pediatria.',
            'Conte com uma equipa de médicos Pediatras pronta para responder a questões relacionadas com a saúde dos seus filhos, bem como uma equipa de médicos Dermatologistas para o ajudar a resolver os seus problemas de pele.​​',
          ],
        },
        {
          title: 'Orientação nutricional',
          body: ['Os Nutricionistas da Medicina Online irão apoiá-lo em temas relacionados com hábitos alimentares, alimentação saudável, perda de peso, intolerâncias e alergias alimentares, alimentação adequada a patologias específicas (colesterol elevado, hipertensão, diabetes, etc…). Realize consultas de nutrição, no conforto da sua casa através do serviço de Medicina Online​​​.​'],
        },
        {
          title: 'Rede de parcerias',
          body: ['Os clientes Multicare usufruem de condições vantajosas numa Rede de Parcerias com cerca de 700 membros e presença nacional. Esta Rede proporciona vantagens em spas, termas, óticas, centros auditivos, terapêuticas não convencionais, cuidados domiciliários, centros residenciais.​​'],
        },
      ],
    },
    {
      subtitle: 'Multicare Proteção Vital',
      body: ['O seguro que lhe garante proteção e acesso a cuidados de saúde de referência no tratamento da doença oncológica, com um capital de 1.000.000 € por anuidade. O seguro ideal para quem a saúde é a primeira prioridade, para pessoas até aos 65 anos. Pode ainda usufruir do serviço de Medicina Online que conta com serviços de apoio oncológico, como Psico-oncologia e nutrição oncológica.'],
      boxes:[
        {
          title: 'Multicare Proteção Vital',
          body: ['​​Um seguro mais completo que, em caso de doença oncológica, permite acionar a cobertura específica de oncologia com capital de 1.000.000 €.'],
          picture: 5,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Cobertura de oncologia',
          body: ['Com ​capital de 1.000.000€ por anuidade, sendo que, se o contrato vigorar por um período superior a 1 ano, o respetivo capital, para a totalidade do período de vigência do contrato, será de 2.000.000€, o Seguro de Saúde Multicare Proteção Vital, sem franquias ou copagamentos, garante o pagamento de despesas efetuadas com o diagnóstico e tratamento da doença oncológica.​​​'],
        },
        {
          title: 'Serviço de psico-oncologia',
          body: ['A Medicina Online disponibiliza-lhe uma equipa dedicada de Psicólogos que estará próxima de si, e da sua família, para que nunca se sinta sozinho ao longo de todas as fases da doença. Sempre que possível será seguido pelo mesmo profissional de saúde para garantir a continuidade no acompanhamento.​'],
        },
        {
          title: 'Serviço de nutrição oncológica',
          body: ['A Medicina Online disponibiliza-lhe uma equipa de Nutricionistas com experiência na doença oncológica, que desenvolverá, sempre que possível, um plano personalizado e adequado às suas necessidades, partilhando também sugestões de refeições variadas.​'],
        },
        {
          title: 'Aposta na prevenção',
          body: ['Ao adquirir o Seguro Multicare Proteção Vital oferecemos a realização de um check-up 60 dias após o início do seguro. Estes Check-ups são periódicos e diferenciados consoante a idade da pessoa possibilitando o rastreio e diagnóstico atempado.'],
        },
      ],
    },
    {
      subtitle: 'Multicare 60+',
      body: [
        'Conheça a oferta de seguros de saúde especialmente desenhados para a melhor fase da sua vida. Fique com tempo para o que realmente gosta de fazer.',
        '',
        'Aqui poderá encontrar toda a informação sobre os seguros especialmente desenhados para pessoas com mais de 60 anos.​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: '60+ Activcare 1',
          body: ['​​Permite o acesso à Rede Médica Multicare a preços reduzidos e atribui um subsídio diário em caso de internamento.​'],
          picture: 5,
        },
        {
          title: '60+ Activcare 2',
          body: ['​Inclui Medicina Online, consultas a preço reduzido (inclusive domiciliárias), subsídio diário em caso de internamento e acesso à Rede de Parcerias.'],
          picture: 5,
        },
        {
          title: '60+ Multicare 1',
          body: ['Inclui capital de internamento (€50.000), 8 consultas, 2 urgências, consultas domiciliárias a preço reduzido, Medicina Online, Medicina Preventiva e acesso à Rede de Parcerias.'],
          picture: 5,
        },
        {
          title: '60+ Multicare 2',
          body: ['Caracterizado por capitais robustos de internamento (€200.000) e Ambulatório (€2.000), consultas domiciliárias a preço reduzido, Medicina Online, Medicina Preventiva e acesso à Rede de Parcerias.'],
          picture: 5,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Medicina online',
          body: ['A Multicare disponibiliza aos seus clientes, desde 2016, a cobertura de Medicina Online, um serviço médico por telefone, email ou vídeo-consulta, 24h/7 dias, assegurado por médicos de Medicina Geral e Familiar, sem qualquer custo adicional.​'],
        },
        {
          title: 'Urgências e assistência domiciliária',
          body: [
            'Em caso de urgência, a equipa médica da Medicina Online indicar-lhe-á as unidades hospitalares da Rede Multicare que tem à sua disposição',
            'Se necessário, asseguramos também a assistência domiciliária, com o envio de um médico a sua casa, bem como o transporte de urgência.​',
          ],
        },
        {
          title: 'Rede de parcerias',
          body: ['Os clientes Multicare usufruem de condições vantajosas numa Rede de Parcerias com cerca de 700 membros e presença nacional. Esta Rede proporciona vantagens em spas, termas, óticas, centros auditivos, terapêuticas não convencionais, cuidados domiciliários e centros residenciais.​'],
        },
        {
          title: 'Elevados capitais de internamento',
          body: ['Adequados capitais de internamento para que possa ficar confortável em situações mais graves.​'],
        },
      ],
    },
    {
      subtitle: 'Multicare Dental',
      body: [
        'Para o bem da sua saúde oral.',
        'Aqui poderá encontrar a informação sobre a gama Multicare Dental.',
      ],
      boxes:[
        {
          title: 'Multicare Dental',
          body: ['Permite o acesso aos serviços de Estomatologia da Rede Médica a preços vantajosos, incluindo consultas, tratamentos e exames auxiliares de diagnóstico. Sem exames médicos ou questionários médicos e sem períodos de carência.​'],
          picture: 5,
        },
      ],
      title2: '',
      boxes2:[],
    },
  ]
};
