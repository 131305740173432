export default {
  privateInsurance: false,
  title: 'Saúde',
  description: 'Para que possa cuidar melhor de si',
  buttons: ['Multicare  1 | 2 | 3','Activecare Dental PME'],
  pages: [
    {
      subtitle: 'Multicare 1 | 2 | 3',
      body: [
        'Vasta oferta que permite a escolha da solução de saúde que melhor se adequa às suas necessidades. ',
        '',
        'Uma rede de prestadores de cuidados de saúde que se estende por todo o território nacional.',
      ],
      boxes:[
        {
          title: 'Multicare 1',
          body: [
            '​Um seguro simples para quem quer ter acesso à maior rede médica privada, a preços vantajosos.​',
            'Acesso à Rede Multicare para Consultas, Exames e Tratamentos, incluindo Estomatologia. Inclui Internamento.',
          ],
          picture: 5,
        },
        {
          title: 'Multicare 2',
          body: [
            'O seguro ideal para quem pretende uma proteção que responda às necessidades de saúde mais frequentes.',
            'Pode escolher entre €1000 para Consultas, Exames e Tratamentos, incluindo a cobertura de Parto, ou 8 Consultas por ano. Inclui internamento.​',
          ],
          picture: 5,
        },
        {
          title: 'Multicare 3',
          body: [
            'O seguro ideal para quem a saúde é a sua maior prioridade.​',
            'Um seguro com capitais mais elevados e um maior leque de coberturas, inclui o acesso à rede de terapêuticas não convencionais.',
          ],
          picture: 5,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Medicina online',
          body: ['A Multicare disponibiliza aos seus clientes, desde 2016, a cobertura de Medicina Online, um serviço médico por telefone, email ou vídeo-consulta, 24h/7 dias, assegurado por médicos de Medicina Geral e Familiar, sem qualquer custo adicional.​​'],
        },
        {
          title: 'Consultas de especialidade',
          body: [
            'Para além das consultas de Medicina Geral e Familiar, a Medicina Online disponibiliza-lhe consultas das especialidades de Dermatologia e Pediatria.',
            'Conte com uma equipa de médicos Pediatras pronta para responder a questões relacionadas com a saúde dos seus filhos, bem como uma equipa de médicos Dermatologistas para o ajudar a resolver os seus problemas de pele.​​',
          ],
        },
        {
          title: 'Orientação nutricional',
          body: ['Os Nutricionistas da Medicina Online irão apoiá-lo em temas relacionados com hábitos alimentares, alimentação saudável, perda de peso, intolerâncias e alergias alimentares, alimentação adequada a patologias específicas (colesterol elevado, hipertensão, diabetes, etc…). Realize consultas de nutrição, no conforto da sua casa através do serviço de Medicina Online​​​.​'],
        },
        {
          title: 'Rede de parcerias',
          body: ['Os clientes Multicare usufruem de condições vantajosas numa Rede de Parcerias com cerca de 700 membros e presença nacional. Esta Rede proporciona vantagens em spas, termas, óticas, centros auditivos, terapêuticas não convencionais, cuidados domiciliários, centros residenciais.​​'],
        },
      ],
    },
    {
      subtitle: 'Activecare Dental PME',
      body: [
        'Para o bem da sua saúde oral.',
        '',
        'Aqui poderá encontrar toda a informação sobre a gama Multicare Dental PME​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Activecare Dental PME',
          body: ['Acesso aos serviços de Estomatologia da Rede Médica, para consultas e exames auxiliares de diagnóstico. Subscrição sem exames médicos ou questionários clínicos. Sem períodos de carência.​​​​'],
          picture: 5,
        },
      ],
      title2: 'Vantagens',
      boxes2:[
        {
          title: 'Desconto em consultas e tratamentos',
          body: ['Basta selecionar o prestador que pretende consultar e apresentar o Cartão Multicare​ para ter acesso a serviços de Estomatologia.'],
        },
        {
          title: 'Solução simplificada para a sua empresa',
          body: ['O Activcare Dental PME é a solução mais simples e fácil de contratar para qualquer colaborador, independentemente da idade, sem exames médicos ou questionários clínicos e sem períodos de carência no acesso a consultas e exames.'],
        },
        {
          title: 'Desconto por número de pessoas',
          body: ['​Ao incluir todos os colaboradores na apólice e os respetivos agregados familiares, pode beneficiar de descontos sobre o prémio inicial em função do número de pessoas seguras.'],
        },
      ],
    },
  ]
};
