const boxes = {
  boxes: [
    {
      title: 'Essencial',
      body: [
        'Proteção essencial para um negócio de futuro.',
        '*Produtos:* Multirriscos Negócios, Responsabilidade Civil, Cyber Safety, Acidentes de Trabalho e Automóvel.'
      ],
      picture: 1,
    },
    {
      title: 'Complementar',
      body: [
        'Proteção complementar para um negócio de futuro.',
        '*Produtos:* Seguro Equipamento Eletrónico, Acidentes Pessoais e Saúde Multicare.'
      ],
      picture: 1,
    },
  ],
  title2: '',
  boxes2:[],
};

export default {
  privateInsurance: false,
  title: 'Pequeno Negócio',
  description: 'Protegemo-lo a si, aos seus colaboradores e ao seu negócio',
  buttons: [
    'Restauração e Comércio Alimentar',
    'Escritórios Especializados',
    'Beleza',
    'Transporte de Mercadorias',
    'Comércio e Distribuição Não Alimentar',
    'Stands e Oficinas Auto',
    'Educação',
    'Turismo',
  ],
  pages: [
    {
      subtitle: 'Restauração e Comércio Alimentar',
      body: [
        'Oferecemos-lhe um conjunto de soluções adequadas às suas reais necessidades.',
        '',
        '• Cafés',
        '• Restaurantes',
        '• Pastelarias',
      ],
      ...boxes,
    },
    {
      subtitle: 'Escritórios Especializados',
      body: [
        'Proteja o seu estabelecimento e motive a sua equipa.',
        '',
        '• Advogados',
        '• Imobiliárias',
        '• Contabilistas',
      ],
      ...boxes,
    },
    {
      subtitle: 'Beleza',
      body: [
        'Temos para si e para o seu negócio a solução adequada.',
        '',
        '• Cabeleireiros',
        '• Institutos de Beleza',
      ],
      ...boxes,
    },
    {
      subtitle: 'Transporte de Mercadorias',
      body: [
        'Para quem se dedica ao transporte de mercadorias.',
        '',
        '• Empresas de Mudanças',
        '• Transportes Rodoviários de Mercadorias',
      ],
      ...boxes,
    },
    {
      subtitle: 'Comércio e Distribuição Não Alimentar',
      body: [
        'Proteja a sua atividade, a segurança das mercadorias e a satisfação dos clientes.',
        '',
        '• Pronto-a-Vestir',
        '• Sapatarias',
        '• Papelarias',
      ],
      ...boxes,
    },
    {
      subtitle: 'Stands e Oficinas Auto',
      body: [
        'Para quem se dedica a stands e oficinas automóvel.',
        '',
        '• Stands',
        '• Oficinas',
      ],
      boxes: [
        {
          title: 'Essencial',
          body: [
            'Proteção essencial para um negócio de futuro.',
            '*Produtos:* Multirriscos Negócios, Responsabilidade Civil / Ambiental, Cyber Safety, Acidentes de Trabalho e Automóvel / Garagista.'
          ],
          picture: 1,
        },
        {
          title: 'Complementar',
          body: [
            'Proteção complementar para um negócio de futuro.',
            '*Produtos:* Seguro Equipamento Eletrónico, Acidentes Pessoais e Saúde Multicare.'
          ],
          picture: 1,
        },
      ],
      title2: '',
      boxes2:[],
    },
    {
      subtitle: 'Educação',
      body: [
        'Um conjunto de soluções para quem se dedica a ensinar os outros.',
        '',
        '• Estabelecimentos de ensino',
        '• Escolas de línguas',
        '• Escolas de Condução',
      ],
      ...boxes,
    },
    {
      subtitle: 'Turismo',
      body: [
        'Oferecemos-lhe um conjunto de soluções à medida do seu negócio.',
        '',
        '• Hóteis',
        '• Alojamento de curta duração',
        '• Turismo no espaço rural',
      ],
      ...boxes,
    },
  ]
};
