import React,{useMemo,useEffect,useRef,useState,useCallback} from 'react';
import styled from 'styled-components';
import elementWithinViewport from 'element-within-viewport';

import px from 'px.js';

import arrow from './assets/arrow.svg';

import RawLink from 'Link';
import Video from 'Video';

import GetInTouchPopup from './GetInTouchPopup';

import services from './services';

const Root = styled.div`

  padding-left: ${px.vw(40)};
  padding-right: ${px.vw(40)};
  padding-top: ${px.vw(208)};
  padding-bottom: ${px.vw(72)};
  @media (min-aspect-ratio: 18/10) {
    padding-left: ${px.vh(40)};
    padding-right: ${px.vh(40)};
    padding-top: ${px.vh(208)};
    padding-bottom: ${px.vh(72)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    padding-left: ${16/20}em;
    padding-right: ${16/20}em;
    padding-top: ${120/20}em;
    padding-bottom: ${80/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }

  & p{
    margin: 0;
    min-height: 1em;
  }
`;

const Title = styled.div`
  font-size: ${px.vw(68)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(68)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(40)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(40)};
  }
  font-weight: bold;
  line-height: .96;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const Subtitle = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(30)};
  }
  margin-top: ${100/40}em;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;

const Description = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  margin-top: ${24/20}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
`;
const Body = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }

  margin-top:  ${20/16}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }

  max-width: ${646/16}em;

`;

const Buttons = styled.div`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  max-width: 80vw;
  ${px.portrait}{
    max-width: none;
    font-size: ${px.mvw(14)};
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: ${8/14}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  margin-top: ${40/14}em;

`;
const Button = styled.div`
  display: inline-block;
  vertical-align: top;

  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }

  font-weight: normal;
  line-height: ${45/14};

  cursor: pointer;

  color: #98A1B2;
  opacity: 0.7;
  border: ${1/14}em solid rgba(152, 161, 178, 0.5);
  &:hover{
    border: ${1/14}em solid rgba(152, 161, 178);
    opacity: 1;
  }
  &[data-alt=true]{
    border: ${1.5/14}em solid #171C26;
    color: #171C26;
  }

  height: ${45/14}em;
  margin-right: ${8/14}em;
  margin-bottom: ${8/14}em;
  padding: 0 ${24/14}em;

  &[data-selected=true]{
    /* font-weight: 600; */
    border: none;
    color: #EDF2FA;
    background: #00AEEA;
    opacity: 1;
  }
`;
const Boxes = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top: ${60/16}em;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${24/16}em;
  grid-row-gap: ${24/16}em;
  &[data-gridtemplate]{
    grid-auto-flow: unset;
    grid-template-columns: repeat(${p=>p['data-gridtemplate']},1fr);
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    grid-row-gap: ${16/16}em;
    grid-auto-flow: unset;
    grid-template-columns: 100%;
    &[data-gridtemplate]{
      grid-template-columns: 100%;
    }
  }
`;
const Boxes2 = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top: ${60/16}em;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: ${24/16}em;
  grid-row-gap: ${84/16}em;
  margin-bottom: ${84/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    grid-template-columns: 1fr;
    grid-row-gap: ${64/16}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
`;

const Box2 = styled.div`
  display: block;
`;

const Arrow = styled(
  p => <img alt="" src={arrow} {...p}/>
)`



`;

const BoxPicture = styled(Video)`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  position: relative;
  width: 50%;
  left: ${-24/16}em;
  top: ${-16/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(16)};
    width: 70%;
  }
  ${px.landscape}{
    &[data-onlybox=true]{
      position: absolute;
      width: auto;
      height: ${480/16}em;
      top: 0;
      left: auto;
      right: ${115/16}em;
    }
  }
`;
const BoxTitle = styled.div`
  position: relative;
  z-index: 1;
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.01em;
  color: #CEDAF2;
  /* margin-top: ${-56/20}em; */
  margin-bottom: ${16/20}em;
  ${px.portrait}{
    font-size: ${px.mvw(20)};
    margin-bottom: ${8/20}em;
  }
`;
const BoxText = styled.div`
  position: relative;
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.landscape}{
    &[data-onlybox=true]{
      max-width: ${449/16}em;
    }
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B3;
`;
const BoxLink = styled(RawLink)`
  display: block;
  position: absolute;
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  left: ${32/14}em;
  bottom: ${32/14}em;

  ${px.portrait}{
    font-size: ${px.mvw(14)};
    left: ${24/14}em;
  }
  ${px.landscape}{
    &[data-onlybox=true]{
      position: relative;
      left: 0;
      top: 0;
      margin-top: 1.5em;
    }
  }

  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: 0.00em;
  color: #00AEEA;
  &:hover{
    color: #00BEFF;
  }
  & img{
    height: 0.57em;
    margin-left: 0.57em;
    transition-duration: 300ms;
  }

  cursor: pointer;
  &:hover img{
    margin-left: 1em;
  }
  text-decoration: none;
`;

const BoxWrapper = styled.div`
  ${px.landscape}{
    &[data-onlybox=true]{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
const Box = styled.div`
  display: block;
  position: relative;
  background: #1F2633;

  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }

  padding: ${32/16}em;
  padding-bottom: ${68/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    padding: ${24/16}em;
    padding-bottom: ${68/16}em;
  }
  ${px.landscape}{
    &[data-onlybox=true]{
      padding: 0;
      padding-left: ${115/16}em;
      height: ${480/16}em;
    }
  }

  overflow: hidden;
`;


const Box2Header = styled.div`
  display: block;

  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: 0.04em;

  color: #98A1B3;
  padding-bottom: 1em;
  border-bottom: ${1/12}em solid #475266;
  &[data-alt=true]{
    color: #475266;
    border-bottom: ${1/12}em solid #98A1B3;
  }



  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(12)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(12)};
  }
`;
const Box2Title = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  letter-spacing: -0.01em;
  margin-top: ${40/20}em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }


  ${px.portrait}{
    font-size: ${px.mvw(20)};
    margin-top: ${20/20}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(20)};
  }
`;
const Box2Text = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  margin-top: 1em;
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
`;

const FootNote = styled.div`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
  }
  max-width: ${472/14}em;
  margin-top: 8em;
  line-height: 1.5;
  font-weight: normal;
  & a{
    text-decoration: none;
    font-weight: bold;
    color: #CEDAF2;
    &[data-alt=true]{
      color: #171C26;
    }
  }
`;

const Bullet = styled(
  p =>
  <svg {...p} width="100" height="78" viewBox="0 0 100 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.2918 76.3577C37.3372 77.3179 36.0349 77.8536 34.6821 77.8536C33.3292 77.8536 32.0269 77.3179 31.0723 76.3577L2.24382 47.5245C-0.747942 44.5328 -0.747942 39.6814 2.24382 36.6953L5.85358 33.0846C8.84628 30.0928 13.692 30.0928 16.6838 33.0846L34.6821 51.0838L83.316 2.4489C86.3087 -0.542864 91.1591 -0.542864 94.1462 2.4489L97.756 6.05959C100.748 9.05136 100.748 13.9018 97.756 16.8889L38.2918 76.3577Z" fill="#CEDAF2"/>
  </svg>
)`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
  display: inline-block;
  height: ${9*1.2/16}em;
  width: ${11.6*1.2/16}em;
  margin-right: ${6/16}em;
`;

const parseText = (text)=>{
  var result = [text];

  const idx = text.indexOf('•');
  if(idx > -1){
    result = [
      <span key={1}>{text.substr(0,idx)}</span>,
      <Bullet key={2}/>,
      <span key={3}>{text.substr(idx+1)}</span>
    ];
  }
  for(const i in result){
    if(typeof(result[i]) !== 'string')
      continue;
    const idx1 = result[i].indexOf('*');
    if(idx1 === -1)
      continue;
    const idx2 = result[i].indexOf('*',idx1+1);
    if(idx2 === -1)
      continue;
    result[i] = [
      <span key={1}>{result[i].substr(0,idx1)}</span>,
      <span key={3} style={{fontWeight: 'bolder'}}>
        {result[i].substr(idx1+1,idx2-1)}
      </span>,
      <span key={1}>{result[i].substr(idx2+1)}</span>
    ];
  }
  return result;
};

export default function Services({path,setPrivateInsurance,animate}){

  const hide = useMemo(()=>{
    return !services[path];
  },[path]);

  const data = useMemo(()=>(
    services[path]
  ),[path]);

  useEffect(()=>{
    if(hide)
      return;
    setPrivateInsurance(data.privateInsurance);
  },[path,setPrivateInsurance,hide,data]);

  const boxes = useRef();
  const [animationVisible,setAnimationVisible] = useState(true);

  useEffect(()=>{
    if(!boxes.current)
      return;
    elementWithinViewport(boxes.current, {
      onEnter: e => { setAnimationVisible(true) },
      onExit: e => { setAnimationVisible(false) }
    });
  },[boxes,setAnimationVisible]);

  const [showPopup,setShowPopup] = useState(false);

  const openPopup = useCallback(()=>{
    setShowPopup(true);
  },[setShowPopup])
  const closePopup = useCallback(()=>{
    setShowPopup(false);
  },[setShowPopup])

  const [page,setPage] = useState(0);
  const handleButtonClick = useCallback(ev => {
    setPage(parseInt(ev.target.dataset.targetpage));
  },[setPage]);
  useEffect(()=>{
    setPage(0);
  },[path]);

  const onlybox = useMemo(()=>(
    data && data.pages[page] && data.pages[page].boxes.length === 1
  ),[data,page,path]);

  const formPreselect = useMemo(()=>{
    return !data ? undefined : path.match(/^\/[^\/]+\/([^\/]+)/)[1];
  },[data,path]);

  return !data || !data.pages[page] || hide ? null : (
    <Root>
      { showPopup ? (
        <GetInTouchPopup privateInsurance={data.privateInsurance} preselect={formPreselect} onClose={closePopup}/>
      ):null}

      <Title data-alt={data.privateInsurance}>{data.title}</Title>
      <Description data-alt={data.privateInsurance}>{data.description}</Description>
      <Buttons>{data.buttons.map((label,idx)=>(
        <Button
          data-alt={data.privateInsurance}
          data-selected={idx === page}
          data-targetpage={idx}
          key={idx}
          onClick={handleButtonClick}
        >
          {label}
        </Button>
      ))}
      </Buttons>
      <Subtitle data-alt={data.privateInsurance}>{data.pages[page].subtitle}</Subtitle>
      <Body data-alt={data.privateInsurance}>{
        data.pages[page].body.map((text,idx) => <p key={idx}>{parseText(text)}</p>)
      }</Body>
      <Boxes
        ref={boxes}
        data-gridtemplate={data.pages[page].gridtemplate}
      >{data.pages[page].boxes.map((box,idx) => (
        <Box key={idx} data-onlybox={onlybox}>
          <BoxPicture data-onlybox={onlybox}
            enable={animationVisible && animate && !showPopup}
            src={require(`./assets/${box.picture}.webm`)}
            poster={require(`./assets/${box.picture}.png`)}
          />
          <BoxWrapper data-onlybox={onlybox}>
            <BoxTitle data-onlybox={onlybox}>{box.title}</BoxTitle>
            <BoxText data-onlybox={onlybox}>{
              box.body.map((text,idx) => <p key={idx}>{parseText(text)}</p>)
            }</BoxText>
            <BoxLink data-onlybox={onlybox} onClick={openPopup}>Pedir mais info<Arrow/></BoxLink>
          </BoxWrapper>
        </Box>
      ))}</Boxes>
      <Subtitle data-alt={data.privateInsurance}>{data.pages[page].title2}</Subtitle>
      <Boxes2>{data.pages[page].boxes2.map((box,idx) => (
        <Box2 key={idx}>
          <Box2Header data-alt={data.privateInsurance}>#{idx+1}</Box2Header>
          <Box2Title data-alt={data.privateInsurance}>{box.title}</Box2Title>
          <Box2Text data-alt={data.privateInsurance}>{
            box.body.map((text,idx) => <p key={idx}>{text}</p>)
          }</Box2Text>
        </Box2>
      ))}</Boxes2>
      <FootNote data-alt={data.privateInsurance}>
        A informação constante deste site sobre este Seguro não dispensa a consulta da informação pré-contratual e contratual legalmente exigida em
        <a data-alt={data.privateInsurance} href="https://fidelidade.pt" rel="noopener noreferrer"> fidelidade.pt</a>
      </FootNote>
    </Root>
  );
}
