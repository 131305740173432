
export default {
  vw: px => `${px/1440*100}vw`,
  vh: px => `${px/810*100}vh`,
  mvw: px => `${px/375*100}vw`,
  mvh: px => `${px/812*100}vh`,
  tvw: px => `${px/540*100}vw`,
  tvh: px => `${px/1024*100}vh`,
  
  halfVh: px => `calc((${(810-px)/810*100}vh - ${(810-px)/1440*100}vw)/2)`,

  portrait: '@media (max-aspect-ratio:  1/1)',
  landscape: '@media (min-aspect-ratio:  1/1)',
  tablet: '@media (max-aspect-ratio:  1/1) and (min-width: 600px)',
};
