import React from 'react';
import styled from 'styled-components';

import px from 'px.js';
import RawLink from 'Link';

import arrow from './assets/arrow.svg';

import news from 'News/news.js';

var newsList = [];
for(const id in news)
  newsList.push({id:id,...news[id]});
newsList = newsList.sort((a,b) => parseInt(b.id) - parseInt(a.id)).slice(0,3);

const Root = styled.div`
  background: #171C26;
  &[data-alt=true]{
    background: #EDF2FA;
  }
  min-height: 100vh;
  border: 1px solid transparent;
  position: relative;
  scroll-snap-align: start;

  padding-left: ${px.vw(40)};
  padding-right: ${px.vw(40)};
  padding-top: calc(${224/20}em + ${px.halfVh(400)});
  @media (min-aspect-ratio: 18/10) {
    padding-left: ${px.vh(40)};
    padding-right: ${px.vh(40)};
    padding-top: calc(${196/20}em);
  }
  ${px.portrait}{
    padding-left: ${px.mvw(16)};
    padding-right: ${px.mvw(16)};
    padding-top: ${px.mvw(100)};
    padding-bottom: ${px.mvw(100)};
    min-height: unset;
  }
`;

const Title = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;

const Description = styled.div`
  font-size: ${px.vw(16)};
  margin-top:  ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  margin-top:  ${20/16}em;
  ${px.portrait}{
    font-size: ${px.mvw(14)};
    margin-top:  ${20/14}em;
    br{
      display: none;
    }
  }
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
`;
const ScrollWindow = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  margin-top: ${120/12}em;

  ${px.portrait}{
    font-size: ${px.vh(12)};
    margin-top: ${40/12}em;
    padding-bottom: ${20/12}em;
    width: 100%;
    overflow-y: scroll;
    scroll-snap-type: x mandatory;

    width: calc(100% + ${px.mvw(32)});
    margin-left: ${px.mvw(-16)};


    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* Chrome/Safari/Webkit */
    }
  }
  ${px.tablet}{
    margin-top: ${60/12}em;
  }
`;
const Grid = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }

  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${24/12}em;

  & > *{
    vertical-align: top;
    position: relative;
  }

  ${px.portrait}{
    font-size: ${px.mvw(12)};
    width: 300%;
    padding-right: 20%;
    grid-template-columns: repeat(3,1fr);
    grid-auto-flow: unset;
    grid-column-gap: ${0/12}em;
    & > div{
      scroll-snap-align: start;
      padding-left: ${px.mvw(16)};
      /* padding-right: ${px.mvw(16)}; */
      &:last-child{
        /* scroll-snap-align: end; */
      }
    }
  }

`;
const Header = styled.div`
  font-size: ${px.vw(12)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(12)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(12)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(14)};
  }
  line-height: 1;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
  }
  & > *{
    vertical-align: top;
  }
  & > span{
    &:first-child{
      font-weight: bold;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    &:last-child{
      float: right;
    }
  }
  padding-bottom: 1em;
  margin-bottom: ${40/12}em;
  border-bottom: 1px solid #47526680;
  &[data-alt=true]{
    border-bottom: 1px solid #98A1B280;
  }
`;
const Headline = styled.div`
  font-size: ${px.vw(20)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(20)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(20)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(22)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  margin-bottom: ${16/20}em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;
const NewsBody = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(18)};
  }
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -0.00em;
  color: #98A1B2;
  padding-bottom: ${40/16}em;
  &[data-alt=true]{
    color: #475266;
  }
`;
const Arrow = styled(
  p => <img alt="" src={arrow} {...p}/>
)`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
  }
  height: ${10/14}em;
  margin-left: ${10/14}em;
  transition-duration: 300ms;
`;
const Link = styled(
  p => <RawLink {...p}><span>Ler</span><Arrow/></RawLink>
)`
  font-size: ${px.vw(14)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(14)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.00em;
  color: #00AEEA;
  cursor: pointer;

  position: absolute;
  left: 0;
  bottom: 0;

  text-decoration: none;

  & > *{
    vertical-align: top;
  }
  & > ${Arrow}{
    position: relative;
    top: 0.15em;
  }
  &:hover > ${Arrow}{
    margin-left: 1.5em;
  }
  ${px.portrait}{
    font-size: ${px.mvw(14)};
    left: ${px.mvw(16)};
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }
`;

const en = false;

export default function News({privateInsurance}){
  return (
    <Root data-alt={privateInsurance}>
      <Title data-alt={privateInsurance}>{en ?
          'Latest news'
        : 'Últimas notícias'
      }</Title>
      <Description data-alt={privateInsurance}>{en ?
          <span>See the latest news from the Portugal Seguro<br/> universe, its partners and the world in general</span>
        : <>Aceda às últimas notícias do universo Portugal Seguro,<br/> dos nossos parceiros e do mundo em geral.</>
      }</Description>

      <ScrollWindow>
        <Grid>
          {newsList.map( article => (
            <div>
              <Header data-alt={privateInsurance}>
                <span>{article.category}</span>
                <span>{article.date}</span>
              </Header>
              <Headline data-alt={privateInsurance}>
                {article.title}
              </Headline>
              <div>
                <NewsBody data-alt={privateInsurance}>
                  {article.summary}
                </NewsBody>
                <Link to={`/noticias/${article.id}`}/>
              </div>
            </div>
          ))}
        </Grid>
      </ScrollWindow>

    </Root>
  );
}
