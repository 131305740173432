import React from 'react';
import styled from 'styled-components';

import px from 'px.js';

import cookies from './cookies.js';
import privacy from './privacy.js';

const Root = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  padding: ${40/16}em;
  padding-top: ${208/16}em;
  padding-bottom: ${72/16}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    padding: ${16/16}em;
    padding-top: ${120/16}em;
    padding-bottom: ${80/16}em;
  }
  ${px.tablet}{
    font-size: ${px.tvw(16)};
  }

  line-height: 1.5;
  font-weight: normal;

  & h1{
    font-size: ${px.vw(68)};
    @media (min-aspect-ratio: 18/10) {
      font-size: ${px.vh(68)};
    }
    ${px.portrait}{
      font-size: ${px.mvw(40)};
    }
    ${px.tablet}{
      font-size: ${px.tvw(40)};
    }
    font-weight: bold;
    line-height: .96;
    text-align: left;
    letter-spacing: -0.04em;
    color: #CEDAF2;
    margin-bottom: 1em;
  }

  & h2{
    display: block;

    font-weight: bold;
    line-height: 1;
    text-align: left;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    color: #98A1B3;
    padding-bottom: 1em;
    border-bottom: ${1/12}em solid #475266;

    font-size: ${px.vw(12)};
    @media (min-aspect-ratio: 18/10) {
      font-size: ${px.vh(12)};
    }
    ${px.portrait}{
      font-size: ${px.mvw(12)};
    }
    ${px.tablet}{
      font-size: ${px.tvw(12)};
    }
  }

  & span[data-bullet=true]{
    position: relative;
    top: 0.135em;
    font-size: 3em;
    line-height: ${1/3};
  }

  strong{
    font-weight: bold;
  }
  a{
    color: inherit;
    text-decoration: none;
    &:hover{
      color: #475266;
    }
  }

  color: #98A1B3;
  &[data-alt=true]{
    color: #475266;
    & a:hover{
      color: #171C26;
    }
    & h1{
      color: #171C26;
    }
    & h2{
      color: #475266;
      border-bottom: ${1/12}em solid #98A1B3;
    }
  }
`;

export default function Polices({path,privateInsurance}){

  const data = (
    path === '/privacidade' ? privacy :
    path === '/cookies' ? cookies :
    null
  );

  return !data ? null : (
    <Root data-alt={privateInsurance}>
      {data}
    </Root>
  );
}
