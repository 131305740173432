const vantagens = {
  title2: 'Vantagens',
  boxes2:[
    {
      title: 'Assistência em viagem',
      body: ['Todos os planos incluem a cobertura de Assistência em Viagem, disponível 24 horas todos os dias, com proteção específica para veículos de transporte de mercadorias.'],
    },
    {
      title: 'Compromisso WeCare',
      body: ['Projeto pioneiro cujo objetivo é tratar da reintegração das pessoas que sofreram acidentes graves, de automóvel e acidentes de trabalho, de uma forma completa, em todos os aspetos da sua vida - social, profissional e familiar.'],
    },
    {
      title: 'Assistência recomendada',
      body: ['Construímos a liderança com base na qualidade dos serviços e na assistência premium, oferecendo condições específicas nas reparações efetuadas na Rede de Oficinas Recomendadas ou nos centros Help-a-Car.​'],
    },
  ],
}

export default {
  privateInsurance: false,
  title: 'Automóvel',
  description: 'Porque o seu negócio não pode parar',
  buttons: [
    'Essencial',
    'Alarme',
    'Extra',
    'Total',
    'Mota',
  ],
  pages: [
    {
      subtitle: 'Essencial',
      body: ['Adaptado à sua atividade, o seguro Automóvel Essencial, garante a proteção das suas viaturas e dos respetivos condutores a um preço acessível.​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​'],
      boxes:[
        {
          title: 'Essencial',
          body: [
            'A proteção simples e acessível para as viaturas da sua empresa.',
            '',
            '',
            '• Responsabilidade Civil',
            '• Assistência em Viagem Plus',
            '• Proteção Vital do Condutor (500.000€)',
          ],
          picture: 2,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Alarme',
      body: [
        'Aumente a proteção das viaturas da sua empresa com as coberturas de furto ou roubo, fenómenos da natureza e quebra isolada de vidros.​​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Alarme',
          body: [
            'Uma proteção base que inclui coberturas mais abrangentes.',
            '',
            '• Furto ou Roubo',
            '• Fenómenos da Natureza',
            '• Quebra Isolada de Vidros (1.000€ | 2.000€)',
          ],
          picture: 2,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Extra',
      body: [
        'O seguro de danos próprios adequado à sua empresa.',
        'Garanta uma proteção elevada que não deixe nenhum imprevisto parar o seu negócio.​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Extra',
          body: [
            'A solução ideal para a melhor proteção das viaturas da sua empresa.',
            '',
            '',
            '• Choque, Colisão ou Capotamento',
            '• Incêndio, Raio ou Explosão',
            '• Atos de Vandalismo',
          ],
          picture: 2,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Total',
      body: [
        'A proteção mais completa para as viaturas da sua empresa.',
        'Com oseguro Automóvel Total garante a proteção completa e abrangente das suas viaturas.​​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Total',
          body: [
            'A solução mais completa para a frota de veículos da sua empresa. ',
            '',
            '',
            '• Veículo de Substituição',
            '• Privação de Uso',
            '• Assistência em Viagem Plus ',
          ],
          picture: 2,
        },
      ],
      ...vantagens
    },
    {
      subtitle: 'Mota',
      body: [
        'Proteja a agilidade e a competitividade da sua atividade. ',
        'Disponibilizamos a proteção mais adequada  para os veículos de duas rodas com a cobertura opcional de Quebra Isolada de Vidros.​​​​​​​​​​​​​​​​​​​​​​​​​​​',
      ],
      boxes:[
        {
          title: 'Mota',
          body: [
            'Proteção adequada às suas necessidades.',
            '',
            '',
            '• Responsabilidade Civil',
            '• Assistência em Viagem Plus',
            '• Quebra Isolada de Vidros (1.000€ )',
          ],
          picture: 2,
        },
      ],
      ...vantagens
    },
  ]
};
