import React,{useMemo} from 'react';
import styled from 'styled-components'; 

import px from 'px.js';

import ContactForm from 'ContactForm';
import RawLink from 'Link';

import UnstyledVideo from 'Video.js';

const Root = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  padding: ${40/40}em;
  padding-top: ${208/40}em;
  padding-bottom: ${(72+20)/40}em;

  ${px.portrait}{
    font-size: ${px.mvw(16)};
    padding: ${16/16}em;
    padding-top: ${120/16}em;
    padding-bottom: ${80/16}em;
  }
`;

const Grid = styled.div`
  display: grid;
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    grid-template-columns: 100%;
    grid-row-gap: ${24/16}em;
    font-size: ${px.mvw(16)};
    & > :first-child{
      order: 2;
    }
    & > :last-child{
      order: 1;
    }
  }

  grid-template-columns: repeat(2,1fr);
  grid-column-gap: ${60/16}em;

`;

const ContactInfo = styled.div`

`;

const Title = styled.div`
  font-size: ${px.vw(40)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(40)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(30)};
  }
  font-weight: bold;
  line-height: 1;
  text-align: left;
  letter-spacing: -0.04em;
  color: #CEDAF2;
  &[data-alt=true]{
    color: #171C26;
  }
`;

const Body = styled.div`
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  ${px.portrait}{
    font-size: ${px.mvw(16)};
  }
  margin-bottom:  ${40/16}em;
  margin-top:  ${24/16}em;
  line-height: 1.5;
  text-align: left;
  color: #98A1B2;
  &[data-alt=true]{
    color: #475266;
    & h2{
      color: #171C26;
    }
  }
  & h2,p{
    margin: 0;
    padding: 0;
    font-weight: inherit;
    line-height: inherit;
    font-size: inherit;
    color: inherit;
  }
  & h2{
    font-size: ${14/16}em;
    margin-bottom: ${8/14}em;
    font-weight: 600;
    color: #CEDAF2;
  }
`;
const Link = styled(RawLink)`
  text-decoration: none;
  color: inherit;
  &:hover{
    color: #CEDAF2;
    &[data-alt=true]{
      color: #171C26;
    }
  }
`;

const Video = styled(UnstyledVideo)`
  position: absolute;
  font-size: ${px.vw(16)};
  @media (min-aspect-ratio: 18/10) {
    font-size: ${px.vh(16)};
  }
  width: ${770/16}em;
  top: ${60/16}em;
  right: -${770/2/16}em;
  ${px.portrait}{
    display: none;
  }
`;

export default function Contacts({path,animate,privateInsurance}){

  const hide = useMemo(()=>(
    path !== '/contactos'
  ),[path]);

  return hide ? null : (
    <Root>
      <Video
        enable={animate}
        src={require(`Home/GetInTouch/assets/get in touch on ${privateInsurance?'light':'dark'}.webm`)}
        poster={require(`Home/GetInTouch/assets/get in touch on ${privateInsurance?'light':'dark'}.png`)}
      />
      <Grid>
        <ContactForm privateInsurance={privateInsurance} hideInsuranceTypes={true}/>
        <ContactInfo>
          <Title data-alt={privateInsurance}>Contactos</Title>
          <Body data-alt={privateInsurance}>
            <Link
              external={true}
              to="https://www.google.com/maps/place/Portugal+Seguro+-+Media%C3%A7%C3%A3o+de+Seguros,+Lda./@41.2783544,-8.3744397,17z/data=!3m1!4b1!4m5!3m4!1s0xd248cc04e8b7b95:0x3cc2ba7f34627674!8m2!3d41.2783544!4d-8.372251"
              data-alt={privateInsurance}
            >
              Praceta Santa Eulália nº 5<br/>
              4590-530 Paços de Ferreira
            </Link>
          </Body>
          <Body data-alt={privateInsurance}>
            <h2>Telefone</h2>
            <p>
              <Link
                external={true}
                newtab={false}
                to="tel:+351255161043"
                data-alt={privateInsurance}
              >
                +351 255 161 043
              </Link>
            </p>
          </Body>
          <Body data-alt={privateInsurance}>
            <h2>Urgências</h2>
            <p>
              <Link
                external={true}
                newtab={false}
                to="tel:+351915207888"
                data-alt={privateInsurance}
              >
                +351 915 207 888
              </Link>
            </p>
          </Body>
          <Body data-alt={privateInsurance}>
            <h2>E-mail</h2>
            <p>
              <Link
                external={true}
                newtab={false}
                to="mailto:geral@portugalseguro.pt"
                data-alt={privateInsurance}
              >
                geral@portugalseguro.pt
              </Link>
            </p>
          </Body>
          <Body data-alt={privateInsurance}>
            2ª feira – 6ª feira<br/>
            09:30 – 12:30 e 14:00 – 19:00
          </Body>
        </ContactInfo>
      </Grid>
    </Root>
  );
}
